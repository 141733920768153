import React, { useEffect } from 'react';
import { Modal, Table, Form, DatePicker, Button } from 'antd';
import { SearchOutlined, LeftSquareOutlined, RightSquareOutlined } from '@ant-design/icons';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import store from '../../../store';
import errorUtil from "../../../utils/errorUtil";

interface Iprops {
    workNO: string,
    visible: boolean,
    loading: boolean,
    cRef: any,
    onCancel: () => void,
}

let query: any = {}

const WorkerDailyWorkTimeTable: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { visible, workNO, loading, onCancel } = props;
    const { t, i18n } = useTranslation();
    const eStore: any = useLocalObservable(() => store.workRecordStore);
    const [summaryform] = Form.useForm();
    let { queryDate,dailyWorkTime } = eStore;
    let title = t('EwRecord.dailyWorkTime');
    query.WorkNo = workNO;
    props.cRef.current = {
        setDatePicker: (date: any) => {
            summaryform.setFieldsValue({ 'datePicker': date });
        }
    }


    const EnginnerTimeRecordColumns: any = [
        {
            title: t('TimeTable.Seq'),
            render: (text: string, record: any, index: number) => `${index + 1}`
        }, {
            title: t('FormHeader.ApplicationNo'),
            dataIndex: 'mp_Application_No',
        },{
            title: t('TimeTable.State'),
            dataIndex: 'mp_State',
            render: (val: string) => {
                if (i18n.language === "en") {
                    return val;
                } else {
                    switch (val) {
                        case "Wait":
                            return "等待开始"
                        case "Start":
                            return "开始";
                        case "Pause":
                            return "暂停";
                        case "End":
                            return "结束";
                        case "Finished":
                            return "完成";
                        default:
                            return "";
                    }
                }
            }
        }, {
            title: t('TimeTable.Name'),
            dataIndex: 'mp_Technician',
        }, {
            title: t('TimeTable.From_Time'),
            render: (text: string, record: any) => {
                if (record.mp_FromTime) {
                    let time = moment(record.mp_FromTime).format("MM/DD/YYYY HH:mm:ss")
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('TimeTable.To_Time'),
            render: (text: string, record: any) => {
                if (record.mp_ToTime) {
                    let time = moment(record.mp_ToTime).format("MM/DD/YYYY HH:mm:ss")
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('TimeTable.WorkingTime'),
            align: 'center',
            render: (text: any, record: any) => {
                if (record.mp_SpendTime) {
                    let hour = Math.floor(record.mp_SpendTime / 3600);
                    let min = Math.floor((record.mp_SpendTime % 3600) / 60);
                    let second = record.mp_SpendTime % 3600 - min * 60;
                    if (hour > 0) {
                        if (min > 0) {
                            if (second > 0) {
                                return hour + "h" + min + "m" + second + "s";
                            } else {
                                return hour + "h" + min + "m";
                            }
                        } else {
                            if (second > 0) {
                                return hour + "h" + second + "s";
                            } else {
                                return hour + "h";
                            }
                        }
                    } else {
                        if (min > 0) {
                            if (second > 0) {
                                return min + "m" + second + "s";
                            } else {
                                return min + "m";
                            }
                        } else {
                            if (second > 0) {
                                return second + "s";
                            } else {
                                return '0';
                            }
                        }
                    }
                } else {
                    return 0;
                }
            }
        }, {
            title: t('TimeTable.Remarks'),
            dataIndex: 'mp_Remarks',
        }
    ]

    const handleSummarySearch = () => {
        let date = summaryform.getFieldValue("datePicker");
        // let date = queryDate;
        if (date) {
            query.Date = date.format('YYYY/MM/DD');
            eStore.setQueryDate(date);
            SearchSummary();
        } else {
            errorUtil.showErrorMsg(t('InputSearch'));
        }
    }

    const handlePreDaySearch = () => {
        let date = summaryform.getFieldValue("datePicker");
        //  let date = queryDate;
        if (date) {
            let preDate = date.subtract(1, 'days');
            summaryform.setFieldsValue({ 'datePicker': preDate });
            query.Date = preDate.format('YYYY/MM/DD');
            eStore.setQueryDate(preDate);
            SearchSummary();
        } else {
            errorUtil.showErrorMsg(t('InputSearch'));
        }

    }

    const handleNextDaySearch = () => {
        let date = summaryform.getFieldValue("datePicker");
        if (date) {
            let nextDate = date.add(1, 'days');
            summaryform.setFieldsValue({ 'datePicker': nextDate });
            query.Date = nextDate.format('YYYY/MM/DD');
            eStore.setQueryDate(nextDate);
            SearchSummary();
        } else {
            errorUtil.showErrorMsg(t('InputSearch'));
        }

    }

    const SearchSummary = () => {
        //eStore.querySummaryMpList(querySum).then((v: any) => {
        eStore.queryDaliyWorkerMpLst(query).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            }
        }).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });
    }

    // return <Observer>{() =>
    return (
        <Modal title={title} closable={true} maskClosable={false}
            visible={visible} width={1000}
            footer={null} onCancel={onCancel}
        >
            <Form form={summaryform} layout="inline" className="reportbar" initialValues={{ 'datePicker': queryDate }} onFinish={handleSummarySearch}>
                <Form.Item >
                    <Button size="large" onClick={handlePreDaySearch} icon={<LeftSquareOutlined />} disabled={loading}>{t('Report.PreDay')}</Button>
                </Form.Item>
                <Form.Item name="datePicker" >
                    <DatePicker id="date1" size='large' format='YYYY/MM/DD' />
                </Form.Item>
                <Form.Item >
                    <Button size="large" onClick={handleNextDaySearch} icon={<RightSquareOutlined />} disabled={loading}>{t('Report.NextDay')}</Button>
                </Form.Item>
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading}>{t('Search')}</Button>
                </Form.Item>
                <Form.Item className="summary"    >
                    {t('WorkTime', { hours:  dailyWorkTime})}
                </Form.Item>
            </Form>
            <Table
                columns={EnginnerTimeRecordColumns}
                rowKey={(record: any) => record.id}
                dataSource={eStore.dailyMpList.slice()}
                pagination={false}
                bordered
                loading={loading}
                //rowClassName={getRowClassName}
                size='middle'
                // onChange={props.handleTableChange}
                scroll={{ x: 'max-content' }}
            />
        </Modal>
    )
    // }</Observer>;

}

export default WorkerDailyWorkTimeTable;
// export default observer(WorkerDailyWorkTimeTable);