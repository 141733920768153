import "../style/login.less";
import React, { useEffect, useRef, useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Button, Input, Tabs,Radio } from 'antd';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import http from '../utils/http';
import Base64Util from '../utils/Base64Util';
import errorUtil from "../utils/errorUtil";
import ModalUtil from "../utils/ModalUtil";
import Loading from "../components/Loading";
import {webConfig} from "../config/webConfig";

const { TabPane } = Tabs;

const Login: React.FC = () => {
    const { t,i18n } = useTranslation();
    const qrcodeRef = useRef<Input>(null);
    const [userCode, setUserCode] = useState('');
    const [loading, setLoading] = useState(false);
    let history = useHistory();
    useEffect(() => {
        if (qrcodeRef.current) { qrcodeRef.current.focus() }
    }, []);


    const onFinish = (values: any) => {
        //console.log('Received values of form: ', values);
        setLoading(true);
        http.get('login', values, { baseURL: 'auth/' }).then(res => {
            setLoading(false);
            loginReturn(res);
        }).catch(err => {
            setLoading(false);
            errorUtil.showErrorMsg(err.message);
        });
        // sessionStorage.setItem("isAuth", '1');
        // history.push('/app');
    }

    const handleCodeValueChange = (e: any) => {
        setUserCode(e.target.value);
    }

    const onScanCode = (e: any) => {
        let logSign = Base64Util.decodeBase64Content(Base64Util.decodeBase64Content(e.target.value));
        let arr = logSign.split('-');
        if (arr.length === 2) {
            let workNo = arr[0].trim();
            let role = arr[1].trim();
            setLoading(true);
            //const res = axios.get(Config.authURL + 'loginByScan?workNo=' + workNo + '&role=' + role);
            http.get('loginByScan', { workNo: workNo, role: role }, { baseURL: 'auth/' }).then(res => {
                setLoading(false);
                loginReturn(res);
            }).catch(err => {
                setLoading(false);
                errorUtil.showErrorMsg(err.message);
            });
        } else {
            ModalUtil.warningModal(t('ScanWarn'));
        }
    }

    const loginReturn = (res: any) => {
        let status = res.data.status;
        if (status === 0) {
            errorUtil.showErrorMsg(res.data.err);
        } else if (status === -1) {
            errorUtil.showErrorMsg(t('LoginPage.ErrorTips'));
        } else if (status === 1) {
            let user = res.data.obj;
            if (user != null) {
                sessionStorage.setItem("token", res.data.msg);
                sessionStorage.setItem("isAuth", '1');
                sessionStorage.setItem("user", JSON.stringify(user));
                history.push('/app');
            } else {
                errorUtil.showErrorMsg(t('LoginPage.ServerError'));
            }
        }
    }

    const onLanuageChange = (e:any) =>{
        i18n.changeLanguage(e.target.value);
        document.title = t('SYSName');
    }


    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    return (
        <div className="login">
            <Loading loading={loading} />
            <div className="login-form">
                <div className="login-logo"></div>
                <h1 className="login-title">{t('SYSName')}<span className="login-version">  v{webConfig.version}</span></h1>
                <Tabs defaultActiveKey="2" >
                    <TabPane tab={t('LoginPage.PasswordLogin')} key="1">
                        <Form {...formItemLayout} onFinish={onFinish} style={{ width: '330px' }}>
                            <Form.Item label={t('LoginPage.Username')} name="workNo" rules={[{ required: true, message: t('LoginPage.InputUser') }]}>
                                <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    allowClear size="large" placeholder="userName" />
                            </Form.Item>
                            <Form.Item label={t('LoginPage.Password')} name="pwd" rules={[{ required: true, message: t('LoginPage.InputPw') }]}>
                                <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password" size="large" allowClear placeholder="password" />
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit" className="login-form-button"
                                    size="large">{t('LoginPage.Login')}</Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                    <TabPane tab={t('LoginPage.ScanLogin')} key="2">
                        <Input type="password" size="large" allowClear placeholder={t('ScanTips')}
                            ref={qrcodeRef} value={userCode} onChange={handleCodeValueChange} onPressEnter={onScanCode} 
                            className="login-Scan" />
                    </TabPane>
                </Tabs>
                <Radio.Group className="lanuage" defaultValue={i18n.language}  onChange={onLanuageChange} size="large" buttonStyle="solid">
                    <Radio.Button value="zh">中文</Radio.Button>
                    <Radio.Button value="en">English</Radio.Button>
                </Radio.Group>
            </div>
        </div>
    );

}


export default Login;