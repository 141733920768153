import "../../style/maintenanceReport.less";
import React, { useRef,useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Button, Layout, Form, message, Modal } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import errorUtil from "../../utils/errorUtil";
import Loading from "../../components/Loading";
import store from '../../store';
import OutSourcingTable from './components/OutSourcingTable';
import AddOutSourcingDrawer from './components/AddOutSourcingDrawer';
import QRCode from 'qrcode.react';
import http from '../../utils/http';

interface Iprops {
    user: any
}

const { Content } = Layout;
let query: any = { currIndex: 0 }


const OutSourcingManage: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    const eStore: any = useLocalObservable(() => store.outSourcingStore);
    const [form] = Form.useForm();
    let outSourcingRecordList = eStore.outSourcingRecordList.slice();
    let { page, loading, qrModalVisible, outSourceInfo } = eStore;
    const [isEdit,setIsEdit] = useState(false);
    const [OsId,setOsId] = useState(0);
    const [OsNo,setOsNo] = useState('');
    let formRef:any = useRef();

    useEffect(() => {
        //query = { applicant: props.user.user_NameEN,currIndex: 0  }
        onSearch();
        return () => {
            eStore.setOutSourcingRecordList([]);
        }
    }, [])


    const handleSearch = () => {
        // e.preventDefault();//阻止页面提交刷新
        onSearch();
    }

    const onSearch = () => {
        getSearchCondition();
        onCallServer();
    }

    const onCallServer = () => {
            eStore.queryOutsoucringList(query).then((v: any) => {
                if (v.status === 0) {
                    errorUtil.showErrorMsg(v.err);
                }
            }).catch((ex: any) => {
                errorUtil.showErrorWithStore(ex, history, eStore);
            });
    }

    const getSearchCondition = () => {
        // if (props.user.user_Role === "Supervisor") {
        //     query.applicant = props.user.user_NameEN;
        // } else {
        //     delete query.applicant;
        // }
        // if (props.user.user_Role === "Manager") {
        //     query.department = props.user.user_Department;
        // } else {
        //     delete query.department;
        // }
        let applicationNo = form.getFieldValue("applicationNo");
        if (!!applicationNo) {
            query.formNo = applicationNo;
        } else {
            delete query.formNo;
        }
        let machineNo = form.getFieldValue("machineNo");
        if (!!machineNo) {
            query.machineNo = machineNo;
        } else {
            delete query.machineNo;
        }
        query.pageSize = eStore.pageSize;
    }


    const handleReportTableChange = (pagination: any, fliters: any, sorter: any) => {
        getSearchCondition();
        const sortFiled = sorter.field;
        let sortOrder = sorter.order;
        if (sortOrder !== undefined && sortOrder !== null) {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc'
            } else if (sortOrder === 'descend') (
                sortOrder = 'desc'
            )
        }
        query.sortFiled = sortFiled;
        query.sortOrder = sortOrder;
        const statusArr = fliters.ma_Status;
        query.statusArr = statusArr;
        //query.pageSize = pagination.pageSize;
        const current = pagination.current;
        if (current !== undefined && current !== null) {
            if (current > 1) {
                query.currIndex = (current - 1) * pagination.pageSize;
            } else {
                query.currIndex = 0;
            }
        }
        onCallServer();
    }

    const onShowSizeChange = (current: number, pageSize: number) => {
        eStore.setPageSize(pageSize);
    }

    const onEdit = (record:any) =>{
        setIsEdit(true);
        setOsId(record.os_Id);
        setOsNo(record.os_FormNo);
        formRef.current.initForm(record);
        eStore.setAddModalVisible(true);
    }
    

    const onOpenAddModal = () => {
        eStore.addExpire(query).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });
        setIsEdit(false);
        eStore.setAddModalVisible(true);
    }

    const onCloseAddModal = () => {
        eStore.setAddModalVisible(false);
    }

    const onAfterSubmit = (values: any,showQR:boolean) => {
        if(showQR){
            eStore.setQrModalVisible(true);
        }
        onSearch();
    }

    const onExport = (value: any) => {
        eStore.setLoading(true);
        let params = {formNo:value}
        const FileDownload = require('js-file-download');
        http.get('osf/getOutSourcingApplicationExcel', params, { responseType: 'blob' }).then(
            (res: any) => {
                eStore.setLoading(false);
                if (!res) {
                    return
                }
                if (res.config.responseType === 'blob' && res.data.type === 'application/json') {
                    const reader = new FileReader();
                    reader.readAsText(res.data, 'utf-8');
                    reader.onload = () => {
                        const result: any = reader.result  // 得到字符串
                        const jData = JSON.parse(result) // 解析成json对象
                    }
                } else {
                    let filename = res.headers['content-disposition'];
                    if (filename) {
                        filename = filename.match(/filename="(.*)"/)[1];
                        FileDownload(res.data, filename);
                    }
                }

            }
        ).catch((err) => {
            errorUtil.showErrorWithStore(err, history, eStore);
        });
    }

    const onCancelQrModel = () => {
        eStore.setQrModalVisible(false);
    }

    let QRAttachInfo = "";
    if (outSourceInfo.os_Account) {
        QRAttachInfo += outSourceInfo.os_Account+"-";
    }
    if (outSourceInfo.os_AssetNo) {
        QRAttachInfo += outSourceInfo.os_AssetNo;
    }
    if (outSourceInfo.equipment_NameCN) {
        QRAttachInfo += outSourceInfo.equipment_NameCN;
    }
    if (outSourceInfo.os_Content) {
        QRAttachInfo += outSourceInfo.os_Content;
    }
    if (outSourceInfo.OsNo) {
        QRAttachInfo += "(" + outSourceInfo.OsNo + ")";
    }

    return <Observer>{() =>
        <>
            <Loading loading={loading} />
            <Form form={form} layout="inline" className="reportbar" onFinish={handleSearch}>
                {/* <Form.Item label="BatchNo"> */}
                <Form.Item name="machineNo">
                    <Input size="large" allowClear placeholder={t('FormHeader.EquipmentNo')} />
                </Form.Item>
                <Form.Item name="applicationNo">
                    <Input size="large" allowClear placeholder={t('FormHeader.ApplicationNo')} />
                </Form.Item>
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading}>{t('Search')}</Button>
                </Form.Item>
                <Form.Item>
                    <Button size="large" icon={<SearchOutlined />} onClick={onOpenAddModal} disabled={loading}>{t('Add')}</Button>
                </Form.Item>
                <Form.Item className="summary"    >
                    {t('Total', { pcs: page.total })}
                </Form.Item>
            </Form>
            <OutSourcingTable loading={loading} onEdit={onEdit} onExport={onExport} dataSource={outSourcingRecordList} pagination={page} handleTableChange={handleReportTableChange} onShowSizeChange={onShowSizeChange} />
            <AddOutSourcingDrawer isEdit={isEdit} OsId={OsId} OsNo={OsNo} formRef={formRef} visible={eStore.addModalVisible} user={props.user} onAfterSubmit={onAfterSubmit} onCancel={onCloseAddModal} />
            <Modal title="Out Sourcing Application No QrCode" visible={qrModalVisible} maskClosable={false}
                footer={null} onCancel={onCancelQrModel} className="alignCenter">
                <QRCode id='qrid' value={outSourceInfo.OsNo} size={120} />
                <div className="maQR">{QRAttachInfo}</div>
            </Modal>
        </>
        //   )
    }</Observer>;
}

export default observer(OutSourcingManage);

