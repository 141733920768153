import "./UserTable.less";
import React, { useEffect, useState } from 'react';
import { ArrowDownOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Layout, Modal, Table, Form } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import Base64Util from "../../utils/Base64Util";
import axios from 'axios';
import store from '../../store';
import errorUtil from "../../utils/errorUtil";
import Loading from "../../components/Loading";

const { Content } = Layout;
let query: any = {}

interface Iprops {
    user: any
}


const UserList: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t, i18n } = useTranslation();
    const eStore: any = useLocalObservable(() => store.userStore);
    const [form] = Form.useForm();
    let { loading } = eStore;
    let userList = eStore.userList.slice();
    const [visible, setVisible] = useState(false);
    const [qrContent, setQrContent] = useState('');
    const [workNo, setWorkNo] = useState('');

    useEffect(() => {
        query = {}
        onSearch();
        return () => {
            eStore.setUserList([]);
        }
    }, [])

    const handleSearch = () => {
        //e.preventDefault();//阻止页面提交刷新
        onSearch();
    }

    const onSearch = () => {
        getSearchCondition();
        eStore.queryUserList(query).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const getSearchCondition = () => {
        let workNo = form.getFieldValue("workNo");
        if (!!workNo) {
            query.workNo = workNo;
        } else {
            delete query.workNo;
        }
        if (props.user.user_Role === "Supervisor") {
            query.group = props.user.user_Group;
        } else {
            delete query.group;
        }
        if (props.user.user_Role === "Manager") {
            query.department = props.user.user_Department;
        }else if(props.user.user_Role === "Admin"){
            query.department = "Enginner"
        } else {
            delete query.department;
        }
    }

    const openQrModal = (e: any, code: string, workNo: string) => {
        let qr = Base64Util.encodeBase64Content(Base64Util.encodeBase64Content(code));
        setVisible(true);
        setQrContent(qr);
        setWorkNo(workNo);
    }

    const onCancelModel = () => {
        setVisible(false);
    }

    const onDownloadImg = () => {
        var Qr: any = document.getElementById('qrid');
        let image = new Image();
        image.src = Qr.toDataURL("image/png");
        var a_link: any = document.getElementById('aId');
        a_link.href = image.src;
    }

    const onBatchDownload = () => {
        getSearchCondition();
        const FileDownload = require('js-file-download');
        eStore.setLoading(true);
        axios({ method: 'post', url: 'user/downloadQrCode', responseType: 'blob', data: query }).then(
            res => {
                eStore.setLoading(false);
                if (!res) {
                    return
                }
                let filename = res.headers['content-disposition'];
                if (filename) {
                    filename = filename.match(/filename="(.*)"/)[1];
                    FileDownload(res.data, filename);
                }

            }
        ).catch((err) => {
            errorUtil.showErrorWithStore(err, history, eStore);
        });
    }

    const userTableColumns: any = [{
        title: 'WorkNo',
        dataIndex: 'user_No',
        sorter: true,
        fixed: 'left',
        align: 'center',
        width: 120,
    }, {
        title: 'NameEN',
        dataIndex: 'user_NameEN',
        sorter: true,
    }, {
        title: 'NameEN',
        dataIndex: 'user_NameCN',
        sorter: true,
    }, {
        title: 'Role',
        dataIndex: 'user_Role',
        align: 'center',
    }, {
        title: 'Operation',
        dataIndex: 'Operation',
        render: (text: string, record: any) => (
            <div>
                <Button type="primary" onClick={(e) => { openQrModal(e, record.user_No + '-' + record.user_Role, record.user_No) }} >QRCode</Button>
            </div>
        )
    }
    ]




    return <Observer>{() =>
        <>
            <Loading loading={loading} />
            <Form form={form} layout="inline" className="reportbar" onFinish={handleSearch}>
                <Form.Item name="workNo">
                    <Input allowClear placeholder="workNo" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading}>Search</Button>
                </Form.Item>
                <Form.Item>
                    <Button icon={<ArrowDownOutlined />} onClick={onBatchDownload} disabled={loading}>Batch Download</Button>
                </Form.Item>
                <Form.Item className="summary">
                    {t('Total', { pcs: eStore.page.total })}
                </Form.Item>
            </Form>
            <Table columns={userTableColumns}
                rowKey={record => record.user_Id}
                dataSource={userList}
                pagination={false}
                loading={loading}
                scroll={{ x: 'max-content' }}
            />
            <Modal title="User QrCode" visible={visible}
                footer={null} onCancel={onCancelModel} className="alignCenter">
                <QRCode id='qrid' value={qrContent.toString()} size={240} />
                <p className="pMargin"><a download={workNo} id='aId' onClick={onDownloadImg} >
                    <Button type="primary" size="large" >Download</Button></a></p>
            </Modal>
        </>
    }</Observer>;


}

export default observer(UserList);
