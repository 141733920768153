import { observable, action, runInAction,makeObservable } from 'mobx';
import http from '../utils/http';
import root from './root';
import moment from 'moment';

class ScheduleStore extends root {
    constructor() {
        super();
        makeObservable(this);
    }

    @observable dataList = [];
    @observable queryDate = moment(new Date());

    @action setDataList(dataList) {
        this.dataList = dataList;
    }

    @action setQueryDate(queryDate) {
        this.queryDate = queryDate;
    }

    @action async querySchLstByDate(queryObj) {
        this.loading = true;
        let res = await http.post('schedule/getSchLstByDate', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.dataList = data.obj;
                }
            }
        })
        return data;
    }
    

    @action async updateSchedule(sch) {
        this.loading = true;
        let res = await http.post('schedule/updateSchedule', sch);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                const index = this.dataList.findIndex(item => sch.sch_Id === item.sch_Id);
                if(index > -1){
                    const item = this.dataList[index];
                    this.dataList.splice(index,1,{...item,...sch});
                }
            }

        })
        return data;
    }

}

const scheduleStore = new ScheduleStore();
export default scheduleStore;