import "../../style/maintenanceReport.less";
import React, { useEffect, useState,useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Button, Form, Tabs } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EnginnerWorkRecordTable from './components/EnginnerWorkRecordTable';
import errorUtil from "../../utils/errorUtil";
import Loading from "../../components/Loading";
// import http from '../utils/http';
import store from '../../store';
import WorkerDailyWorkTimeTable from './components/WorkerDailyWorkTimeTable';
import moment from 'moment';

interface Iprops {
    user: any
}

const { TabPane } = Tabs;
let query: any = {}


const EnginnerWorkRecord: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    // const [loading, setLoading] = useState(false);
    // const [total, setTotal] = useState(0);
    // const [maintenanceRecordList, setMaintenanceRecordList] = useState([]);
    const eStore: any = useLocalObservable(() => store.workRecordStore);
    //query.pageSize = eStore.pageSize;
    query.technician = props.user.user_NameCN;
    const [form] = Form.useForm();
    const [WorkTimeModalVisible, setWorkTimeModalVisible] = useState(false);
    let maintenanceRecordList = eStore.maintenanceRecordList.slice();
    let { page, loading, queryDate, currentDateWorkTime } = eStore;
    let childRef:any =  useRef();

    useEffect(() => {
        query = { technician: props.user.user_NameCN }
        onSearch();
        return () => {
            eStore.setMaintenanceRecordList([]);
        }
    }, [])

    //无奈，需要在渲染完成强制修改DatePicker的显示值
    useEffect(() => {
        let dp = document.getElementById('date1') as HTMLInputElement;
        if (dp) {
            dp.value = queryDate.format('YYYY/MM/DD');
        }
        // summaryform.setFieldsValue({'datePicker':queryDate});
    })
   

    // const onTabChange = (key: any) => {
    //     if (key === '1') {
    //         onSearch();
    //     } else if (key === '2') {

    //     }
    // }


    const handleSearch = () => {
        // e.preventDefault();//阻止页面提交刷新
        onSearch();
    }

    const oncheckApplicationNo = (applicationNo: string) => {
        let match = /^\d+$/;
        let match2 = /^t\d+$/;
        return !(match2.test(applicationNo) || match.test(applicationNo));
    }

    const onSearch = () => {
        getSearchCondition();
        let isVaild = true;
        if (query.applyNo) {
            if (oncheckApplicationNo(query.applyNo.trim())) {
                isVaild = false;
                errorUtil.showErrorMsg(t('EwRecord.ApplyNoFormat'));
            }
        }
        if (isVaild) {
            eStore.queryLocalRecordList(query).then((v: any) => {
                if (v.status === 0) {
                    errorUtil.showErrorMsg(v.err);
                }
            }).catch((ex: any) => {
                errorUtil.showErrorWithStore(ex, history, eStore);
            });

            getCurrentDateWorkTime(false);
        }
    }

    const getCurrentDateWorkTime =(isGetMpLst:boolean)=> {
        let queryMp = { Type: 'today', WorkNo: props.user.user_No };
        eStore.queryCurrentDateWorkerMpLst(queryMp,isGetMpLst).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            }
        }).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });
    }

    const getSearchCondition = () => {
        let applicationNo = form.getFieldValue("applicationNo");
        if (!!applicationNo) {
            query.applyNo = applicationNo;
        } else {
            delete query.applyNo;
        }
        let machineNo = form.getFieldValue("machineNo");
        if (!!machineNo) {
            query.machineNo = machineNo;
        } else {
            delete query.machineNo;
        }
        // query.pageSize = eStore.pageSize;
    }

    const onSearchAll = () => {
        // let applicationNo = form.getFieldValue("applicationNo");
        // let qObj = { applyNo: applicationNo };
        getSearchCondition();
        let isVaild = true;
        if (query.applyNo) {
            if (oncheckApplicationNo(query.applyNo.trim())) {
                isVaild = false;
                errorUtil.showErrorMsg(t('EwRecord.ApplyNoFormat'));
            }
        }
        if (isVaild) {
            if ((!query.applyNo || (query.applyNo && query.applyNo.trim() === ""))
                && (!query.machineNo || (query.machineNo && query.machineNo.trim() === ""))) {
                errorUtil.showErrorMsg(t('InputSearch'));
            } else {
                eStore.queryAllRecordList(query).then((v: any) => {
                    if (v.status === 0) {
                        if (v.err.indexOf("FMSQLException") > 0) {
                            errorUtil.showErrorMsg(t('FMEx'));
                        } else {
                            errorUtil.showErrorMsg(v.err);
                        }
                    }
                }).catch((ex: any) => {
                    errorUtil.showErrorWithStore(ex, history, eStore);
                });
            }
        }
    }

    const handleReportTableChange = (pagination: any, fliters: any, sorter: any) => {
        getSearchCondition();
        const sortFiled = sorter.field;
        let sortOrder = sorter.order;
        if (sortOrder !== undefined && sortOrder !== null) {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc'
            } else if (sortOrder === 'descend') (
                sortOrder = 'desc'
            )
        }
        query.sortFiled = sortFiled;
        query.sortOrder = sortOrder;
        //query.pageSize = pagination.pageSize;
        const current = pagination.current;
        if (current !== undefined && current !== null) {
            if (current > 1) {
                query.currIndex = (current - 1) * pagination.pageSize;
            } else {
                query.currIndex = 0;
            }
        }
        eStore.queryMaintenanceRecordList(query).catch((e: any) => {
            //errorUtil.showError(e, history);
            // setLoading(false);
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const onShowSizeChange = (current: number, pageSize: number) => {
        // eStore.setPageSize(pageSize);
    }

    const jumpToWorkForm = (formData: any) => {
        //state 对象不能过大，不然无法clone
        history.push({ pathname: '/app/workTime', state: { applyNo: formData } });
        //history.push('/app/workTime');
    }

    const onCloseWorkTimeModal = () => {
        setWorkTimeModalVisible(false);
    }

    const onOpenWorkTimeModal = () => {
        getCurrentDateWorkTime(true);
        setWorkTimeModalVisible(true);
        childRef.current.setDatePicker(eStore.currentDate);
    }


    return <Observer>{() =>
        <>
            <Loading loading={loading} />
            {/* <Tabs defaultActiveKey="1" onChange={onTabChange} centered size="large">
                <TabPane tab={t('Report.DailySummary')} key="1"> */}
            <Form form={form} layout="inline" className="reportbar" onFinish={handleSearch}>
                {/* <Form.Item label="BatchNo"> */}
                <Form.Item name="machineNo">
                    <Input size="large" allowClear placeholder={t('FormHeader.EquipmentNo')} />
                </Form.Item>
                <Form.Item name="applicationNo">
                    <Input size="large" allowClear placeholder={t('FormHeader.ApplicationNo')} />
                </Form.Item>
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading}>{t('EwRecord.SearchTask')}</Button>
                </Form.Item>
                <Form.Item>
                    <Button size="large" icon={<SearchOutlined />} onClick={onSearchAll} disabled={loading}>{t('EwRecord.SearchAll')}</Button>
                </Form.Item>
                <Form.Item>
                    <Button size="large" onClick={onOpenWorkTimeModal} disabled={loading}>{t('WorkTime', { hours:  currentDateWorkTime})}</Button>
                </Form.Item>
                <Form.Item className="summary"    >
                    {t('Total', { pcs: page.total })}
                </Form.Item>
            </Form>
            <EnginnerWorkRecordTable loading={loading} isRNA={false} jumpToWorkForm={jumpToWorkForm} dataSource={maintenanceRecordList} handleTableChange={handleReportTableChange} onShowSizeChange={onShowSizeChange} />
            {/* </TabPane>
                <TabPane tab={t('Report.DailySummary')} key="2"> */}

            <WorkerDailyWorkTimeTable cRef={childRef} visible={WorkTimeModalVisible} onCancel={onCloseWorkTimeModal} workNO={props.user.user_No} loading={loading} />
            {/* </TabPane>
            </Tabs> */}
        </>
        //   )
    }</Observer>;
}

export default observer(EnginnerWorkRecord);



// export default EnginnerWorkRecord;