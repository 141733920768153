import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { Route, Switch, useHistory } from 'react-router-dom';
import '../style/app.css';
import { webConfig } from '../config/webConfig';
import errorUtil from "../utils/errorUtil";
import HeaderCustom from "../components/HeaderCustom";
import AuthRoute from '../routes/AuthRoute';
import Nomatch from './404';
import EnginnerWorkRecord from './EnginnerWorkRecord/EnginnerWorkRecord';
import MaintenanceForm from './MaintenanceForm/MaintenanceForm';
import AssignTask from './AssignTask';
import ReasonAnalyRecord from "./EnginnerWorkRecord/ReasonAnalyRecord";
import ReasonAnalyForm from "./MaintenanceForm/ReasonAnalyForm";
import MProcessingReport from './MReport/MProcessingReport';
import ApplyManageRecord from './ApplyManageRecord';
import UserList from './UserList';
import Schedule from './Schedule';
import OutSourcingManage from './OutSourcingManage';
import OutSourcingWorkRecord from './OutSourcingWorkRecord';
import OutSourcingProcess from './OutSourcingProcess';

const { Content, Footer } = Layout
const App: React.FC = () => {
    let history = useHistory();
    let sessionUser = sessionStorage.getItem("user");
    let user: any = null;
    if (!!sessionUser) {
        user = JSON.parse(sessionUser);
    }

    useEffect(() => {
        if (!user) {
            errorUtil.showErrorWithCB('You do not have permission!', logout);
        }
    }, [])


    const logout = () => {
        sessionStorage.setItem("token", '');
        sessionStorage.setItem("isAuth", '0');
        sessionStorage.setItem("user", '');
        history.push('/');
    }

    const goBack = ()=>{
        history.goBack();
    }

    const goMain = ()=>{
        history.push('/app');
    }

    const appComponent = () =>{
        if(user.user_Role === 'Admin'){
            return <MProcessingReport user={user} />;
        }
        else if(user.user_Department === 'Enginner'){
            return <EnginnerWorkRecord user={user} />;
        }else if(user.user_Role ===  'Supplier'){
            return <OutSourcingWorkRecord user={user} />;
        }
        else{
            return <ApplyManageRecord user={user} />;
        }
    }

    const userNo = user ? user.user_No + ' ' + user.user_NameCN : '';
    return (
        <Layout>
            <HeaderCustom username={userNo} user={user} onlogout={logout} onGoMain={goMain}/>
            <Content className="content home">
                {/* 使footor固定在底部，需要设置flex：1 0 auto */}
                <Switch>
                    <AuthRoute exact path={'/app'} component={appComponent} />
                    <AuthRoute path="/app/workTime" component={() => (<MaintenanceForm user={user} />)}></AuthRoute>
                    <AuthRoute path="/app/assign" component={() => (<AssignTask user={user} />)}></AuthRoute>
                    <AuthRoute path="/app/reason" component={() => (<ReasonAnalyRecord user={user} />)}></AuthRoute>
                    <AuthRoute path="/app/analy" component={() => (<ReasonAnalyForm user={user} />)}></AuthRoute>
                    <AuthRoute path="/app/report" component={() => (<MProcessingReport user={user} />)}></AuthRoute>
                    <AuthRoute path="/app/user" component={() => (<UserList user={user} />)}></AuthRoute>
                    <AuthRoute path="/app/osm" component={() => (<OutSourcingManage user={user} />)} />
                    <AuthRoute path="/app/oswr" component={() => (<OutSourcingWorkRecord user={user} />)} />
                    <AuthRoute path="/app/osp" component={() => (<OutSourcingProcess user={user} />)} />
                    <AuthRoute path="/app/sch" component={() => (<Schedule user={user} />)} />
                    {/* <AuthRoute path="/app/sectionLst" component={SectionLst}></AuthRoute> */}
                    <Route component={Nomatch} />
                </Switch>
            </Content>
            <Footer className="footer">
                Engineering Maintenance System {webConfig.version} Power By IT-SCADA<br />
                Copyright © 2021 ISA Industrial Ltd. All rights reserved.
            </Footer>
        </Layout>
    )
}

export default App;