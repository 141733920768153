import { observable, action, runInAction, makeObservable } from 'mobx';
import { webConfig } from "../config/webConfig";
import http from '../utils/http';
import root from './root';
import moment from 'moment';

class WorkRecordStore extends root {
    constructor() {
        super();
        makeObservable(this);
    }
    @observable maintenanceRecordList = [];
    @observable pageSize = webConfig.reportPageSize;
    @observable page = {
        total: 0,
        defaultPageSize: webConfig.reportPageSize
    };
    @observable queryDate = moment(new Date());
    @observable dailyMpList = [];
    @observable currentDate = moment(new Date());
    @observable currentDateWorkTime = "0";
    @observable dailyWorkTime = "0";

    @action setMaintenanceRecordList(maintenanceRecordList) {
        this.maintenanceRecordList = maintenanceRecordList;
    }

    @action setPage(page) {
        this.page = page;
    }

    @action setPageSize(pageSize) {
        this.pageSize = pageSize;
    }

    @action setQueryDate(queryDate) {
        this.queryDate = queryDate;
    }

    @action setDailyMpList(dailyMpList) {
        this.dailyMpList = dailyMpList;
    }

    @action setDailyWorkTime(dailyWorkTime) {
        this.dailyWorkTime = dailyWorkTime;
    }

    @action setCurrentDate(currentDate) {
        this.currentDate = currentDate;
    }

    @action setCurrentDateWorkTime(currentDateWorkTime) {
        this.currentDateWorkTime = currentDateWorkTime;
    }

    @action async queryMaintenanceRecordList(queryObj) {
        this.loading = true;
        let res = await http.get('mapp/getMALstByTech', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.maintenanceRecordList = data.obj.maintenanceRecordList;
                    this.page.total = data.obj.total
                }
            }

        })
        return data;
    }

    @action async queryMaintenanceLstByNoNTech(queryObj) {
        this.loading = true;
        let res = await http.get('mapp/getMALstByNoNTech', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.maintenanceRecordList = data.obj.maintenanceRecordList;
                    this.page.total = data.obj.total
                }
            }

        })
        return data;
    }

    @action async queryLocalRecordList(queryObj) {
        this.loading = true;
        let res = await http.get('mac/getMALstByTech', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.maintenanceRecordList = data.obj.maintenanceRecordList;
                    this.page.total = data.obj.total
                }
            }

        })
        return data;
    }

    @action async queryAllRecordList(queryObj) {
        this.loading = true;
        let res = await http.get('mac/getMALstIcFMByNoTech', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.maintenanceRecordList = data.obj.maintenanceRecordList;
                    this.page.total = data.obj.total
                }
            }

        })
        return data;
    }

    @action async searhMaintenanceLstByNo(queryObj) {
        this.loading = true;
        let res = await http.get('mapp/getMAByApplyNo', queryObj);
        runInAction(() => {
            this.maintenanceRecordList = res.data.obj;
            this.page.total = this.maintenanceRecordList.length;
            this.loading = false;
        })
    }

    @action async getCurrentDate() {
        this.loading = true;
        let res = await http.get('mac/getCurrentDate', null);
        runInAction(() => {
            //this.queryDate = moment(res.data);
            this.loading = false;
        })
        return res.data;
    }

    @action async queryDaliyWorkerMpLst(queryObj) {
        this.loading = true;
        let res = await http.post('mpc/getDaliyWorkerMpLst', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.dailyMpList = data.obj.mpLst;
                    this.dailyWorkTime = data.obj.workTime;
                }
            }
        })
        return data;
    }

    @action async queryCurrentDateWorkerMpLst(queryObj,isGetMpLst) {
        this.loading = true;
        let res = await http.post('mpc/getDaliyWorkerMpLst', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.currentDateWorkTime = data.obj.workTime;
                    let today =new Date(data.obj.currentDate); 
                    this.currentDate = moment(today);
                    if(isGetMpLst){
                        this.dailyMpList = data.obj.mpLst;
                        this.dailyWorkTime = this.currentDateWorkTime;
                        this.queryDate = this.currentDate;
                    }
                }
            }
        })
        return data;
    }



}

const workRecordStore = new WorkRecordStore();

export default workRecordStore;
