import React, { ReactNode } from 'react';
import { Button, Modal, Form, Table, Input, message, Popconfirm } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import errorUtil from "../../../utils/errorUtil";
import store from '../../../store';

interface Iprops {
    title: ReactNode,
    loading: boolean,
    visible: boolean,
    user: any,
    onClose:()=>void
}

const AssignWorkerModal: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    const { loading, visible, user,onClose } = props;
    const eStore: any = useLocalObservable(() => store.assignStore);
    const title = props.title + " "+eStore.applicationNo;

    const columns = [
        { title: t('TimeTable.WorkNo'), dataIndex: 'user_No', key: 'user_No' },
        { title: t('TimeTable.Name'), dataIndex: 'user_NameCN' },
        { title: t('TimeTable.Quantity'), dataIndex: 'workingNum' },
        {
            title: t('FormHeader.Operation'), dataIndex: 'operation', key: 'Action',
            render: (text: string, record: any) => (
                <div>
                    {!record.applicationNo ?
                        <Button onClick={() => onAssignWorker(record.user_NameCN, record.user_Id)}>{t('Add')}</Button>
                        : record.state === "Wait" ? <Popconfirm placement="topRight" title={t('AssignForm.Suretoremove')}
                            onConfirm={() => onDelete(record.mp_Id, record.user_Id)}>
                            <Button danger>{t('Remove')}</Button>
                        </Popconfirm> : ''}
                </div>
            )
        }
    ];

    const onAssignWorker = (workerName: any, workerId: number) => {
        eStore.onAssignWorker(eStore.applicationNo, workerName, user.user_NameEN).then((v: any) => {
            message.success(t('AddSuccess'));
            let selArr = [...eStore.selectedKeys];
            selArr.push(workerId);
            eStore.setSelectedKeys(selArr);
            let assignArr = [...eStore.assignWorkerLst];
            for (let i = 0; i < assignArr.length; i++) {
                if (assignArr[i].user_Id === workerId) {
                    assignArr[i].applicationNo = eStore.applicationNo;
                    assignArr[i].state = "Wait";
                    assignArr[i].mp_Id = v.obj;
                    assignArr[i].workingNum++;
                }
            }
            eStore.setAssignWorkerLst(assignArr);
            eStore.setIsModifyWorker(true);
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }


    const onDelete = (recordID: number, workerId: number) => {

        eStore.onDeleteRecord(recordID).then((v: any) => {
            if (v.status) {
                message.success(t('RemoveSuccess'));
                let selArr = [...eStore.selectedKeys];
                let sIndex = selArr.findIndex(v => v === workerId);
                if (sIndex >= 0) {
                    selArr.splice(sIndex, 1);
                    eStore.setSelectedKeys(selArr);
                }
                let assignArr = [...eStore.assignWorkerLst];
                for (let i = 0; i < assignArr.length; i++) {
                    if (assignArr[i].user_Id === workerId) {
                        assignArr[i].applicationNo = null;
                        assignArr[i].workingNum--;
                    }
                }
                eStore.setAssignWorkerLst(assignArr);
                eStore.setIsModifyWorker(true);
            } else if (v.status === -1) {
                errorUtil.showErrorMsg(t('AssignForm.startNoRemove'));
            }
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    

    const rowSelection = {
        selectedRowKeys: eStore.selectedKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
            //eStore.setSelectedKeys(selectedRowKeys);
            //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
    };

    //return (
    return <Observer>{() =>
        <Modal title={title}
            closable={true}
            maskClosable={false}
            visible={visible}
            onCancel={onClose}
            wrapClassName="orderModal"
            width='800'
            footer={null}
        >
            {/* <Form layout="inline" name="AddWorker2CrewModal" className="reportbar" onFinish={onSearch}>
                <Form.Item name="workNo" rules={[{
                    required: true,
                    message: 'Please input the workNo!',
                }]}>
                    <Form.Item name="workNo">
                        <Input size='large' allowClear placeholder="workNo" />
                    </Form.Item>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" size='large' htmlType="submit" disabled={loading}>Search</Button>
                </Form.Item>
            </Form> */}
            <Table columns={columns}
                rowKey={record => record.user_Id}
                dataSource={eStore.assignWorkerLst}
                loading={loading}
                scroll={{ x: 'max-content' }}
                rowSelection={rowSelection}
                pagination={false}
            />
        </Modal>
        //   )
    }</Observer>;

}

export default observer(AssignWorkerModal);