import i18n from '../language/i18n';

export default class TimeUtil {

    static getMinuteDiffForm2Times(startTime:any,endtime:any){
        let diff = endtime - startTime;
        return  Math.floor(diff / (1000 * 60 ));
    }

    static getWeek(e:number){
        let weekArr:string[] = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
        let weekCNArr:string[] = ['星期日','星期一','星期二','星期三','星期四','星期五','星期六'];
        return i18n.language === "en"?weekArr[e]:weekCNArr[e];
    }
}



