import { observable, action, runInAction, makeObservable } from 'mobx';
import { webConfig } from "../config/webConfig";
import http from '../utils/http';
import root from './root';

class ReasonAnalyStore extends root {
    constructor() {
        super();
        makeObservable(this);
    }
    @observable maintenanceRecordList = [];
    @observable pageSize = webConfig.reportPageSize;
    @observable page = {
        total: 0,
        defaultPageSize: webConfig.reportPageSize
    };

    @action setMaintenanceRecordList(maintenanceRecordList) {
        this.maintenanceRecordList = maintenanceRecordList;
    }

    @action setPage(page) {
        this.page = page;
    }

    @action setPageSize(pageSize) {
        this.pageSize = pageSize;
    }


    @action async queryLocalRecordList(queryObj) {
        this.loading = true;
        let res = await http.post('mac/getReasonAnalyMALst', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.maintenanceRecordList = data.obj.maintenanceRecordList;
                    this.page.total = data.obj.total
                }
            }
        })
        return data;
    }

}

const reasonAnalyStore = new ReasonAnalyStore();

export default reasonAnalyStore;
