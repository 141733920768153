import React, { ReactNode } from 'react';
import { Modal,Table } from 'antd';
import { useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import store from '../../../store';

interface Iprops {
    applicationNo: string,
    visible: boolean,
    loading: boolean,
    onCancel: () => void,
}


const WorkTimeLstModal: React.FC<Iprops> = (props) => {
    const { applicationNo,visible, loading,onCancel } = props;
    const { t, i18n } = useTranslation();
    const eStore: any = useLocalObservable(() => store.mReportStore);
    let title = t('MaintenForm.MANo', { MaNo: applicationNo });

    const EnginnerTimeRecordColumns:any = [
        {
            title: t('TimeTable.Seq'),
            render: (text: string, record: any, index: number) => `${index + 1}`
        }, {
            title: t('TimeTable.State'),
            dataIndex: 'mp_State',
            render: (val: string) => {
                if (i18n.language === "en") {
                    return val;
                } else {
                    switch (val) {
                        case "Wait":
                            return "等待开始"
                        case "Start":
                            return "开始";
                        case "Pause":
                            return "暂停";
                        case "End":
                            return "结束";
                        case "Finished":
                            return "完成";
                        default:
                            return "";
                    }
                }
            }
        }, {
            title: t('TimeTable.Name'),
            dataIndex: 'mp_Technician',
        }, {
            title: t('TimeTable.From_Time'),
            render: (text: string, record: any) => {
                if (record.mp_FromTime) {
                    let time = moment(record.mp_FromTime).format("MM/DD/YYYY HH:mm:ss")
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('TimeTable.To_Time'),
            render: (text: string, record: any) => {
                if (record.mp_ToTime) {
                    let time = moment(record.mp_ToTime).format("MM/DD/YYYY HH:mm:ss")
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('TimeTable.WorkingTime'),
            align: 'center',
            render: (text: any, record: any) => {
                if (record.mp_SpendTime) {
                    let hour = Math.floor(record.mp_SpendTime / 3600);
                    let min = Math.floor((record.mp_SpendTime % 3600) / 60);
                    let second = record.mp_SpendTime % 3600 - min * 60;
                    if (hour > 0) {
                        if (min > 0) {
                            if (second > 0) {
                                return hour + "h" + min + "m" + second + "s";
                            } else {
                                return hour + "h" + min + "m";
                            }
                        } else {
                            if (second > 0) {
                                return hour + "h" + second + "s";
                            } else {
                                return hour + "h";
                            }
                        }
                    }else{
                        if (min > 0) {
                            if (second > 0) {
                                return  min + "m" + second + "s";
                            } else {
                                return  min + "m";
                            }
                        } else {
                            if (second > 0) {
                                return second + "s";
                            } else {
                                return '0';
                            }
                        }
                    }
                } else {
                    return 0;
                }
            }
        }, {
            title: t('TimeTable.Remarks'),
            dataIndex: 'mp_Remarks',
        }
    ]

    return (
         <Modal title={title} closable={true} maskClosable={false}
            visible={visible}  width={1000}
            footer={null} onCancel={onCancel}
         >
            <Table 
            columns={EnginnerTimeRecordColumns}
            rowKey={(record: any) => record.id}
            dataSource={eStore.maMpList.slice()}
            pagination={false}
            bordered
            loading={loading}
            //rowClassName={getRowClassName}
            size='middle'
            // onChange={props.handleTableChange}
            scroll={{ x: 'max-content' }}
        />
        </Modal>
        
    )

}

export default WorkTimeLstModal;