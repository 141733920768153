import React, { useEffect, useRef, useState } from 'react';
import { SearchOutlined, LeftSquareOutlined, RightSquareOutlined } from '@ant-design/icons';
import { Button, Layout, Form, DatePicker,message, Popconfirm } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import type { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { EditableProTable } from '@ant-design/pro-table';
import errorUtil from "../../utils/errorUtil";
import Loading from "../../components/Loading";
import store from '../../store';

interface Iprops {
    user: any
}

let query: any = { }

const Schedule: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t,i18n } = useTranslation();
    const eStore: any = useLocalObservable(() => store.scheduleStore);
    const [form] = Form.useForm();
    const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
    const ref = useRef<ActionType>();
    let dataLst = eStore.dataList.slice();
    let { queryDate,loading } = eStore;

    // useEffect(() => {
    // }, [])

     //无奈，需要在渲染完成强制修改DatePicker的显示值
     useEffect(() => {
        let dp = document.getElementById('date1') as HTMLInputElement;
        dp.value = queryDate.format('YYYY/MM/DD');
        // summaryform.setFieldsValue({'datePicker':queryDate});
    })


    const handleTableChange = (params: any, sort: any, filter: any): Promise<Partial<RequestData<any>>> => {
        if (editableKeys.length > 0) {
            ref.current?.cancelEditable(editableKeys[0]);
        }
        let date = form.getFieldValue("datePicker");
        query.date = date.format('YYYY/MM/DD');
        getSearchCondition();
        return eStore.querySchLstByDate(query);

    }

    const handleSummarySearch = () => {
            let date = form.getFieldValue("datePicker");
            // let date = queryDate;
            if (date) {
                query.date = date.format('YYYY/MM/DD');
                eStore.setQueryDate(date);
                SearchSchedule();
            } else {
                errorUtil.showErrorMsg(t('InputSearch'));
            }
    }

    const handlePreDaySearch = () => {
        let date = form.getFieldValue("datePicker");
        //  let date = queryDate;
        if (date) {
            let preDate = date.subtract(1, 'days');
            //summaryform.setFieldsValue({'datePicker':preDate});
            query.date = preDate.format('YYYY/MM/DD');
            eStore.setQueryDate(preDate);
            SearchSchedule();
        } else {
            errorUtil.showErrorMsg(t('InputSearch'));
        }

    }

    const handleNextDaySearch = () => {
        let date = form.getFieldValue("datePicker");
        if (date) {
            let nextDate = date.add(1, 'days');
            //summaryform.setFieldsValue({'datePicker':nextDate});
            query.date = nextDate.format('YYYY/MM/DD');
            eStore.setQueryDate(nextDate);
            SearchSchedule();
        } else {
            errorUtil.showErrorMsg(t('InputSearch'));
        }

    }

    const getSearchCondition = () => {
        if (props.user.user_Role === "Supervisor") {
            query.group = props.user.user_Group;
        } else {
            delete query.group;
        }
        if (props.user.user_Role === "Manager") {
            query.department = props.user.user_Department;
        }else if(props.user.user_Role === "Admin"){
            query.department = "Enginner"
        } else {
            delete query.department;
        }
    }

    const SearchSchedule = () => {
        getSearchCondition();
        eStore.querySchLstByDate(query).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            }
        }).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });

        
    }


    const tableColumns: ProColumns<any>[] = [
        {
            title: '工号',
            dataIndex: 'sch_UserNo',
            editable: false
        },{
            title: '姓名',
            dataIndex:  i18n.language === "en" ?'user_NameEN':'user_NameCN',
            editable: false
        }, {
            title: '组别',
            dataIndex: 'user_Group',
            editable: false
        },  {
            title: '日期',
            dataIndex: 'sch_Date',
            editable: false,
            renderText: (text: string, record: any) => {
                if (record.sch_Date) {
                    let time = moment(record.sch_Date).format("MM/DD/YYYY")
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: '班次',
            dataIndex: 'sch_Shift',
            valueType: 'select',
            valueEnum: {
                Day: { text: '日班', },
                Middle: { text: '中班', },
                Night: { text: '夜班', },
                Holiday:{ text: '休假', }
            },
        },
        {
            title: '操作',
            valueType: 'option',
            width: 150,
            render: (text: any, record: any, _: any, action: any) => [
                <a key="editable"
                    onClick={() => {
                        action?.startEditable?.(record.sch_Id);
                    }}
                >编辑</a>
            ],
        }
    ]

    return <Observer>{() =>
        <>
            <Loading loading={loading} />
            <Form form={form} layout="inline" className="reportbar" initialValues={{ 'datePicker': queryDate }} onFinish={handleSummarySearch}>
                <Form.Item>
                    <Button size="large" onClick={handlePreDaySearch} icon={<LeftSquareOutlined />} disabled={loading}>{t('Report.PreDay')}</Button>
                </Form.Item>
                <Form.Item name="datePicker" >
                    <DatePicker id="date1" size='large' format='YYYY/MM/DD' />
                </Form.Item>
                <Form.Item >
                    <Button size="large" onClick={handleNextDaySearch} icon={<RightSquareOutlined />} disabled={loading}>{t('Report.NextDay')}</Button>
                </Form.Item>
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading}>{t('Search')}</Button>
                </Form.Item>

            </Form>
            <EditableProTable<any>
                actionRef={ref}
                rowKey='sch_Id'
                headerTitle={t('Schedule')}
                //loading={loading}
                recordCreatorProps={false}
                toolBarRender={() => [

                ]}
                columns={tableColumns}
                options={{ fullScreen: false, reload: true, setting: false, density: false }}
                //params={params}
                request={async (params, sort, filter) => {
                    try {
                        let r = await handleTableChange(params, sort, filter)
                        return {
                            data: r.obj,
                            success: r.status?true:false,
                            //分页才需要下面这个
                            //total: r.total
                        };
                    } catch (e: any) {
                        errorUtil.showErrorWithStore(e, history, eStore);
                        return {
                            data: [],
                            success: true,
                            //total: 0
                        };
                    }
                }}
                value={dataLst}
                pagination={false}
                editable={{
                    type: 'single',
                    editableKeys,
                    actionRender: (row, config, defaultDom) => [defaultDom.save, defaultDom.cancel],
                    onSave: async (rowKey, data, row, newLine) => {
                        eStore.updateSchedule(data).then((r: any) => {
                            if (r.status < 1) {
                                errorUtil.showErrorMsg(r.msg);
                            }else{
                                message.success(t('UpdateSuccess'));
                            }
                        }).catch((e: any) => {
                            errorUtil.showErrorWithStore(e, history, eStore);
                        });
                    },
                    onChange: setEditableRowKeys,
                }}
            />
        </>
        //   )
    }</Observer>;
}

export default observer(Schedule);