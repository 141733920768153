import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';


interface Iprops {
    Header: any,
}

const OsProcessHead: React.FC<Iprops> = ({ Header }) => {
    const { t } = useTranslation();
    return (
        <table className="orderHeadTable">
            <tbody>
                <tr>
                    <td>{t('FormHeader.ApplicationNo')}：</td>
                    <td className="textLeft">{Header.os_FormNo}</td>
                    <td>{t('FormHeader.Status')}：</td>
                    <td className="textLeft">{Header.os_Status}</td>
                    <td>{t('FormHeader.ApplicationMan')}：</td>
                    <td>{Header.os_Applicant}</td>
                    <td>{t('FormHeader.ApplicationTime')}：</td>
                    <td>{Header.os_ApplicationDate ? moment(Header.os_ApplicationDate).format("MM/DD/YYYY HH:mm:ss") : ""}</td>
                </tr>
                <tr>
                    <td>{t('OutSourcing.Supplier')}：</td>
                    <td>{Header.os_Supplier}</td>
                    <td>{t('FormHeader.Entity')}：</td>
                    <td>{Header.os_EntityShort}</td>
                    <td>{t('FormHeader.EquipmentNo')}：</td>
                    <td>{Header.os_AssetNo}</td>
                    <td>{t('FormHeader.EquipmentName')}：</td>
                    <td>{Header.equipment_NameCN}</td>
                </tr>
                <tr>
                </tr>
                <tr>
                    <td>{t('FormHeader.ProblemDescription')}：</td>
                    <td colSpan={7} className="textLeft" >{Header.os_Content}</td>
                </tr>
            </tbody>
        </table>
    );

}

export default OsProcessHead;