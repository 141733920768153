import { observable, action, runInAction,makeObservable } from 'mobx';
import {webConfig} from "../config/webConfig";
import http from '../utils/http';
import root from './root';

class UserStore extends root {
    constructor() {
        super();
        makeObservable(this);
    }
    @observable userList = [];
    @observable page = {
        total: 0
    };

    @action setUserList(userList) {
        this.userList = userList;
    }

    @action setPage(page) {
        this.page = page;
    }
    

    @action async queryUserList(queryObj) {
        this.loading = true;
        //let res = await axios.post(baseURL+'user/queryUserList', queryObj);
        let res = await http.post('user/queryUserList', queryObj);
        runInAction(() => {
            this.userList = res.data.userList;
            this.page.total = res.data.total;
            this.loading = false;
        })

    }

}

const userStore = new UserStore();
export default userStore;