import { observable, action, runInAction, makeObservable } from 'mobx';
import http from '../utils/http';
import root from './root';

class OsWorkTimeStore extends root {
    constructor() {
        super();
        makeObservable(this);
    }
    @observable formData = {};
    @observable currentTimeRecord = {};
    @observable currentState = 'I';
    @observable timeList = [];

    @action setFormData(formData) {
        this.formData = formData;
    }

    @action setCurrentTimeRecord(currentTimeRecord) {
        this.currentTimeRecord = currentTimeRecord;
    }

    @action setCurrentState(currentState) {
        this.currentState = currentState;
    }

    @action setTimeList(timeList) {
        this.timeList = timeList;
    }

    @action async queryOsFormNTimeListByNo(queryObj) {
        this.loading = true;
        let res = await http.get('osf/getOsFormNTimeListByNo', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                this.formData = res.data.obj.Header;
                this.timeList = res.data.obj.timeLst;
                if (this.timeList.length > 0) {
                    this.currentTimeRecord = {};
                    for (let i = 0; i < this.timeList.length; i++) {
                        if(this.timeList[i].osp_UserNo === queryObj.technician){
                            this.currentTimeRecord = this.timeList[i];
                            break;
                        }
                    }
                    this.currentState = this.currentTimeRecord.osp_State ? this.currentTimeRecord.osp_State : 'I';
                }else{
                    this.currentTimeRecord = {};
                    this.currentState = 'New';
                }
                if(this.formData.os_Status === 'Finished'){
                    this.currentState = 'End';
                }
            }
        })
        return data;
    }

    @action async queryStartRecord(queryObj) {
        this.loading = true;
        let res = await http.get('osp/getOSPLstByTechNState', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
        })
        return data;
    }

    @action async onStartTime(applyNo, user) {
        this.loading = true;
        let res;
        if (this.currentState === 'Wait') {
            res = await http.put('osp/updateOSPFromTime/' + this.currentTimeRecord.osp_Id + '/' + applyNo + '/' + user);
        } else {
            res = await http.put('osp/addNewOSPWithStart/' + applyNo + '/' + user);
        }
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj.length > 0) {
                    this.timeList = data.obj;
                    this.currentTimeRecord = {};
                    for (let i = 0; i < this.timeList.length; i++) {
                        if(this.timeList[i].osp_UserNo === user){
                            this.currentTimeRecord = this.timeList[i];
                            break;
                        }
                    }
                    this.currentState = this.currentTimeRecord.osp_State ? this.currentTimeRecord.osp_State : 'I';
                }
            }
        })
        return data;
    }

    @action async onPauseOrFinishTime(applyNo,user,state) {
        this.loading = true;
        let res = await http.put('osp/updateOSPToTime/' + this.currentTimeRecord.osp_Id + '/' + state + '/' + applyNo + '/' + user);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj.length > 0) {
                    this.timeList = data.obj;
                    this.currentTimeRecord = {};
                    for (let i = 0; i < this.timeList.length; i++) {
                        if(this.timeList[i].osp_UserNo === user){
                            this.currentTimeRecord = this.timeList[i];
                            break;
                        }
                    }
                    this.currentState = this.currentTimeRecord.osp_State ? this.currentTimeRecord.osp_State : 'I';
                }
            }
        })
        return data;
    }

    @action async onPauseOtherNStartTime(applyNo, user) {
        this.loading = true;
        let type = "Add";
        if (this.currentState === 'Wait') {
            type = "Update";
        } 
        let id = this.currentTimeRecord.osp_Id?this.currentTimeRecord.osp_Id:0 
        let res = await http.put('osp/pasueOtherNStart/' +id + '/' + applyNo + '/' + user+ '/' + type);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj.length > 0) {
                    this.timeList = data.obj;
                    this.currentTimeRecord = {};
                    for (let i = 0; i < this.timeList.length; i++) {
                        if(this.timeList[i].osp_UserNo === user){
                            this.currentTimeRecord = this.timeList[i];
                            break;
                        }
                    }
                    this.currentState = this.currentTimeRecord.osp_State ? this.currentTimeRecord.osp_State : 'I';
                }
            }
        })
        return data;
    }

}

const osWorkTimeStore = new OsWorkTimeStore();

export default osWorkTimeStore;
