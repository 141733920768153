import React from 'react';
import { UserOutlined, UnorderedListOutlined,BorderOuterOutlined,
     LoginOutlined, DiffOutlined,InfoCircleOutlined,
     BarsOutlined,TeamOutlined,ScheduleOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const { Header } = Layout;

interface Iprops {
    username: string,
    user: any,
    onlogout: () => void
    onGoMain: () => void
}

const HeaderCustom: React.FC<Iprops> = (props) => {
    const { t } = useTranslation();
    let history = useHistory();
    const { username, user } = props;
    //let match = useRouteMatch();
    //export default class HeaderCustom extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         collapsed: props.collapsed,
    //     }
    // }
    // componentWillReceiveProps(nextProps) {
    //     //console.log(nextProps);
    //     this.onCollapse(nextProps.collapsed);
    // }
    // onCollapse = (collapsed) => {
    //     this.setState({
    //         collapsed,
    //     });
    // };
    // const logout = () => {
    //     onlogout();
    // }

    const onClick = (menu: any) => {
        if (menu.key === 'main') {
            props.onGoMain();
        } else if (menu.key === 'logout') {
            props.onlogout();
        } else if (menu.key === 'assign') {
            history.push('/app/assign');
        }else if (menu.key === 'reason') {
            history.push('/app/reason');
        }else if (menu.key === 'report') {
            history.push('/app/report');
        }else if (menu.key === 'userlist') {
            history.push('/app/user');
        }else if (menu.key === 'schlist') {
            history.push('/app/sch');
        }else if (menu.key === 'osmlist') {
            history.push('/app/osm');
        }else if (menu.key === 'oswlist') {
            history.push('/app/oswr');
        }
    }

    let logoutItem: React.ReactNode = (<Menu.Item key="logout" icon={<LoginOutlined style={{ fontSize: 18, color: '#6a3024' }} />}>
        {t('Logout')}
    </Menu.Item>);
    let reasonItem: React.ReactNode = (<Menu.Item key="reason" disabled={false} icon={<InfoCircleOutlined style={{ fontSize: 18, color: '#6a3024' }} />}>
     {t('Reason')}
    </Menu.Item>);
    let assignItem: React.ReactNode = (<Menu.Item key="assign" disabled={false} icon={<DiffOutlined style={{ fontSize: 18, color: '#6a3024' }} />}>
        {t('Assign')}
    </Menu.Item>);
    let mpItem: React.ReactNode = (<Menu.Item key="report" disabled={false} icon={<BarsOutlined style={{ fontSize: 18, color: '#6a3024' }} />}>
        {t('Reports')}
    </Menu.Item>);
    let userItem: React.ReactNode = (<Menu.Item key="userlist" disabled={false} icon={<TeamOutlined style={{ fontSize: 18, color: '#6a3024' }} />}>
        {t('User')}
    </Menu.Item>);
    let schItem: React.ReactNode = (<Menu.Item key="schlist" disabled={false} icon={<ScheduleOutlined  style={{ fontSize: 18, color: '#6a3024' }} />}>
        {t('Schedule')}
    </Menu.Item>);
    let osmItem: React.ReactNode = (<Menu.Item key="osmlist" disabled={false} icon={<BorderOuterOutlined  style={{ fontSize: 18, color: '#6a3024' }} />}>
       {t('OutSource')}
   </Menu.Item>);
    let oswItem: React.ReactNode = (<Menu.Item key="oswlist" disabled={false} icon={<BorderOuterOutlined  style={{ fontSize: 18, color: '#6a3024' }} />}>
       {t('OutSource')}
   </Menu.Item>);
    let itemArr = [];
    if(user.user_Department === 'Enginner'){
        if(user.user_Role === 'Supervisor' || user.user_Role === 'Senior Supervisor'){
            itemArr.push(reasonItem);
            itemArr.push(assignItem);
            itemArr.push(mpItem);
            itemArr.push(osmItem);
            // itemArr.push(schItem);
            itemArr.push(logoutItem);
        }else if(user.user_Role ===  'Manager'){
            itemArr.push(mpItem);
            itemArr.push(osmItem);
            itemArr.push(logoutItem);
        }else{
            itemArr.push(reasonItem);
            itemArr.push(logoutItem);
        }
    }
    else if(user.user_Department === 'Production' || user.user_Department === 'Environment' || user.user_Department === 'TrimTec'){
        itemArr.push(logoutItem);
    }else{
        if(user.user_Role ===  'Admin'){
            itemArr.push(userItem);
            itemArr.push(osmItem);
            // itemArr.push(schItem);
            itemArr.push(logoutItem);
        }else if(user.user_Role ===  'Supplier'){
            //itemArr.push(oswItem);
            itemArr.push(logoutItem);
        }
        else{
            itemArr.push(logoutItem);
        } 
    }


        return (
            <Header className="customHeader">
                <div className="logo"></div>
                <div className="title">{t('SYSName')}</div>
                {/* <LegacyIcon
                    className="trigger"
                    type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                    onClick={this.props.toggle}
                /> */}
                {/* {this.state.collapsed ? <MenuUnfoldOutlined  className="trigger" onClick={this.props.toggle} />  
                : <MenuFoldOutlined  className="trigger" onClick={this.props.toggle}/>} */}
                <Menu onClick={onClick}
                    mode="horizontal"
                     style={{ justifyContent:'flex-end' }}
                >
                    <Menu.Item key="user" icon={<UserOutlined style={{ fontSize: 18, color: '#6a3024' }} />}>
                        {username}
                    </Menu.Item>
                    <Menu.Item key="main" disabled={false} icon={<UnorderedListOutlined style={{ fontSize: 18, color: '#6a3024' }} />}>
                        {t('Main')}
                    </Menu.Item>
                    {itemArr}
                </Menu>
            </Header>
        );
}

export default HeaderCustom;