import React from 'react';
import { Table,Tag,Tooltip } from 'antd';
import moment from 'moment';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { EditableInputRow } from './EditableInputCell';
import { EditableInputCell } from './EditableInputCell';
import store from '../../../store';
import TimeUtil from '../../../utils/TimeUtil';


interface Iprops {
    loading: boolean,
    handleSave: (row: any) => void,
    //pagination: any,
    dataSource: object[],
    //handleTableChange: (pagination: any, fliters: any, sorter: any) => void,
    //onShowSizeChange: (current: any, pageSize: any) => void
}

const DayWorkTimeSumTable: React.FC<Iprops> = (props: Iprops) => {
    const { t, i18n } = useTranslation();
    const eStore: any = useLocalObservable(() => store.mReportStore);
    const {expandedRowKeys} = eStore;


    const dayWorkTimeSumTableColumns: any = [
        {
            title: t('TimeTable.Seq'),
            fixed: 'left',
            render: (text: string, record: any, index: number) => `${index + 1}`
        }, {
            title: t('Date'),
            //dataIndex: 'date',
            dataIndex: 'dr_Date',
            align: 'center',
            render:(text:string,record:any)=> {
                if(record.dr_Date){
                    return moment(record.dr_Date).format("YYYY/MM/DD");
                }else{
                    return '';
                }
            }
        },{
            title: t('Week'),
            align: 'center',
            render:(text:string,record:any)=> {
                if(record.dr_Date){
                    //let dateArr = record.date.split(" ");
                    //let date = moment(dateArr[0]);
                    let date = moment(record.dr_Date);
                    return TimeUtil.getWeek(date.day())
                }else{
                    return '';
                }
            }
        }, {
            title: t('TimeTable.Name'),
            //dataIndex: i18n.language === "en"?'user_NameEN':'technician',
            dataIndex: i18n.language === "en"?['user','user_NameEN']:['user','user_NameCN'],
            align: 'center',
        },  {
            title: t('Report.AttendanceTime'),
            dataIndex: 'dr_AttendTime',
            align: 'center',
            render: (aTime:number,record:any) =>{ 
                let date = moment(record.dr_Date);
                if(!record.dr_AttendanceStatus && (date.day() !==0 && date.day() !==6)){
                    return <Tag color="red">{aTime}</Tag>
                }else{
                    return aTime;
                }
            },
        }, {
            title: t('Report.OTTime'),
            dataIndex: 'dr_OTTime',
            align: 'center',
        }, {
            title: t('Report.LeaveTime'),
            dataIndex: 'dr_LeaveTime',
            align: 'center',
        },{
            title: t('TimeTable.WorkingTime'),
            //dataIndex: 'workTime',
            dataIndex: 'dr_WorkTime',
            align: 'center',
            render: (workTime:number,record:any) =>{ 
                // let dateArr = record.date.split(" ");
                // let date = moment(dateArr[0]);
                let date = moment(record.dr_Date);
                let leaveTime = record.dr_LeaveTime?record.dr_LeaveTime:0;
                let outWorkTime = record.dr_OutWorkTime?record.dr_OutWorkTime:0;
                let wt = workTime + leaveTime + outWorkTime;
                if((record.user?.user_Type  > 7 ) 
                &&((wt === 0 && (date.day() !==0 && date.day() !==6)) ||(wt > 0 && wt < 7) || wt > 8)){
                    return <Tag color="orange">{workTime}</Tag>
                }else{
                    return workTime;
                }
            },
        }, {
            title: t('Report.AbnormalCause'),
            dataIndex: 'dr_Reason',
            editable: true
        }
    ]

    const components = {
        body: {
            row: EditableInputRow,
            cell: EditableInputCell,
        },
    };
    const columns = dayWorkTimeSumTableColumns.map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: props.handleSave,
                noContent: <Tag color="orange">{t('Report.ToBeFilled')}</Tag>,
            }),
        };
    });

    const expandedRowRender = (record: any) => {
        const columns:any = [
            {
                title: t('FormHeader.ApplicationNo'),
                dataIndex: 'mp_Application_No',
            },{
                title: t('FormHeader.EquipmentNo'),
                dataIndex: 'ma_Machine_No'
            }, {
                title: t('FormHeader.EquipmentName'),
                dataIndex: i18n.language === "en"?'ma_Machine_Name':'ma_Machine_CNName',
                ellipsis:{
                    showTitle:false
                },
                render:(text:string)=>(
                    <Tooltip placement="topLeft" title={text}>
                        {text}
                    </Tooltip>
                )
            }, {
                title: t('TimeTable.State'),
                dataIndex: 'mp_State',
                render: (val: string) => {
                    if (i18n.language === "en") {
                        return val;
                    } else {
                        switch (val) {
                            case "Wait":
                                return "等待开始"
                            case "Start":
                                return "开始";
                            case "Pause":
                                return "暂停";
                            case "End":
                                return "结束";
                            case "Finished":
                                return "完成";
                            default:
                                return "";
                        }
                    }
                }
            }, {
                title: t('TimeTable.Name'),
                dataIndex: i18n.language === "en"?'user_NameEN':'mp_Technician',
            }, {
                title: t('TimeTable.From_Time'),
                render: (text: string, record: any) => {
                    if (record.mp_FromTime) {
                        let time = moment(record.mp_FromTime).format("MM/DD/YYYY HH:mm:ss")
                        return time;
                    } else {
                        return "";
                    }
                }
            }, {
                title: t('TimeTable.To_Time'),
                render: (text: string, record: any) => {
                    if (record.mp_ToTime) {
                        let time = moment(record.mp_ToTime).format("MM/DD/YYYY HH:mm:ss")
                        return time;
                    } else {
                        return "";
                    }
                }
            }, {
                title: t('TimeTable.WorkingTime'),
                align: 'center',
                render: (text: any, record: any) => {
                    if (record.mp_SpendTime) {
                        let hour = Math.floor(record.mp_SpendTime / 3600 * 100)/100;
                        return hour + "h";
                    } else {
                        return 0;
                    }
                }
            }, {
                title: t('TimeTable.Remarks'),
                dataIndex: 'mp_Remarks',
                ellipsis:{
                    showTitle:false
                },
                render:(text:string)=>(
                    <Tooltip placement="topLeft" title={text}>
                        {text}
                    </Tooltip>
                )
            },{
                title: t('FormHeader.MainCategory'),
                dataIndex: 'ma_MainCategory',
                ellipsis:{
                    showTitle:false
                },
                render:(text:string)=>(
                    <Tooltip placement="topLeft" title={text}>
                        {text}
                    </Tooltip>
                )
            },
            // {
            //     title: t('FormHeader.MiddleCategory'),
            //     dataIndex: 'ma_MiddleCategory',
            //     ellipsis:{
            //         showTitle:false
            //     },
            //     render:(text:string)=>(
            //         <Tooltip placement="topLeft" title={text}>
            //             {text}
            //         </Tooltip>
            //     )
            // },
            {
                title: t('FormHeader.ProblemDescription'),
                dataIndex: 'ma_Description',
                ellipsis:{
                    showTitle:false
                },
                render:(text:string)=>(
                    <Tooltip placement="topLeft" title={text}>
                        {text}
                    </Tooltip>
                )
            }
        ];
        const data: [] = eStore.techDailyMpList[record.user.user_NameCN];
        return <Table columns={columns} rowKey={(record:any) => record.mp_Id} dataSource={data} pagination={false} rowClassName={() => { return "childbg"}}/>;
    }


    const onExpand = (expanded: boolean, record: any) => {
        if (expanded === false) {
            let tempLst = {...eStore.techDailyMpList,[record.user.user_NameCN]:[]};
            eStore.setTechDailyMpList(tempLst);
            // let keyArr = [...eStore.expandedRowKeys];
            // const index = keyArr.findIndex((item:any) => record.user.user_NameCN === item);
            // if(index > -1){
            //     keyArr.splice(index,1);
            //     eStore.setExpandedRowKeys(keyArr);
            // }
        } else {
            // expandedRowKeys.push(record.user.user_NameCN);
            // eStore.setExpandedRowKeys(expandedRowKeys);
            eStore.queryMpLstByTechNTimeFrame({technician:record.user.user_NameCN,
                date:eStore.queryDate.format('YYYY/MM/DD'),shift:record.dr_ShiftType});
        }
    }

    const { dataSource, loading} = props;
    // pagination.pageSizeOptions = webConfig.reportPageSizeOptions;
    // pagination.showSizeChanger = true;
    // pagination.onShowSizeChange = props.onShowSizeChange;
    //return (
    return <Observer>{() =>
        <Table components={components}
            columns={columns}
            rowKey="dr_Id"
            dataSource={dataSource}
            bordered
            rowClassName="largeFont"
            size="middle"
            pagination={false}
            // pagination={pagination}
            loading={loading}
            // onChange={props.handleTableChange}
            scroll={{ x: 'max-content' }}
            expandable={{
                expandedRowRender: expandedRowRender,
                onExpand: onExpand,
                // expandedRowKeys,
            }}
        />
    //);
    }</Observer>;
}

//export default DayWorkTimeSumTable;
export default observer(DayWorkTimeSumTable);