import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import store from '../store';
import AuthRoute from './AuthRoute';
import App from '../pages/App';
import Nomatch from '../pages/404';
import Login from "../pages/Login";
import UserList from "../pages/UserList";
import EditableTable from '../pages/EditableTable';
import i18n from '../language/i18n';


class MRoute extends Component {


    componentDidMount(){
        if(i18n.language === "en"){
            document.title= 'Engineering Maintenance System';
        }else{
            document.title= '工程部维修保养记录系统';
        }
    }

    render() {
        return (
            <Router>
                <Provider {...store}>
                    <Switch>
                        <Route exact path="/" component={Login}></Route>
                        <Route path="/login" component={Login}></Route>
                        {/* <AuthRoute path="/cDefPwd" component={ChangeDefaultPwd}></AuthRoute> */}
                        <AuthRoute path="/app" component={App}></AuthRoute>
                        {/* <AuthRoute path="/user" component={UserList}></AuthRoute> */}
                        <Route path="/test" component={EditableTable}></Route>
                        {/* <AuthRoute path="/proc" component={Proc}></AuthRoute> */}
                        {/* <AuthRoute path="/report" component={OrderReport}></AuthRoute>
                        <AuthRoute path="/user" component={UserTableForm}></AuthRoute>
                        <AuthRoute path="/machine" component={MachineTableForm}></AuthRoute> */}
                        {/* <AuthRoute path="/processReport/:type" component={ProcessReport}></AuthRoute> */}
                        <Route component={Nomatch}></Route>
                    </Switch>
                </Provider>
            </Router>
        );
    }
}

export default MRoute;