import "../../style/maintenanceReport.less";
import React, { useRef,useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Button, Layout, Form, message, Modal } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import errorUtil from "../../utils/errorUtil";
import Loading from "../../components/Loading";
import store from '../../store';
import ApplyManageTable from './components/ApplyManageTable';
import AddMaintenanceDrawer from './components/AddMaintenanceDrawer';
import QRCode from 'qrcode.react';

interface Iprops {
    user: any
}

const { Content } = Layout;
let query: any = { currIndex: 0 }


const ApplyManageRecord: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    const eStore: any = useLocalObservable(() => store.manageRecordStore);
    const [form] = Form.useForm();
    let maintenanceRecordList = eStore.maintenanceRecordList.slice();
    let { page, loading, qrModalVisible, maInfo } = eStore;
    const [isEdit,setIsEdit] = useState(false);
    const [MaId,setMaId] = useState(0);
    const [MaNo,setMaNo] = useState('');
    let formRef:any = useRef();

    useEffect(() => {
        //query = { applicant: props.user.user_NameEN,currIndex: 0  }
        onSearch();
        return () => {
            eStore.setMaintenanceRecordList([]);
        }
    }, [])


    const handleSearch = () => {
        // e.preventDefault();//阻止页面提交刷新
        onSearch();
    }

    const onSearch = () => {
        getSearchCondition();
        onCallServer();
    }

    const onCallServer = () => {
        if (query.applicant) {
            eStore.queryMaintenanceRecordList(query).then((v: any) => {
                if (v.status === 0) {
                    errorUtil.showErrorMsg(v.err);
                }
            }).catch((ex: any) => {
                errorUtil.showErrorWithStore(ex, history, eStore);
            });
        } else {
            eStore.queryMALstByDepartment(query).then((v: any) => {
                if (v.status === 0) {
                    errorUtil.showErrorMsg(v.err);
                }
            }).catch((ex: any) => {
                errorUtil.showErrorWithStore(ex, history, eStore);
            });
        }
    }

    const getSearchCondition = () => {
        if (props.user.user_Role === "Supervisor" || props.user.user_Role === "Worker") {
            query.applicant = props.user.user_NameEN;
        } else {
            delete query.applicant;
        }
        if (props.user.user_Role === "Manager") {
            query.department = props.user.user_Department;
        } else {
            delete query.department;
        }
        let applicationNo = form.getFieldValue("applicationNo");
        if (!!applicationNo) {
            query.applyNo = applicationNo;
        } else {
            delete query.applyNo;
        }
        let machineNo = form.getFieldValue("machineNo");
        if (!!machineNo) {
            query.machineNo = machineNo;
        } else {
            delete query.machineNo;
        }
        query.pageSize = eStore.pageSize;
    }


    const handleReportTableChange = (pagination: any, fliters: any, sorter: any) => {
        getSearchCondition();
        const sortFiled = sorter.field;
        let sortOrder = sorter.order;
        if (sortOrder !== undefined && sortOrder !== null) {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc'
            } else if (sortOrder === 'descend') (
                sortOrder = 'desc'
            )
        }
        query.sortFiled = sortFiled;
        query.sortOrder = sortOrder;
        const statusArr = fliters.ma_Status;
        query.statusArr = statusArr;
        //query.pageSize = pagination.pageSize;
        const current = pagination.current;
        if (current !== undefined && current !== null) {
            if (current > 1) {
                query.currIndex = (current - 1) * pagination.pageSize;
            } else {
                query.currIndex = 0;
            }
        }
        onCallServer();
    }

    const onShowSizeChange = (current: number, pageSize: number) => {
        eStore.setPageSize(pageSize);
    }

    const onEdit = (record:any) =>{
        setIsEdit(true);
        setMaId(record.ma_Id);
        setMaNo(record.ma_Application_No > 0?record.ma_Application_No:record.ma_TempNo);
        formRef.current.initForm(record);
        eStore.setAddModalVisible(true);
    }

    const onOpenAddModal = () => {
        eStore.addExpire(query).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });
        setIsEdit(false);
        eStore.setAddModalVisible(true);
    }

    const onCloseAddModal = () => {
        eStore.setAddModalVisible(false);
    }

    const onAfterSubmit = (values: any,showQR:boolean) => {
        if(showQR){
            eStore.setQrModalVisible(true);
        }
        onSearch();
    }

    const onCancelQrModel = () => {
        eStore.setQrModalVisible(false);
    }

    let QRAttachInfo = "";
    if (maInfo.ma_Machine_No) {
        QRAttachInfo += maInfo.ma_Machine_No;
    }
    if (maInfo.ma_Machine_CNName) {
        QRAttachInfo += maInfo.ma_Machine_CNName;
    }
    if (maInfo.ma_Description) {
        QRAttachInfo += maInfo.ma_Description;
    }
    if (maInfo.MaNo) {
        QRAttachInfo += "(" + maInfo.MaNo + ")";
    }

    return <Observer>{() =>
        <>
            <Loading loading={loading} />
            <Form form={form} layout="inline" className="reportbar" onFinish={handleSearch}>
                {/* <Form.Item label="BatchNo"> */}
                <Form.Item name="machineNo">
                    <Input size="large" allowClear placeholder={t('FormHeader.EquipmentNo')} />
                </Form.Item>
                <Form.Item name="applicationNo">
                    <Input size="large" allowClear placeholder={t('FormHeader.ApplicationNo')} />
                </Form.Item>
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading}>{t('Search')}</Button>
                </Form.Item>
                <Form.Item>
                    <Button size="large" icon={<SearchOutlined />} onClick={onOpenAddModal} disabled={loading}>{t('Add')}</Button>
                </Form.Item>
                <Form.Item className="summary"    >
                    {t('Total', { pcs: page.total })}
                </Form.Item>
            </Form>
            <ApplyManageTable loading={loading} onEdit={onEdit} dataSource={maintenanceRecordList} pagination={page} handleTableChange={handleReportTableChange} onShowSizeChange={onShowSizeChange} />
            <AddMaintenanceDrawer isEdit={isEdit} MaId={MaId} MaNo={MaNo} formRef={formRef} visible={eStore.addModalVisible} user={props.user} onAfterSubmit={onAfterSubmit} onCancel={onCloseAddModal} />
            <Modal title="Maintenance Application No QrCode" visible={qrModalVisible} maskClosable={false}
                footer={null} onCancel={onCancelQrModel} className="alignCenter">
                <QRCode id='qrid' value={maInfo.MaNo} size={120} />
                <div className="maQR">{QRAttachInfo}</div>
            </Modal>
        </>
        //   )
    }</Observer>;
}

export default observer(ApplyManageRecord);

