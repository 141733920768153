import React from 'react';
import { Spin } from 'antd';

interface Iprops {
    loading: boolean,
}

const Loading: React.FC<Iprops> = (props: Iprops) => {
    return (
        <div className={props.loading ? "loading_mask" : "hide_mask"}>
           <div className="loading"> <Spin  size="large"  tip="loading..." /></div>
        </div>
    )
}

export default Loading;
