import { Modal } from 'antd';

export default class ModalUtil{
    static errorModal(content:string){
        Modal.error({
            title: 'Error 错误提示',
            content: content,
            okText: '确认',
            width: 600,
            className: 'bigModal',
        });
    }

    static warningModal(content:string){
        Modal.warning({
            title: 'Waring 警告',
            content: content,
            okText: '确认',
            width:600,
            className:'bigModal',
        });
    }

    static successModal(content:string){
        Modal.success({
            content: content,
            okText: '确认',
            width:600,
            className:'bigModal',
        });
    }
}