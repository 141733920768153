import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { webConfig } from '../../../config/webConfig';

interface Iprops {
    loading: boolean,
    pagination: any,
    dataSource: object[],
    handleTableChange: (pagination: any, fliters: any, sorter: any) => void,
    onShowSizeChange: (current: any, pageSize: any) => void
}

const MProcessingTable: React.FC<Iprops> = (props: Iprops) => {
    const { t, i18n } = useTranslation();

    const MProcessingTableColumns: any = [
        {
            title: t('FormHeader.ApplicationNo'),
            dataIndex: 'mp_Application_No',
            fixed: 'left',
            sorter: true,
        }, {
            title: t('FormHeader.EquipmentNo'),
            dataIndex: 'ma_Machine_No'
        }, {
            title: t('FormHeader.EquipmentName'),
            dataIndex: i18n.language === "en" ? 'ma_Machine_Name' : 'ma_Machine_CNName',
        }, {
            title: t('TimeTable.State'),
            dataIndex: 'mp_State',
            sorter: true,
            render: (val: string) => {
                if (i18n.language === "en") {
                    return val;
                } else {
                    switch (val) {
                        case "Wait":
                            return "等待开始"
                        case "Start":
                            return "开始";
                        case "Pause":
                            return "暂停";
                        case "End":
                            return "结束";
                        case "Finished":
                            return "完成";
                        default:
                            return "";
                    }
                }
            }
        }, {
            title: t('TimeTable.Name'),
            dataIndex: i18n.language === "en" ? 'user_NameEN' : 'mp_Technician',
        }, {
            title: t('TimeTable.From_Time'),
            sorter: true,
            render: (text: string, record: any) => {
                if (record.mp_FromTime) {
                    let time = moment(record.mp_FromTime).format("MM/DD/YYYY HH:mm:ss")
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('TimeTable.To_Time'),
            sorter: true,
            render: (text: string, record: any) => {
                if (record.mp_ToTime) {
                    let time = moment(record.mp_ToTime).format("MM/DD/YYYY HH:mm:ss")
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('TimeTable.WorkingTime'),
            align: 'center',
            render: (text: any, record: any) => {
                if (record.mp_SpendTime) {
                    let hour = Math.floor(record.mp_SpendTime / 3600);
                    let min = Math.floor((record.mp_SpendTime % 3600) / 60);
                    let second = record.mp_SpendTime % 3600 - min * 60;
                    if (hour > 0) {
                        if (min > 0) {
                            if (second > 0) {
                                return hour + "h" + min + "m" + second + "s";
                            } else {
                                return hour + "h" + min + "m";
                            }
                        } else {
                            if (second > 0) {
                                return hour + "h" + second + "s";
                            } else {
                                return hour + "h";
                            }
                        }
                    }else{
                        if (min > 0) {
                            if (second > 0) {
                                return  min + "m" + second + "s";
                            } else {
                                return  min + "m";
                            }
                        } else {
                            if (second > 0) {
                                return second + "s";
                            } else {
                                return '0';
                            }
                        }
                    }
                } else {
                    return 0;
                }
            }
        }, {
            title: t('TimeTable.Remarks'),
            dataIndex: 'mp_Remarks',
        },{
            title: t('FormHeader.MainCategory'),
            dataIndex: 'ma_MainCategory',
        },
        // {
        //     title: t('FormHeader.MiddleCategory'),
        //     dataIndex: 'ma_MiddleCategory',
        // },
        {
            title: t('FormHeader.ThirdCategory'),
            dataIndex: 'ma_ThirdCategory',
        }, {
            title: t('FormHeader.ProblemDescription'),
            dataIndex: 'ma_Description',
        }
    ]

    const { dataSource, loading, pagination } = props;
    pagination.pageSizeOptions = webConfig.reportPageSizeOptions;
    pagination.showSizeChanger = true;
    pagination.onShowSizeChange = props.onShowSizeChange;
    return (
        <Table columns={MProcessingTableColumns}
            rowKey={(record: any) => record.mp_Id}
            dataSource={dataSource}
            bordered
            rowClassName="largeFont"
            size="middle"
            //pagination={false}
            pagination={pagination}
            loading={loading}
            onChange={props.handleTableChange}
            scroll={{ x: 'max-content' }}
        />
    );
}

export default MProcessingTable;