import axios from 'axios';
import qs from 'querystring';
import { webConfig } from '../../config/webConfig';

axios.defaults.baseURL = webConfig.baseURL;
axios.defaults.timeout = 240000;//ms

//axios 发送拦截器
axios.interceptors.request.use(config => {
    //let token = localStorage.getItem('token');
    //let token = authStore.token;
    let token = sessionStorage.getItem('token');
    config.headers['token'] = token;
    return config;
}, error => {
    return Promise.reject(error);
})

//axios 响应拦截器
axios.interceptors.response.use(response => {
    return response;
}, error => {
    //return Promise.reject(error);
    throw Promise.reject(error);
})

export default class http {
    // static async get(url:string,params:any){
    //     try{
    //         let query = qs.stringify(params);//默认使用UTF-8Encode
    //         let res = null;
    //         if(!params){
    //             res = await axios.get(url);
    //         }else{
    //             res = await axios.get(url+'?'+query);
    //         }
    //         return res;
    //     }catch(ex){
    //         return ex;
    //     }
    // }

    static async get(url: string, params: any, config?: object) {
        try {
            if (typeof config !== 'undefined') {
                let query = qs.stringify(params);//默认使用UTF-8Encode
                let res = null;
                if (!params) {
                    res = await axios.get(url, config);
                } else {
                    res = await axios.get(url + '?' + query, config);
                }
                return res;
            } else {
                let query = qs.stringify(params);//默认使用UTF-8Encode
                let res = null;
                if (!params) {
                    res = await axios.get(url);
                } else {
                    res = await axios.get(url + '?' + query);
                }
                return res;
            }
        } catch (ex) {
            return ex;
        }
    }

    // static async post(url, data) {
    //     try {
    //         let res = await axios.post(url, data);
    //         return res;
    //     } catch (ex) {
    //         return ex;
    //     }
    // }

    static async post(url: string, data: any, config?: object) {
        try {
            if (typeof config !== 'undefined') {
                let res = await axios.post(url, data, config);
                return res;
            } else {
                let res = await axios.post(url, data);
                return res;
            }
        } catch (ex) {
            return ex;
        }
    }

    //局部更新
    static async patch(url:string, data:any) {
        try {
            let res = null;
            if (!data) {
                res = await axios.patch(url);
            } else {
                res = await axios.patch(url, data);
            }
            return res;
        } catch (ex) {
            return ex;
        }
    }

    //创建或完全替换
    static async put(url:string, data:any) {
        try {
            let res = null;
            if (!data) {
                res = await axios.put(url);
            } else {
                res = await axios.put(url, data);
            }
            return res;
        } catch (ex) {
            return ex;
        }
    }

    static async delete(url:string, data:any) {
        try {
            let res = null;
            if (!data) {
                res = await axios.delete(url);
            } else {
                res = await axios.delete(url, data);
            }
            return res;
        } catch (ex) {
            return ex;
        }
    }


}

