import { observable, action, runInAction, makeObservable } from 'mobx';
import { webConfig } from "../config/webConfig";
import http from '../utils/http';
import root from './root';

class ManageRecordStore extends root {
    constructor() {
        super();
        makeObservable(this);
    }
    @observable maintenanceRecordList = [];
    @observable addModalVisible = false;
    @observable qrModalVisible = false;
    @observable maInfo = {};
    @observable equipmentInfo = {};
    @observable category = [];
    @observable secondCates = [];
    @observable thirdCates = [];
    @observable pageSize = webConfig.reportPageSize;
    @observable page = {
        total: 0,
        defaultPageSize: webConfig.reportPageSize
    };

    @action setMaintenanceRecordList(maintenanceRecordList) {
        this.maintenanceRecordList = maintenanceRecordList;
    }

    @action setAddModalVisible(addModalVisible) {
        this.addModalVisible = addModalVisible;
    }

    @action setQrModalVisible(qrModalVisible) {
        this.qrModalVisible = qrModalVisible;
    }

    @action setMaInfo(maInfo){
        this.maInfo = maInfo;
    }

    @action setPage(page) {
        this.page = page;
    }

    @action setCategory(category) {
        this.category = category;
    }

    @action setSecondCates(secondCates) {
        this.secondCates = secondCates;
    }

    @action setThirdCates(thirdCates) {
        this.thirdCates = thirdCates;
    }

    @action setEquipmentInfo(equipmentInfo) {
        this.equipmentInfo = equipmentInfo;
    }

    @action setPageSize(pageSize) {
        this.pageSize = pageSize;
    }

    @action async queryMaintenanceRecordList(queryObj) {
        this.loading = true;
        let res = await http.post('mac/getMALstByApplicant', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.maintenanceRecordList = data.obj.maintenanceRecordList;
                    this.page.total = data.obj.total
                }
            }

        })
        return data;
    }

    @action async queryMALstByDepartment(queryObj) {
        this.loading = true;
        let res = await http.post('mac/getApplyMALstByDepartment', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.maintenanceRecordList = data.obj.maintenanceRecordList;
                    this.page.total = data.obj.total
                }
            }

        })
        return data;
    }

    @action async queryEquipByNo(queryObj) {
        this.loading = true;
        let res = await http.get('equip/getEquipByNo', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                    this.equipmentInfo = data.obj;
            }

        })
        return data;
    }

    @action async addNewMa(queryObj) {
        this.loading = true;
        let res = await http.post('mac/addNewMA', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                this.maInfo = queryObj;
                this.maInfo.MaNo = data.obj;
            }
        })
        return data;
    }

    @action async addExpire() {
        this.loading = true;
        let res = await http.get('mac/addExpire');
        runInAction(() => {
            this.loading = false;
        })
    }

    @action async modifyMA(queryObj) {
        this.loading = true;
        let res = await http.post('mac/modifyMA', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                this.maInfo = queryObj;
            }
        })
        return data;
    }

    @action async queryCategory(queryObj) {
        this.loading = true;
        let res = await http.get('category/getCategoryByNo', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            this.category = data;
        })
        return data;
    }


}

const manageRecordStore = new ManageRecordStore();

export default manageRecordStore;
