import React from 'react';
import { Table,Button } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {webConfig} from '../../../config/webConfig';
import TimeUtil from '../../../utils/TimeUtil';

interface Iprops {
    loading: boolean,
    pagination: any,
    dataSource: object[],
    onEdit:(record:any)=>void,
    handleTableChange: (pagination: any, fliters: any, sorter: any) => void,
    onShowSizeChange: (current: any, pageSize: any) => void
}

const ApplyManageTable: React.FC<Iprops> = (props: Iprops) => {
    const { t,i18n } = useTranslation();


    const prodManageTableColumns:any = [
        {
            title: t('FormHeader.ApplicationNo'),
            fixed: 'left',
            render: (text:string, record:any) => {
                if(record.ma_Application_No >0){
                    return record.ma_Application_No;
                }else{
                    return record.ma_TempNo;
                }
            }
        },{
            title: t('FormHeader.Status'),
            dataIndex: 'ma_Status',
            filters: webConfig.filterFormStatusArr,
            align: 'center',
        }, {
            title: t('FormHeader.Process'),
            dataIndex: 'ma_EngStatus',
        },{
            title: t('FormHeader.ApplicationTime'),
            dataIndex: 'ma_Application_Time',
            sorter: true,
            render: (text:string, record:any) => {
                if (record.ma_Application_Time) {
                    let time  = moment(record.ma_Application_Time).format("MM/DD/YYYY HH:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('FormHeader.EngStartTime'),
            render: (text:string, record:any) => {
                if (record.ma_EngStartTime) {
                    let time  = moment(record.ma_EngStartTime).format("MM/DD/YYYY HH:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('FormHeader.EngEndTime'),
            render: (text:string, record:any) => {
                if (record.ma_EngEndTime) {
                    let time  = moment(record.ma_EngEndTime).format("MM/DD/YYYY HH:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('Production.Downtime'),
            align: 'center',
            render: (text:any, record:any) => {
                if (record.ma_Application_Time && record.ma_EngEndTime) {
                    let totalMinute = TimeUtil.getMinuteDiffForm2Times(record.ma_Application_Time, record.ma_EngEndTime);
                    let hour = Math.floor(totalMinute / 60);
                    let min = totalMinute % 60;
                    if (min > 0) {
                        return hour + "h" + min + "min";
                    } else {
                        return hour + "h";
                    }
                } else {
                    return "";
                }
            }
        },{
            title: t('FormHeader.ApplicationMan'),
            dataIndex: 'ma_Application_Man',
        }, {
            title: t('FormHeader.CostCenter'),
            dataIndex: 'ma_Cost_Center',
        },  {
            title: t('FormHeader.EquipmentNo'),
            dataIndex: 'ma_Machine_No'
        }, {
            title: t('FormHeader.EquipmentName'),
            dataIndex: 'ma_Machine_CNName'
        }, {
            title: t('FormHeader.ProblemDescription'),
            dataIndex: 'ma_Description',
            // ellipsis:{
            //     showTitle:false
            // },
            // render:(text:string)=>(
            //     <Tooltip placement="topLeft" title={text}>
            //         {text}
            //     </Tooltip>
            // )
        },{
            title: t('FormHeader.Operation'), dataIndex: 'operation', key: 'Action',
            fixed: 'right',
            render: (text: string, record: any) => (
                <div>
                    {record.ma_EngStartTime?<></>:<Button type='primary' onClick={()=>props.onEdit(record)}>{t('Edit')}</Button>}
                </div>
            )
        }
    ]

    const { dataSource,loading,pagination } = props;
    pagination.pageSizeOptions = webConfig.reportPageSizeOptions;
    pagination.showSizeChanger = true;
    pagination.onShowSizeChange = props.onShowSizeChange;
    return (
        <Table columns={prodManageTableColumns}
            rowKey="ma_Application_No"
            dataSource={dataSource}
            bordered
            rowClassName="largeFont"
            size="middle"
            //pagination={false}
            pagination={pagination}
            loading={loading}
            onChange={props.handleTableChange}
            scroll={{ x: 'max-content' }}
        />
    );
}

export default ApplyManageTable;