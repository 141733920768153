import React from 'react';
import { Table,Tooltip,Button,Space } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {webConfig} from '../../../config/webConfig';
import TimeUtil from '../../../utils/TimeUtil';

interface Iprops {
    loading: boolean,
    pagination: any,
    dataSource: object[],
    onOpenWorkTimeModal:(applyNo:string) => void,
    onOpenFittingLstModal:(applyNo:string) => void,
    handleTableChange: (pagination: any, fliters: any, sorter: any) => void,
    onShowSizeChange: (current: any, pageSize: any) => void
}

const MApplicationTable: React.FC<Iprops> = (props: Iprops) => {
    const { t,i18n } = useTranslation();

    const prodManageTableColumns:any = [
        {
            title: t('FormHeader.ApplicationNo'),
            fixed: 'left',
            render: (text:string, record:any) => {
                if(record.ma_Application_No >0){
                    return record.ma_Application_No;
                }else{
                    return record.ma_TempNo;
                }
            }
        },{
            title: t('Fitting.FittingtypesQty'),
            dataIndex: 'ftPCS',
            sorter: true,
        },{
            title: t('FormHeader.Status'),
            dataIndex: 'ma_Status',
            filters: webConfig.filterFormStatusArr,
            align: 'center',
        }, {
            title: t('FormHeader.Process'),
            dataIndex: 'ma_EngStatus',
        },{
            title: t('FormHeader.ApplicationTime'),
            dataIndex: 'ma_Application_Time',
            sorter: true,
            render: (text:string, record:any) => {
                if (record.ma_Application_Time) {
                    let time  = moment(record.ma_Application_Time).format("MM/DD/YYYY HH:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('FormHeader.EngStartTime'),
            render: (text:string, record:any) => {
                if (record.ma_EngStartTime) {
                    let time  = moment(record.ma_EngStartTime).format("MM/DD/YYYY HH:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('FormHeader.EngEndTime'),
            render: (text:string, record:any) => {
                if (record.ma_EngEndTime) {
                    let time  = moment(record.ma_EngEndTime).format("MM/DD/YYYY HH:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('FormHeader.ApplicationMan'),
            dataIndex: 'ma_Application_Man',
        }, {
            title: t('FormHeader.Responsible'),
            dataIndex: 'ma_Responsible',
        },{
            title: t('FormHeader.CostCenter'),
            dataIndex: 'ma_Cost_Center',
        },  {
            title: t('FormHeader.EquipmentNo'),
            dataIndex: 'ma_Machine_No'
        }, {
            title: t('FormHeader.EquipmentName'),
            dataIndex: 'ma_Machine_CNName'
        }, {
            title: t('FormHeader.ProblemDescription'),
            dataIndex: 'ma_Description',
            // ellipsis:{
            //     showTitle:false
            // },
            // render:(text:string)=>(
            //     <Tooltip placement="topLeft" title={text}>
            //         {text}
            //     </Tooltip>
            // )
        },{
            title: t('FormHeader.MainCategory'),
            dataIndex: 'ma_MainCategory'
        },
        // {
        //     title: t('FormHeader.MiddleCategory'),
        //     dataIndex: 'ma_MiddleCategory'
        // }
        ,{
            title: t('FormHeader.ThirdCategory'),
            dataIndex: 'ma_ThirdCategory'
        },{
            title: t('FormHeader.ReasonAnalysis'),
            dataIndex: 'ma_Reason_Analysis'
        },{
            title: t('FormHeader.Operation'),
            dataIndex: 'Operation',
            fixed: 'right',
            render: (text:string, record:any) => {
                let applyNo:string = record.ma_Application_No >0?record.ma_Application_No:record.ma_TempNo;
                return (
                <Space>
                    <Button type="primary" onClick={() => {props.onOpenWorkTimeModal(applyNo)}} >{t('Detail')}</Button>
                    {record.ftPCS?<Button onClick={() => {props.onOpenFittingLstModal(applyNo)}} >{t('Fittings')}</Button>:<></>}
                </Space>
                )
            }
        }
    ]

    const { dataSource,loading,pagination } = props;
    pagination.pageSizeOptions = webConfig.reportPageSizeOptions;
    pagination.showSizeChanger = true;
    pagination.onShowSizeChange = props.onShowSizeChange;
    return (
        <Table columns={prodManageTableColumns}
            rowKey="ma_Id"
            dataSource={dataSource}
            bordered
            rowClassName="largeFont"
            size="middle"
            //pagination={false}
            pagination={pagination}
            loading={loading}
            onChange={props.handleTableChange}
            scroll={{ x: 'max-content' }}
        />
    );
}

export default MApplicationTable;