import React from 'react';
import { Table,Button,Space } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {webConfig} from '../../../config/webConfig';
import TimeUtil from '../../../utils/TimeUtil';

interface Iprops {
    loading: boolean,
    pagination: any,
    dataSource: object[],
    onEdit:(record:any)=>void,
    onExport:(formNo:any)=>void,
    handleTableChange: (pagination: any, fliters: any, sorter: any) => void,
    onShowSizeChange: (current: any, pageSize: any) => void
}

const OutSourcingTable: React.FC<Iprops> = (props: Iprops) => {
    const { t,i18n } = useTranslation();


    const prodManageTableColumns:any = [
        {
            title: t('FormHeader.ApplicationNo'),
            fixed: 'left',
            dataIndex: 'os_FormNo',
        },{
            title: t('FormHeader.Status'),
            dataIndex: 'os_Status',
            filters: webConfig.filterFormStatusArr,
            align: 'center',
        },{
            title: t('FormHeader.CostCenter'),
            dataIndex: 'os_CostCenter',
        },{
            title: t('OutSourcing.QuotationDate'),
            dataIndex: 'os_QuotationDate',
            sorter: true,
            render: (text:string, record:any) => {
                if (record.os_QuotationDate) {
                    let time  = moment(record.os_QuotationDate).format("MM/DD/YYYY") 
                    return time;
                } else {
                    return "";
                }
            }
        },{
            title: t('OutSourcing.Quotation'),
            dataIndex: 'os_Quotation',
        },{
            title: t('OutSourcing.ActualCost'),
            dataIndex: 'os_ActualCost',
        },  {
            title: t('FormHeader.ApplicationTime'),
            dataIndex: 'os_ApplicationDate',
            sorter: true,
            render: (text:string, record:any) => {
                if (record.os_ApplicationDate) {
                    let time  = moment(record.os_ApplicationDate).format("MM/DD/YYYY") 
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('FormHeader.EngStartTime'),
            render: (text:string, record:any) => {
                if (record.os_EngStartTime) {
                    let time  = moment(record.os_EngStartTime).format("MM/DD/YYYY HH:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('FormHeader.EngEndTime'),
            render: (text:string, record:any) => {
                if (record.os_EngEndTime) {
                    let time  = moment(record.os_EngEndTime).format("MM/DD/YYYY HH:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('OutSourcing.Exectime'),
            align: 'center',
            render: (text:any, record:any) => {
                if (record.os_ExcuteTime) {
                    return record.os_ExcuteTime + "h";
                } else {
                    return "";
                }
            }
        },{
            title: t('FormHeader.ApplicationMan'),
            dataIndex: i18n.language === "en" ? 'user_NameEN' : 'user_NameCN'
        }, {
            title: t('OutSourcing.Account'),
            dataIndex: 'os_Account',
        },{
            title: t('OutSourcing.Supplier'),
            dataIndex: 'os_Supplier',
        },{
            title: t('OutSourcing.LeadTime'),
            dataIndex: 'os_LeadTime',
        },{
            title: t('Category'),
            dataIndex: 'category_Content',
        },{
            title: t('OutSourcing.Brief'),
            dataIndex: 'os_Brief',
        }
        // ,{
        //     title: t('OutSourcing.FollowUp'),
        //     dataIndex: 'os_FollowUp',
        // }
        ,{
            title: t('FormHeader.EquipmentNo'),
            dataIndex: 'os_AssetNo'
        }, {
            title: t('FormHeader.EquipmentName'),
            dataIndex: i18n.language === "en" ? 'equipment_NameEN' : 'equipment_NameCN'
        }, {
            title: t('FormHeader.ProblemDescription'),
            dataIndex: 'os_Content',
            // ellipsis:{
            //     showTitle:false
            // },
            // render:(text:string)=>(
            //     <Tooltip placement="topLeft" title={text}>
            //         {text}
            //     </Tooltip>
            // )
        },{
            title: t('FormHeader.Operation'), dataIndex: 'operation', key: 'Action',
            fixed: 'right',
            render: (text: string, record: any) => (
                <Space>
                    <Button type='primary' onClick={()=>props.onExport(record.os_FormNo)}>{t('Export')}</Button>
                    <Button onClick={()=>props.onEdit(record)}>{t('Edit')}</Button>
                </Space>
            )
        }
    ]

    const { dataSource,loading,pagination } = props;
    pagination.pageSizeOptions = webConfig.reportPageSizeOptions;
    pagination.showSizeChanger = true;
    pagination.onShowSizeChange = props.onShowSizeChange;
    return (
        <Table columns={prodManageTableColumns}
            rowKey="os_FormNo"
            dataSource={dataSource}
            bordered
            rowClassName="largeFont"
            size="middle"
            //pagination={false}
            pagination={pagination}
            loading={loading}
            onChange={props.handleTableChange}
            scroll={{ x: 'max-content' }}
        />
    );
}

export default OutSourcingTable;