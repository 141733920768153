import './maintenanceForm.less'
import React, { useEffect, useState } from 'react';
import { Input, Button, Modal, Form, message, Select } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import store from '../../store';
import Loading from "../../components/Loading";
import MaintenanceTableHead from "../../components/MaintenanceTableHead";
import EnginnerTimeRecordTable from './components/EnginnerTimeRecordTable';
import errorUtil from "../../utils/errorUtil";
import FittingsRecordDrawer from './components/FittingsRecordDrawer';

interface Iprops {
    user: any
}

const Search = Input.Search;
const confirm = Modal.confirm;
const { Option } = Select;
const MaintenanceForm: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t, i18n } = useTranslation();
    let location: any = useLocation();
    const [btnStartDis, setBtnStartDis] = useState(true);
    const [btnFinishDis, setBtnFinishDis] = useState(true);
    const [fittingDrawrVisible, setFittingDrawrVisible] = useState(false);
    let applicationNo = location.state.applyNo;
    const eStore: any = useLocalObservable(() => store.workTimeStore);
    const [form] = Form.useForm();
    let { loading } = eStore;
    let timeList = eStore.timeList.slice();
    let stdJobList = eStore.stdJobList.slice();
    let userName = props.user.user_NameCN;
    //let currentState = eStore.currentTimeRecord ? eStore.currentTimeRecord.state ? eStore.currentTimeRecord.state : 'I' : 'I';

    useEffect(() => {
        getFormData();
        return () => {
            eStore.setFormData({});
            eStore.setTimeList([]);
            eStore.setStdJobList([]);
        }
    }, [])

    const getFormData = () => {
        let query = { applyNo: applicationNo, technician: userName, isContainRemark: false }
        eStore.queryLocalMANTimeLstByNoTech(query).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            } else {
                switch (eStore.currentState) {
                    case 'Wait':
                        setBtnStartDis(false);
                        setBtnFinishDis(true);
                        break;
                    case 'Start':
                        setBtnStartDis(false);
                        setBtnFinishDis(false);
                        break;
                    case 'Pause':
                        setBtnStartDis(false);
                        setBtnFinishDis(true);
                        break;
                    case 'End':
                        setBtnStartDis(true);
                        setBtnFinishDis(true);
                        break;
                    default:
                        setBtnStartDis(false);
                        setBtnFinishDis(true);
                        break;
                }
                let costCenter = eStore.formData.ma_Cost_Center??null;
                if (costCenter && (costCenter === '110' || costCenter === '129')) {
                    eStore.queryJobLstByCostCenter({ costCenter: costCenter })
                        .catch((e: any) => {
                            errorUtil.showErrorWithStore(e, history, eStore);
                        });
                }
                else if (eStore.formData?.ma_Machine_No) {
                    eStore.queryJobLstByMachineNo({ machineNo: eStore.formData.ma_Machine_No })
                        .catch((e: any) => {
                            errorUtil.showErrorWithStore(e, history, eStore);
                        });
                }
                let stdId = eStore.formData?.ma_StdId??-1;
                if (stdId > -1) {
                    form.setFieldsValue({ 'selStdTime': eStore.formData?.ma_StdId });
                }
            }
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const checkStart = () => {
        let obj = { technician: userName, state: 'Start' };
        eStore.queryStartRecord(obj).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            } else if (v.status === 1) {
                if (v.obj && v.obj.length > 0) {
                    let obj = v.obj;
                    let appNoLst = '';
                    for (let i = 0; i < obj.length; i++) {
                        appNoLst += obj[i].mp_Application_No + ',';
                    }
                    appNoLst = appNoLst.substr(0, appNoLst.length - 1);
                    confirm({
                        title: t('MaintenForm.StillWorkTitle', { appNoLst: appNoLst }),
                        content: t('MaintenForm.StillWorkContent'),
                        okText: t('Yes'),
                        okType: 'danger',
                        cancelText: t('No'),
                        width: 600,
                        className: 'bigModal',
                        onOk() {
                            onPauseOtherNStartClick();
                        }
                    });
                } else {
                    onStartClick();
                }
            }
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const onStartClick = () => {
        eStore.onStartTime(applicationNo, userName, false).then((v: any) => {
            afterStart(v);
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const onPauseOtherNStartClick = () => {
        eStore.onPauseOtherNStartTime(applicationNo, userName, false).then((v: any) => {
            afterStart(v);
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const afterStart = (v: any) => {
        if (v.status === 0) {
            errorUtil.showErrorMsg(v.err);
        } else if (v.status === 1) {
            message.success(t('StartSuccess'));
            setBtnFinishDis(false);
        } else if (v.status === -1) {
            message.error(t('StartFail'));
        }
    }

    const onPauseOrEndClick = (state: string) => {
        eStore.onPauseOrFinishTime(applicationNo, userName, state).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            } else if (v.status === 1) {
                if (state === 'End') {
                    message.success(t('EndSuccess'));
                    setBtnStartDis(true);
                    setBtnFinishDis(true);
                } else {
                    setBtnStartDis(false);
                    setBtnFinishDis(true);
                    message.success(t('PauseSuccess'));
                }
            } else if (v.status === -1) {
                if (state === 'End') {
                    message.error(t('EndFail'));
                } else {
                    message.error(t('PauseFail'));
                }
            }
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const showEndConfirm = (state: string) => {
        let stdId = eStore.formData?.ma_StdId??-1;
        if (stdJobList.length > 0 && stdId === -1) {
            errorUtil.showErrorMsg(t('MaintenForm.SelectJobContentFirst'));
        } else {
            let title = t('MaintenForm.Sure2Pause');
            if (state === 'End') {
                title = t('MaintenForm.Sure2End');
            }
            confirm({
                title: title,
                content: '',
                okText: t('Yes'),
                okType: 'danger',
                cancelText: t('No'),
                width: 600,
                className: 'bigModal',
                onOk() {
                    onPauseOrEndClick(state);
                }
            });
        }
    }

    const onOpenFittingDrawer = () => {
        eStore.queryFtLstByApplyNo({ applyNo: applicationNo });
        setFittingDrawrVisible(true);
    }

    const onCloseFittingDrawer = () => {
        setFittingDrawrVisible(false);
    }

    const onSaveRemark = (record: any) => {
        if (record.mp_Remarks) {
            const newData = [...eStore.timeList];
            const index = newData.findIndex(item => record.mp_Id === item.mp_Id);
            if (index > -1) {
                const item = newData[index];
                if (item.mp_Remarks !== record.mp_Remarks) {
                    eStore.onUpdateRemark(record.mp_Id, record.mp_Remarks, userName).then((v: any) => {
                        if (v.status === 0) {
                            errorUtil.showErrorMsg(v.err);
                        } else if (v.status === 1) {
                            newData.splice(index, 1, { ...item, ...record });
                            eStore.setTimeList(newData);
                            message.success(t('UpdateSuccess'));
                        } else if (v.status === -1) {
                            message.error(t('UpdateFail'));
                        }
                    }).catch((e: any) => {
                        errorUtil.showErrorWithStore(e, history, eStore);
                    });
                }
            }
        }
    }
    const onSaveStdJob = () => {
        let stdId = form.getFieldValue('selStdTime');
        let applicationNo = eStore.formData.ma_Application_No;
        eStore.onUpdateStdId(stdId, applicationNo).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    let optionArr: any = [];
    if (stdJobList) {
        for (let i = 0; i < stdJobList.length; i++) {
            optionArr.push(<Option value={stdJobList[i].stdT_Id}>{i18n.language==="en"?stdJobList[i].stdT_RepaireJobEN:stdJobList[i].stdT_RepaireJobCN}</Option>);
        }
        optionArr.push(<Option value={0}>{i18n.language==="en"?"Others":"其它"}</Option>);
    }
    return <Observer>{() =>
        <div>
            <Loading loading={loading} />
            <h1 className="textCenter">Maintenance time form
            </h1>
            <div className="orderFrom">
                {/* <Search placeholder="OrderNo-BatchNo" enterButton allowClear size="large" onSearch={this.onSearch} ref={this.searchRef} value={this.store.searchValue} onChange={this.handleSearchValueChange} /> */}

                <MaintenanceTableHead Header={eStore.formData} />
                <Form layout="inline" form={form} className="orderBtn">
                    <Form.Item name="selStdTime">
                        {stdJobList.length > 0 ?
                            <Select size="large" style={{ width: 220 }} placeholder={t('MaintenForm.SelectJobContent')} onChange={onSaveStdJob} >
                                {optionArr}
                            </Select> : <></>}
                    </Form.Item>
                    <Form.Item>
                        <Button className="thirdBtn" onClick={onOpenFittingDrawer} size="large" >{t('Fitting.FittingManage')}</Button>
                    </Form.Item>
                    <Form.Item>
                        {(() => {
                            switch (eStore.currentState) {
                                case 'I':
                                    return <Button type="primary" size="large" disabled={btnStartDis} onClick={() => { checkStart() }}>Start 开始</Button>;
                                case 'Start':
                                    return <Button danger size="large" onClick={() => { showEndConfirm('Pause') }}>Pause 暂停</Button>;
                                case 'Pause':
                                    return <Button type="primary" size="large" onClick={() => { checkStart() }}>Start 开始</Button>;
                                case 'End':
                                    return <Button type="primary" size="large" disabled={btnStartDis} onClick={() => { checkStart() }}>Start 开始</Button>;
                                default:
                                    return <Button type="primary" size="large" disabled={btnStartDis} onClick={() => { checkStart() }}>Start 开始</Button>;
                            }
                        })()}
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={() => { showEndConfirm('End') }} size="large" disabled={btnFinishDis}>End 结束</Button>
                    </Form.Item>
                </Form>

                <EnginnerTimeRecordTable loading={loading} editable={true} user={props.user} handleSave={onSaveRemark} dataSource={timeList} />
                <FittingsRecordDrawer applicationNo={applicationNo} loading={loading} user={props.user} visible={fittingDrawrVisible} onCancel={onCloseFittingDrawer} />
                {/* <ReasonMethodModal visible={reasonModal} onCancel={onCloseReasonModal} /> */}
            </div>

        </div >
    }</Observer>;
}

export default observer(MaintenanceForm);


