import React from 'react';
import { Table, Button,Tooltip } from 'antd';
import moment from 'moment';
import {  useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import store from '../../../store';
import {webConfig} from '../../../config/webConfig';


interface Iprops {
    loading: boolean,
    pagination: any,
    dataSource: object[],
    openAssignModal: (application_No: any) => void
    handleTableChange: (pagination: any, fliters: any, sorter: any) => void,
    onShowSizeChange: (current: any, pageSize: any) => void
}

const AssignTable: React.FC<Iprops> = (props: Iprops) => {
    let history = useHistory();
    const { t, i18n } = useTranslation();
    const eStore: any = useLocalObservable(() => store.assignStore);

    // handleTableChange = (pagination, fliters, sorter) => {
    //     const handleReportTableChange = this.props.handleReportTableChange;
    //     handleReportTableChange(pagination, fliters, sorter);
    // }

    // onShowSizeChange = (current, pageSize) => {
    //     const onSizeChange = this.props.onShowSizeChange;
    //     onSizeChange(current, pageSize);
    // }

    const enginnerWorkRecordTableColumns: any = [
        {
            title: t('FormHeader.ApplicationNo'),
            dataIndex: 'ma_Application_No',
            fixed: 'left',
            sorter: true,
            render: (text:string, record:any) => {
                if(record.ma_Application_No >0){
                    return record.ma_Application_No;
                }else{
                    return record.ma_TempNo;
                }
            }
        }, {
            title: t('AssignForm.AssignedNumber'),
            dataIndex: 'TechCount',
            fixed: 'left',
            align: 'center',
        }, {
            title: t('FormHeader.Status'),
            dataIndex: 'ma_Status',
            align: 'center',
        }, {
            title: t('FormHeader.Process'),
            dataIndex: 'ma_EngStatus',
        }, {
            title: t('FormHeader.ApplicationTime'),
            sorter: true,
            width:100,
            render: (text: string, record: any) => {
                if (record.ma_Application_Time) {
                    let time  = moment(record.ma_Application_Time).format("MM/DD/YYYY HH:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        },{
            title: t('FormHeader.EngStartTime'),
            sorter: true,
            width:100,
            render: (text:string, record:any) => {
                if (record.ma_EngStartTime) {
                    let time  = moment(record.ma_EngStartTime).format("MM/DD/YYYY HH:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('FormHeader.EngEndTime'),
            sorter: true,
            width:100,
            render: (text:string, record:any) => {
                if (record.ma_EngEndTime) {
                    let time  = moment(record.ma_EngEndTime).format("MM/DD/YYYY HH:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        },{
            title: t('FormHeader.Entity'),
            dataIndex: 'ma_Entity_ShortName',
        },  {
            title: t('FormHeader.Department'),
            dataIndex: 'ma_Application_Department',
            align: 'center',
        }, {
            title: t('FormHeader.ApplicationMan'),
            dataIndex: 'ma_Application_Man',
        }, {
            title: t('FormHeader.CostCenter'),
            dataIndex: 'ma_Cost_Center',
        }, {
            title: t('FormHeader.EquipmentNo'),
            dataIndex: 'ma_Machine_No'
        }, {
            title: t('FormHeader.EquipmentName'),
            dataIndex: 'ma_Machine_CNName',
            ellipsis:{
                showTitle:false
            },
            render:(text:string)=>(
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        }, {
            title: t('FormHeader.ProblemDescription'),
            dataIndex: 'ma_Description',
            ellipsis:{
                showTitle:false
            },
            render:(text:string)=>(
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        }, {
            title: t('FormHeader.Operation'),
            dataIndex: 'Operation',
            fixed: 'right',
            render: (text: string, record: any) => (
                <div>
                    <Button type="primary" onClick={(e: React.MouseEvent<HTMLButtonElement>) => { props.openAssignModal(record.ma_Application_No) }} > {t('Assign')}</Button>
                </div>
            )
        }
    ]

    const expandedRowRender = (record: any) => {
        const columns = [
            { title: t('TimeTable.Name'), dataIndex: 'mp_Technician', key: 'mp_Technician' },
            {
                title: t('TimeTable.State'), dataIndex: 'mp_State',key:'mp_State', render: (val: string) => {
                    if (i18n.language === "en") {
                        return val;
                    } else {
                        switch (val) {
                            case "Wait":
                                return "等待开始"
                            case "Start":
                                return "开始";
                            case "Pause":
                                return "暂停";
                            case "End":
                                return "结束";
                            default:
                                return "";
                        }
                    }
                }
            }
            // {
            //     title: 'Action', dataIndex: 'operation', key: 'Action',
            //     render: (text: string, record: any) => (
            //         <div>
            //             <Popconfirm placement="topRight" title="Sure to remove this worker from this Captian team?"
            //                 onConfirm={() => onConfirmToRemove(record.id)}>
            //                 <Button danger>remove</Button>
            //             </Popconfirm>
            //         </div>
            //     )
            // }
        ];
        const data: [] = eStore.assignedLst[record.ma_Application_No];
        return <Table columns={columns} rowKey={(record:any) => record.mp_Id} dataSource={data} pagination={false} rowClassName={() => { return "childbg"}}/>;
    }

    const onConfirmToRemove = (id: any) => {

    }

    const onExpand = (expanded: boolean, record: any) => {
        if (expanded === false) {
            let tempLst = {...eStore.assignedLst,[record.ma_Application_No]:[]};
            eStore.setAssignedLst(tempLst);
        } else {
            eStore.queryAssignedList({applyNo:record.ma_Application_No});
        }

    }

    const { dataSource, loading,pagination} = props;
    pagination.pageSizeOptions = webConfig.reportPageSizeOptions;
    pagination.showSizeChanger = true;
    pagination.onShowSizeChange = props.onShowSizeChange;
    return (
        <Table columns={enginnerWorkRecordTableColumns}
            rowKey="ma_Application_No"
            dataSource={dataSource}
            bordered
            rowClassName="largeFont"
            size="middle"
            //pagination={false}
            pagination={pagination}
            loading={loading}
            onChange={props.handleTableChange}
            //scroll={{ x: 'fit-content' }}
            expandable={{
                expandedRowRender: expandedRowRender,
                //expandedRowRender: (record:any) => <p style={{ margin: 0 }}>{record.ma_Application_No}</p>,
                onExpand: onExpand,
            }}
        />
    );
}

export default AssignTable;