import React from 'react';
import { Table,Button } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {webConfig} from '../../../config/webConfig';

interface Iprops {
    loading: boolean,
    //pagination: any,
    dataSource: object[],
    jumpToWorkForm:(application_No:any) =>void
    handleTableChange: (pagination: any, fliters: any, sorter: any) => void,
    onShowSizeChange: (current: any, pageSize: any) => void
}

const OutSourcingWorkRecordTable: React.FC<Iprops> = (props: Iprops) => {
    const { t,i18n } = useTranslation();


    const prodManageTableColumns:any = [
        {
            title: t('FormHeader.ApplicationNo'),
            fixed: 'left',
            dataIndex: 'os_FormNo',
        },{
            title: t('FormHeader.Process'),
            dataIndex: 'osp_State',
            render: (val:any) => {
                if(i18n.language === "en"){
                    return val;
                }else{
                    switch(val){
                        case "Wait":
                            return "等待开始"
                        case "Start":
                            return "开始";
                        case "Pause":
                            return "暂停";
                        case "End":
                            return "结束";
                        case "Finished":
                            return "完成";
                        default:
                            return "";
                    }
                }
            }
        },{
            title: t('FormHeader.Status'),
            dataIndex: 'os_Status',
            align: 'center',
        },
        // {
        //     title: t('OutSourcing.QuotationDate'),
        //     dataIndex: 'os_QuotationDate',
        //     sorter: true,
        //     render: (text:string, record:any) => {
        //         if (record.os_QuotationDate) {
        //             let time  = moment(record.os_QuotationDate).format("MM/DD/YYYY") 
        //             return time;
        //         } else {
        //             return "";
        //         }
        //     }
        // },{
        //     title: t('OutSourcing.Quotation'),
        //     dataIndex: 'os_Quotation',
        // },{
        //     title: t('OutSourcing.ActualCost'),
        //     dataIndex: 'os_ActualCost',
        // }, 
        {
            title: t('Category'),
            dataIndex: 'category_Content',
        },{
            title: t('OutSourcing.Brief'),
            dataIndex: 'os_Brief',
        }, 
        {
            title: t('FormHeader.ApplicationTime'),
            dataIndex: 'os_ApplicationDate',
            sorter: true,
            render: (text:string, record:any) => {
                if (record.os_ApplicationDate) {
                    let time  = moment(record.os_ApplicationDate).format("MM/DD/YYYY") 
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('FormHeader.EngStartTime'),
            render: (text:string, record:any) => {
                if (record.os_EngStartTime) {
                    let time  = moment(record.os_EngStartTime).format("MM/DD/YYYY HH:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('FormHeader.EngEndTime'),
            render: (text:string, record:any) => {
                if (record.os_EngEndTime) {
                    let time  = moment(record.os_EngEndTime).format("MM/DD/YYYY HH:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('OutSourcing.Exectime'),
            align: 'center',
            render: (text:any, record:any) => {
                if (record.os_ExcuteTime) {
                    return record.os_ExcuteTime + "h";
                } else {
                    return "";
                }
            }
        },{
            title: t('FormHeader.ApplicationMan'),
            dataIndex: i18n.language === "en" ? 'user_NameEN' : 'user_NameCN'
        },{
            title: t('OutSourcing.Supplier'),
            dataIndex: 'os_Supplier',
        }
        // ,{
        //     title: t('OutSourcing.FollowUp'),
        //     dataIndex: 'os_FollowUp',
        // }
        ,{
            title: t('FormHeader.EquipmentNo'),
            dataIndex: 'os_AssetNo'
        }, {
            title: t('FormHeader.EquipmentName'),
            dataIndex: i18n.language === "en" ? 'equipment_NameEN' : 'equipment_NameCN'
        }, {
            title: t('FormHeader.ProblemDescription'),
            dataIndex: 'os_Content',
            // ellipsis:{
            //     showTitle:false
            // },
            // render:(text:string)=>(
            //     <Tooltip placement="topLeft" title={text}>
            //         {text}
            //     </Tooltip>
            // )
        },{
            title: t('FormHeader.Operation'), dataIndex: 'operation', key: 'Action',
            fixed: 'right',
            render: (text:string, record:any) => (
                <div>
                    <Button type="primary"  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {  props.jumpToWorkForm(record.os_FormNo)}} >{t('Work')}</Button>
                </div>
            )
        }
    ]

    const { dataSource,loading } = props;
    // pagination.pageSizeOptions = webConfig.reportPageSizeOptions;
    // pagination.showSizeChanger = true;
    // pagination.onShowSizeChange = props.onShowSizeChange;
    return (
        <Table columns={prodManageTableColumns}
            rowKey="os_FormNo"
            dataSource={dataSource}
            bordered
            rowClassName="largeFont"
            size="middle"
            pagination={false}
            //pagination={pagination}
            loading={loading}
            onChange={props.handleTableChange}
            scroll={{ x: 'max-content' }}
        />
    );
}

export default OutSourcingWorkRecordTable;