import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import zh from './locales/zh.json';

// load translation using http -> see /public/locales
//i18n.use(Backend)
// pass the i18n instance to react-i18next.
i18n.use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        react: {
            useSuspense: true //   
        },
        resources: {
            en,
            zh
        },
        lng: 'zh',
        fallbackLng: 'zh',
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    }) .then(t => {
        console.log('18n ready');
      });

export default i18n;