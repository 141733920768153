import "../../style/maintenanceReport.less";
import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Button, Layout, Form } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AssignTable from './components/AssignTable';
import AssignWorkerModal from './components/AssignWorkerModal';
import errorUtil from "../../utils/errorUtil";
import Loading from "../../components/Loading";
// import http from '../utils/http';
import store from '../../store';

interface Iprops {
    user: any
}

const { Content } = Layout;
let query: any = { currIndex: 0 }


const AssignTask: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    // const [loading, setLoading] = useState(false);
    // const [total, setTotal] = useState(0);
    // const [maintenanceRecordList, setMaintenanceRecordList] = useState([]);
    const eStore: any = useLocalObservable(() => store.assignStore);
    //query.pageSize = eStore.pageSize;
    const [form] = Form.useForm();
    let assignRecordList = eStore.assignRecordList.slice();
    let { page, loading, assignWorkerVisibe } = eStore;

    useEffect(() => {
        //query = { responsible: props.user.user_NameEN }
        onSearch();
        return () => {
            eStore.setAssignRecordList([]);
        }
    }, [])


    const handleSearch = () => {
        // e.preventDefault();//阻止页面提交刷新
        onSearch();
    }

    const onSearch = () => {
        getSearchCondition();
        eStore.queryAssignRecordList(query).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            }
        }).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });
    }

    const getSearchCondition = () => {
        if (props.user.user_Role === "Supervisor") {
            query.responsible = props.user.user_Group;
        } else {
            delete query.responsible;
        }
        if (props.user.user_Role === "Senior Supervisor") {
            query.respArr = props.user.user_Group.split("&");
        } else {
            delete query.respArr;
        }
        let applicationNo = form.getFieldValue("applicationNo");
        if (!!applicationNo) {
            query.applyNo = applicationNo;
        } else {
            delete query.applyNo;
        }
        let machineNo = form.getFieldValue("machineNo");
        if (!!machineNo) {
            query.machineNo = machineNo;
        } else {
            delete query.machineNo;
        }
        query.pageSize = eStore.pageSize;
    }

    const handleReportTableChange = (pagination: any, fliters: any, sorter: any) => {
        getSearchCondition();
        const sortFiled = sorter.field;
        let sortOrder = sorter.order;
        if (sortOrder !== undefined && sortOrder !== null) {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc'
            } else if (sortOrder === 'descend') (
                sortOrder = 'desc'
            )
        }
        query.sortFiled = sortFiled;
        query.sortOrder = sortOrder;
        // const statusArr = fliters.ma_Status;
        // query.statusArr =  statusArr;
        const current = pagination.current;
        if (current !== undefined && current !== null) {
            if (current > 1) {
                query.currIndex = (current - 1) * pagination.pageSize;
            } else {
                query.currIndex = 0;
            }
        }
        eStore.queryAssignRecordList(query).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            }
        }).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });
    }

    const onShowSizeChange = (current: number, pageSize: number) => {
        eStore.setPageSize(pageSize);
    }

    const openAssignModal = (applyNo: number) => {
        let obj:any = { department: props.user.user_Department, applyNo: applyNo }
        if (props.user.user_Role === "Supervisor") {
            obj.group = props.user.user_Group;
        } else {
            delete obj.group;
        }
        if (props.user.user_Role === "Senior Supervisor") {
            let groupArr = props.user.user_Group;
            if(groupArr.indexOf("&") > -1){
                groupArr = props.user.user_Group.split("&");
                groupArr.push(props.user.user_Group);
            }
            obj.groupArr = groupArr;
        } else {
            delete obj.groupArr;
        }
        eStore.queryGropuUserWorkCount(obj);
        eStore.setAssignWorkerVisibe(true);
        eStore.setApplicationNo(applyNo);
    }

    const onCloseAssignModal = () => {
        eStore.setAssignWorkerVisibe(false);
        if (eStore.isModifyWorker && eStore.assignedLst[eStore.applicationNo]
            && eStore.assignedLst[eStore.applicationNo].length > 0) {
            eStore.queryAssignedList({ applyNo: eStore.applicationNo });
        }
        if (eStore.isModifyWorker) {
            let assignRecordArr = [...eStore.assignRecordList];
            for (let i = 0; i < assignRecordArr.length; i++) {
                if (assignRecordArr[i].ma_Application_No === eStore.applicationNo) {
                    assignRecordArr[i].TechCount = eStore.selectedKeys.length;
                }
            }
            eStore.setIsModifyWorker(false);
        }
    }



    return <Observer>{() =>
        <>
            <Loading loading={loading} />
            <Form form={form} layout="inline" className="reportbar" onFinish={handleSearch}>
                {/* <Form.Item label="BatchNo"> */}
                <Form.Item name="machineNo">
                    <Input size="large" allowClear placeholder={t('FormHeader.EquipmentNo')} />
                </Form.Item>
                <Form.Item name="applicationNo">
                    <Input size="large" allowClear placeholder={t('FormHeader.ApplicationNo')} />
                </Form.Item>
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading}>{t('EwRecord.SearchTask')}</Button>
                </Form.Item>
                {/* <Form.Item>
                        <Button size="large"   icon={<SearchOutlined />} onClick={onSearchAll} disabled={loading}>{t('EwRecord.SearchAll')}</Button>
                    </Form.Item> */}
                <Form.Item className="summary"    >
                    {t('Total', { pcs: page.total })}
                </Form.Item>
            </Form>
            <AssignTable loading={loading} pagination={page} openAssignModal={openAssignModal} dataSource={assignRecordList} handleTableChange={handleReportTableChange} onShowSizeChange={onShowSizeChange} />
            <AssignWorkerModal title={t('AssignForm.AssginModalTitle')} loading={loading} visible={assignWorkerVisibe} user={props.user} onClose={onCloseAssignModal} />
        </>
        //   )
    }</Observer>;
}

export default observer(AssignTask);

// export default EnginnerWorkRecord;