import React, { PureComponent } from 'react';
import {Route,Redirect} from 'react-router-dom';

class AuthRoute extends PureComponent{
    // constructor(props){
    //     super(props);
    // }

    render(){
        const {component:Component,...rest} = this.props;
        const isAuth = sessionStorage.getItem("isAuth") === '1'?1:0;
        return(<Route {...rest} render={props=>{
            return isAuth?<Component {...props}/>
            :<Redirect to="/login"/>
            }}/>)
    }
}

export default AuthRoute; 