import React from 'react';
import { Button, Modal, Form, DatePicker, Switch, message, Space } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import store from '../../../store';
import errorUtil from "../../../utils/errorUtil";
import moment from 'moment';
import http from '../../../utils/http';
interface Iprops {
    visible: boolean,
    user: any,
    onCancel: () => void,
}

const { RangePicker } = DatePicker;
const confirm = Modal.confirm;

const TimeRangePickerModal: React.FC<Iprops> = (props) => {
    const { t } = useTranslation();
    let history = useHistory();
    const eStore: any = useLocalObservable(() => store.mReportStore);
    const [form] = Form.useForm();
    let { loading } = eStore;

    // useEffect(() => {
    //     if (eStore.formData && eStore.formData.reason_Analysis) {
    //         form.setFieldsValue({ 'analysis': eStore.formData.reason_Analysis })
    //     }
    // }, [])

    const rangeConfig = {
        rules: [{ type: 'array' as const, required: true, message: 'Please select time!' }],
    };

    const onFormSubmit = (values: any) => {
        const rangeValue = values['rangePicker'];
        let startDate = rangeValue[0].format('YYYY/MM/DD');
        let endDate = rangeValue[1].format('YYYY/MM/DD');
        let query: any = { startDate: startDate, endDate: endDate }
        if (props.user.user_Role === "Supervisor") {
            query.group = props.user.user_Group;
        } else {
            delete query.group;
        }
        if (props.user.user_Role === "Senior Supervisor") {
            let groupArr = props.user.user_Group;
            if(groupArr.indexOf("&") > -1){
                groupArr = props.user.user_Group.split("&");
                groupArr.push(props.user.user_Group);
            }
            query.groupArr = groupArr;
        } else {
            delete query.groupArr;
        }
        if (props.user.user_Role === "Manager") {
            query.department = props.user.user_Department;
        } else if (props.user.user_Role === "Admin") {
            query.department = "Enginner"
        } else {
            delete query.department;
        }
        let includeAll = values['includeAll'];
        if (includeAll) {
            query.includeAll = includeAll
        } else {
            delete query.includeAll;
        }
        eStore.setLoading(true);
        const FileDownload = require('js-file-download');
        http.post('mpc/getSumDailyExcel', query, { responseType: 'blob' }).then(
            (res: any) => {
                eStore.setLoading(false);
                if (!res) {
                    return
                }
                if (res.config.responseType === 'blob' && res.data.type === 'application/json') {
                    const reader = new FileReader();
                    reader.readAsText(res.data, 'utf-8');
                    reader.onload = () => {
                        const result: any = reader.result  // 得到字符串
                        const jData = JSON.parse(result) // 解析成json对象
                    }
                } else {
                    let filename = res.headers['content-disposition'];
                    if (filename) {
                        filename = filename.match(/filename="(.*)"/)[1];
                        FileDownload(res.data, filename);
                        eStore.setExportModalVisible(false);
                    }
                }

            }
        ).catch((err) => {
            errorUtil.showErrorWithStore(err, history, eStore);
        });
    }

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    const showSyncConfirm = () => {
        confirm({
            title: t('Report.SureToSync'),
            content: '',
            okText: t('Yes'),
            okType: 'danger',
            cancelText: t('No'),
            width: 600,
            className: 'bigModal',
            onOk() {
                eStore.syncFMMA2Local().then((v: any) => {
                    if (v.status === 0) {
                        errorUtil.showErrorMsg(v.err);
                    } else {
                        message.success('Sync successfully');
                    }
                }).catch((e: any) => {
                    errorUtil.showErrorWithStore(e, history, eStore);
                });
            }
        });
    }

    const initRangeValue: any = [moment(new Date()), moment(new Date())];
    return <Observer>{() =>
        <Modal visible={props.visible} title={t('Report.ExportWTReport')} width={800} maskClosable={false}
            closable={false} footer={null}>
            <Form form={form} {...layout} name="TimeRangePickerModal" size="large" onFinish={onFormSubmit} initialValues={{ rangePicker: initRangeValue }}>
                <Form.Item name="rangePicker" label={t('Report.SelectDateRange')} {...rangeConfig}>
                    <RangePicker format='YYYY/MM/DD' />
                </Form.Item>
                <Form.Item name="includeAll" label={t('Report.IncludeAllFilelds')} >
                    <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Space size='large'>
                        {props.user.user_Role ===  'Admin'?<Button size="large" className='thirdBtn' disabled={loading} onClick={showSyncConfirm}>{t('Report.SyncFMData')}</Button>:''}
                        <Button type="primary" size='large'  htmlType="submit" disabled={loading}>{t('Export')}</Button>
                        <Button size="large" disabled={loading} onClick={props.onCancel}>{t('Cancel')}</Button>
                    </Space>
                </Form.Item>
            </Form>

        </Modal>
    }</Observer>;
}

export default observer(TimeRangePickerModal);