
const webConfig = {
    version: '1.2.8',
    baseURL: "/api/",
    reportPageSize: 10,
    reportPageSizeOptions: ['6', '10', '15', '20'],
    filterFormStatusArr: [
        { text: 'Submit', value: 'Submit' },
        { text: 'Approve', value: 'Approve' },
        { text: 'Received', value: 'Received' },
        { text: 'Accept', value: 'Accept' },
        { text: 'Processing', value: 'Processing' },
        { text: 'Finished', value: 'Finished' },
    ],
}

const timeExplain =[
    '耗时说明：特种作业申请',
    '耗时说明：等待配件、工具',
    '耗时说明：研究、沟通维修方案',
    '耗时说明：等待使用部门进行作业前准备工作',
    '暂停说明：其他紧急工作原因需暂时中断',
    '暂停说明：下班时间暂停维修',
    '暂停说明：等待配件、工具',
    '其它',
    '无'
]

export {
    webConfig,timeExplain
};