import "../../style/maintenanceReport.less";
import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Button, Layout, Form } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import OutSourcingWorkRecordTable from './components/OutSourcingWorkRecordTable';
import errorUtil from "../../utils/errorUtil";
import Loading from "../../components/Loading";
// import http from '../utils/http';
import store from '../../store';

interface Iprops {
    user: any
}

const { Content } = Layout;
let query: any = {}


const OutSourcingWorkRecord: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    const eStore: any = useLocalObservable(() => store.osWorkRecordStore);
    //query.pageSize = eStore.pageSize;
    query.technician = props.user.user_No;
    const [form] = Form.useForm();
    let osWorkRecordList = eStore.osWorkRecordList.slice();
    let { page, loading } = eStore;

    useEffect(() => {
        query = { technician: props.user.user_No }
        onSearch();
        return () => {
            eStore.setOsWorkRecordList([]);
        }
    }, [])


    const handleSearch = () => {
        // e.preventDefault();//阻止页面提交刷新
        onSearch();
    }

    const oncheckApplicationNo = (applicationNo: string) => {
        let match2 = /^o\d+$/;
        return !match2.test(applicationNo);
    }

    const onSearch = () => {
        getSearchCondition();
        let isVaild = true;
        if (query.applyNo) {
            if (oncheckApplicationNo(query.applyNo.trim())) {
                isVaild = false;
                errorUtil.showErrorMsg(t('EwRecord.ApplyNoFormat'));
            }
        }
        if (isVaild) {
            eStore.queryOsWorkRecordList(query).then((v: any) => {
                if (v.status === 0) {
                    errorUtil.showErrorMsg(v.err);
                }
            }).catch((ex: any) => {
                errorUtil.showErrorWithStore(ex, history, eStore);
            });
        }
    }

    const getSearchCondition = () => {
        let applicationNo = form.getFieldValue("applicationNo");
        if (!!applicationNo) {
            query.applyNo = applicationNo;
        } else {
            delete query.applyNo;
        }
        let machineNo = form.getFieldValue("machineNo");
        if (!!machineNo) {
            query.machineNo = machineNo;
        } else {
            delete query.machineNo;
        }
        // query.pageSize = eStore.pageSize;
    }

    

    const handleReportTableChange = (pagination: any, fliters: any, sorter: any) => {
        getSearchCondition();
        const sortFiled = sorter.field;
        let sortOrder = sorter.order;
        if (sortOrder !== undefined && sortOrder !== null) {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc'
            } else if (sortOrder === 'descend') (
                sortOrder = 'desc'
            )
        }
        query.sortFiled = sortFiled;
        query.sortOrder = sortOrder;
        //query.pageSize = pagination.pageSize;
        const current = pagination.current;
        if (current !== undefined && current !== null) {
            if (current > 1) {
                query.currIndex = (current - 1) * pagination.pageSize;
            } else {
                query.currIndex = 0;
            }
        }
        eStore.queryOsWorkRecordList(query).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const onShowSizeChange = (current: number, pageSize: number) => {
        // eStore.setPageSize(pageSize);
    }

    const jumpToWorkForm = (formData: any) => {
        //state 对象不能过大，不然无法clone
        history.push({ pathname: '/app/osp', state: { formNo: formData } });
        //history.push('/app/workTime');
    }

    return <Observer>{() =>
        <>
            <Loading loading={loading} />
            <Form form={form} layout="inline" className="reportbar" onFinish={handleSearch}>
                {/* <Form.Item label="BatchNo"> */}
                <Form.Item name="machineNo">
                    <Input size="large" allowClear placeholder={t('FormHeader.EquipmentNo')} />
                </Form.Item>
                <Form.Item name="applicationNo">
                    <Input size="large" allowClear placeholder={t('FormHeader.ApplicationNo')} />
                </Form.Item>
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading}>{t('EwRecord.SearchTask')}</Button>
                </Form.Item>
                <Form.Item className="summary"    >
                    {t('Total', { pcs: page.total })}
                </Form.Item>
            </Form>
            <OutSourcingWorkRecordTable loading={loading}  jumpToWorkForm={jumpToWorkForm} dataSource={osWorkRecordList} handleTableChange={handleReportTableChange} onShowSizeChange={onShowSizeChange} />
        </>
        //   )
    }</Observer>;
}

export default observer(OutSourcingWorkRecord);
