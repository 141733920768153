import { observable, action, runInAction, makeObservable } from 'mobx';
import { webConfig } from "../config/webConfig";
import http from '../utils/http';
import root from './root';

class AssignStore extends root {
    constructor() {
        super();
        makeObservable(this);
    }
    @observable applicationNo = 0;
    @observable assignRecordList = [];
    @observable assignWorkerLst = [];
    @observable assignedLst = [];
    @observable selectedKeys = [];
    @observable pageSize = webConfig.reportPageSize;
    @observable assignWorkerVisibe = false;
    @observable isModifyWorker = false;
    @observable page = {
        total: 0,
        defaultPageSize: webConfig.reportPageSize
    };

    @action setApplicationNo(applicationNo) {
        this.applicationNo = applicationNo;
    }

    @action setAssignRecordList(assignRecordList) {
        this.assignRecordList = assignRecordList;
    }

    @action setAssignWorkerLst(assignWorkerLst) {
        this.assignWorkerLst = assignWorkerLst;
    }

    @action setAssignedLst(assignedLst) {
        this.assignedLst = assignedLst;
    }

    @action setSelectedKeys(selectedKeys) {
        this.selectedKeys = selectedKeys;
    }

    @action setAssignWorkerVisibe(assignWorkerVisibe) {
        this.assignWorkerVisibe = assignWorkerVisibe;
    }

    @action setIsModifyWorker(isModifyWorker) {
        this.isModifyWorker = isModifyWorker;
    }

    @action setPage(page) {
        this.page = page;
    }

    @action setPageSize(pageSize) {
        this.pageSize = pageSize;
    }

    @action async queryAssignRecordList(queryObj) {
        this.loading = true;
        let res = await http.post('mac/getMALstByResponse', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.assignRecordList = data.obj.maintenanceRecordList;
                    this.page.total = data.obj.total
                }
            }

        })
        return data;
    }

    @action async queryGropuUserWorkCount(queryObj) {
        this.loading = true;
        let res = await http.post('user/getGropuUserWorkCount', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            this.assignWorkerLst = data;
            let selectArr = new Array();
            if(this.assignWorkerLst.length > 0){
                for(let i=0;i<this.assignWorkerLst.length;i++){
                    if(this.assignWorkerLst[i].applicationNo){
                        selectArr.push(this.assignWorkerLst[i].user_Id);
                    }
                }
                this.selectedKeys = selectArr;
            }
        })
        return data;
    }

    @action async onAssignWorker(applyNo,technician,user) {
        this.loading = true;
        let res = await http.put('mpc/addNewMP/' + applyNo + '/' + technician + '/' + user);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
        })
        return data;
    }

    @action async onDeleteRecord(id) {
        this.loading = true;
        let res = await http.delete('mpc/deleteMP/' + id );
        const data = res.data;
        runInAction(() => {
            this.loading = false;
        })
        return data;
    }

    @action async queryAssignedList(queryObj) {
        this.loading = true;
        let res = await http.get('mpc/selTNStateByApplyNo', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.assignedLst = {...this.assignedLst,[queryObj.applyNo]:data.obj};
                }
            }

        })
        return data;
    }


}

const assignStore = new AssignStore();

export default assignStore;
