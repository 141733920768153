import React , { useRef, useState } from 'react';
import { Button,Drawer,Table,Popconfirm,Space } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import errorUtil from "../../../utils/errorUtil";
import store from '../../../store';
import AUFittingRecordDrawer from './AUFittingRecordDrawer';

interface Iprops {
    applicationNo: string,
    loading: boolean,
    visible: boolean,
    user:any,
    onCancel: () => void,
}

const FittingsRecordDrawer: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t,i18n } = useTranslation();
    const [fittingModalVisible, setFittingModalVisible] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [ftId,setFtId] = useState(0);
    const { applicationNo,loading, visible,user, onCancel } = props;
    const eStore: any = useLocalObservable(() => store.workTimeStore);
    let title = t('Fitting.MANoFitting', { MaNo: applicationNo });
    let formRef:any = useRef();

    const onDelete = (id:number) =>{
        eStore.onDeleteFitting(id).then((v:any)=>{
            let fitArr = [...eStore.fittingList];
            const index = fitArr.findIndex((item:any) => id === item.ft_Id);
            if(index > -1){
                fitArr.splice(index,1);
                eStore.setFittingList(fitArr);
            }
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const onEdit = (record:any) =>{
        setIsEdit(true);
        setFtId(record.ft_Id);
        formRef.current.initForm(record);
        setFittingModalVisible(true);
    }

    const onOpenAddFittingModal = () =>{
        setIsEdit(false);
        setFittingModalVisible(true);
    }

    const onCloseFittingModal = () =>{
        setFittingModalVisible(false);
    }

    const columns:Array<any> = [
        { title: t('Fitting.ProductID'), dataIndex: 'ft_ProductId', key: 'user_No' },
        { title: t('Fitting.FittingName'), 
          dataIndex: i18n.language === "en" ? 'ft_Product_NameEN' : 'ft_Product_NameCN'},
        { title: t('Fitting.Specification'), dataIndex: 'ft_Specification' },
        { title: t('Fitting.Quantity'), dataIndex: 'ft_Quantity' },
        { title: t('Fitting.Unit'), dataIndex: 'ft_Unit' },
        {
            title: t('FormHeader.Operation'), dataIndex: 'operation', key: 'Action',
            fixed: 'right',
            render: (text: string, record: any) => (
                <div>
                    {!record.applicationNo ?
                        <Space><Button onClick={()=>onEdit(record)}>{t('Edit')}</Button>
                        <Popconfirm placement="topRight" title={t('Fitting.Suretoremove')}
                            onConfirm={() => onDelete(record.ft_Id)}>
                            <Button danger>{t('Remove')}</Button>
                        </Popconfirm></Space>:'' }
                </div>
            )
        }
    ];

    return <Observer>{() =>
        <Drawer
          title={title}
          width={820}
          closable={true}
          visible={visible}
          maskClosable={false}
          onClose={onCancel}
        >
           <Space style={{marginBottom:'24px',float:'right'}}  size='large'>
              <Button size="large" onClick={onCancel}>{t('Cancel')}</Button>
              <Button size="large" onClick={onOpenAddFittingModal} type="primary"  disabled={loading}>{t('Add')}</Button>
            </Space>
            <Table columns={columns}
                rowKey={record => record.ft_Id}
                dataSource={eStore.fittingList.slice()}
                loading={loading}
                scroll={{ x: 'max-content' }}
                pagination={false}
                bordered
                />
            <AUFittingRecordDrawer formRef={formRef} ftId={ftId} isEdit={isEdit} visible={fittingModalVisible} user={user} applicationNo={applicationNo}
            onCancel={onCloseFittingModal} />
        </Drawer>
    }</Observer>;
}

export default observer(FittingsRecordDrawer);