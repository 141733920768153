import { observable, action } from 'mobx';

export default class root{
    @observable isAuth = true;
    @observable loading = false;

    @action setLoading(isLoad) {
        this.loading = isLoad;
    }

    @action setIsAuth(isAuth){
        this.isAuth = isAuth;
    }
}

//export default root;