import React, { useEffect } from 'react';
import { Button, Modal, Form, Input, message, } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import store from '../../../store';
import errorUtil from "../../../utils/errorUtil";

interface Iprops {
    visible: boolean,
    onCancel: () => void,
}

const ReasonMethodModal: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    const eStore: any = useLocalObservable(() => store.workTimeStore);
    const [form] = Form.useForm();
    let { loading } = eStore;
    let reasonVal = eStore.formData && eStore.formData.ma_Reason_Analysis?eStore.formData.ma_Reason_Analysis:'';

    // useEffect(() => {
    //     if (eStore.formData && eStore.formData.reason_Analysis) {
    //         form.setFieldsValue({ 'analysis': eStore.formData.reason_Analysis })
    //     }
    // }, [])

    const onFormSubmit = (values: any) => {
        let obj = {Id:eStore.formData.ma_Id,
            Reason: values.analysis        
        }
        eStore.onUpdateReason(obj).then((v:any)=>{
            if (v.status === -1) {
                errorUtil.showErrorMsg(v.err);
            } else if(v.status === 1) {
                form.setFieldsValue({ 'analysis': eStore.formData.ma_Reason_Analysis });
                message.success(t('UpdateSuccess'))
                props.onCancel();
            }else if(v.status === 0){
                message.error(t('UpdateFail'));
            }
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    return <Observer>{() =>
        <Modal visible={props.visible} title={t('MaintenForm.ReasonNMethod')} width={800} maskClosable={false}
            closable={false} footer={null}>
            <Form form={form} {...layout} name="ReasonMethodModal" onFinish={onFormSubmit} initialValues={{analysis:reasonVal}}>
                <Form.Item name="analysis" label={t('MaintenForm.ReasonNMethod')} rules={[{ required: true }]}>
                    <Input.TextArea disabled={loading} autoSize={{ minRows: 3, maxRows: 6 }} />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" size='large' className="marginRight12px" htmlType="submit" disabled={loading}>{t('Save')}</Button>
                    <Button size="large" disabled={loading} onClick={props.onCancel}>{t('Cancel')}</Button>
                </Form.Item>
            </Form>

        </Modal>
    }</Observer>;
}

export default  observer(ReasonMethodModal);