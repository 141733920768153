import workRecordStore from './workRecordStore'
import workTimeStore from './workTimeStore'
import userStore from './userStore'
import assignStore from './assignStore'
import manageRecordStore from './manageRecordStore'
import reasonAnalyStore from './reasonAnalyStore'
import mReportStore from './mReportStore'
import scheduleStore from './scheduleStore'
import outSourcingStore from './outSourcingStore'
import osWorkRecordStore from './osWorkRecordStore'
import osWorkTimeStore from './osWorkTimeStore'

export default{
    workRecordStore,
    workTimeStore,
    userStore,
    assignStore,
    manageRecordStore,
    reasonAnalyStore,
    mReportStore,
    scheduleStore,
    outSourcingStore,
    osWorkRecordStore,
    osWorkTimeStore
}