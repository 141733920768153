import {Modal} from 'antd';
import i18n from '../language/i18n';

export default class errorUtil{
    static title:string = i18n.language === "en"?"Error":"错误";
    static okText:string = i18n.language === "en"?"Comfirm":"确认";

    static dealWithHttpErr(err:any){
        let errMsg = err.message;
        let status = 0;
        if(err.response && err.response.status === 405){
            if(i18n.language === "en"){
                errMsg = 'The authorization has expired,please login again';
            }else{
                errMsg = '登录授权已过期，烦请再次登录授权';
            }
            sessionStorage.setItem("isAuth",'0');
            status = -1;
        } 
        return {status:status,msg:errMsg}   
    }

    static showError(e:any,history:any){
        let errObj = errorUtil.dealWithHttpErr(e);
        Modal.error({
            title: errorUtil.title,
            content: errObj.msg,
            okText: errorUtil.okText,
            onOk: () => {
                if (errObj.status === -1) {
                    history.push('/');
                }
            }
        });

    }

    static showErrorWithStore(e:any,history:any,store:any){
        let errObj = errorUtil.dealWithHttpErr(e);
        Modal.error({
            title: errorUtil.title,
            content: errObj.msg,
            okText: errorUtil.okText,
            onOk: () => {
                if (errObj.status === -1) {
                    history.push('/');
                }
            }
        });
        store.setLoading(false);
    }

    static showErrorMsg(errMsg:string){
        Modal.error({
            title: errorUtil.title,
            content: errMsg,
            okText: errorUtil.okText,
            className:'bigModal'
        });
    }

    static showErrorWithCB(errMsg:string,callBack:any){
        Modal.error({
            title: errorUtil.title,
            content: errMsg,
            okText: errorUtil.okText,
            onOk:callBack
        });
    }
}