import { observable, action, runInAction, makeObservable } from 'mobx';
import { webConfig } from "../config/webConfig";
import http from '../utils/http';
import root from './root';
import moment from 'moment';

class MReprotStore extends root {
    constructor() {
        super();
        makeObservable(this);
    }
    @observable mpList = [];
    @observable drSummaryList = [];
    @observable summaryTotal = 0;
    @observable maList = [];
    @observable maMpList = [];
    @observable fittingList = [];
    @observable isDaily = true;
    @observable techDailyMpList = [];
    @observable expandedRowKeys = [];
    @observable queryDate = moment(new Date());
    @observable exportModalVisible = false;
    @observable pageSize = webConfig.reportPageSize;
    @observable page = {
        total: 0,
        defaultPageSize: webConfig.reportPageSize
    };

    @action setMpLst(mpList) {
        this.mpList = mpList;
    }

    @action setDrSummaryList(drSummaryList) {
        this.drSummaryList = drSummaryList;
    }

    @action setSummaryTotal(summaryTotal) {
        this.summaryTotal = summaryTotal;
    }

    @action setMaList(maList) {
        this.maList = maList;
    }

    @action setMaMpList(maMpList) {
        this.maMpList = maMpList;
    }

    @action setFittingList(fittingList) {
        this.fittingList = fittingList;
    }

    @action setIsDaily(isDaily) {
        this.isDaily = isDaily;
    }

    @action setTechDailyMpList(techDailyMpList) {
        this.techDailyMpList = techDailyMpList;
    }

    @action setExpandedRowKeys(expandedRowKeys) {
        this.expandedRowKeys = expandedRowKeys;
    }

    @action setQueryDate(queryDate) {
        this.queryDate = queryDate;
    }

    @action setExportModalVisible(exportModalVisible) {
        this.exportModalVisible = exportModalVisible;
    }

    @action setPage(page) {
        this.page = page;
    }

    @action setPageSize(pageSize) {
        this.pageSize = pageSize;
    }

    @action async queryMpList(queryObj) {
        this.loading = true;
        let res = await http.post('mpc/getMPLstByDepartOrGroup', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.mpList = data.obj.mpList;
                    this.page.total = data.obj.total
                }
            }
        })
        return data;
    }

    @action async querySummaryMpList(queryObj) {
        this.loading = true;
        let res = await http.post('mpc/getSummaryDailyWorkTime', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.drSummaryList = data.obj.mpList;
                    this.summaryTotal = data.obj.total;
                    this.expandedRowKeys = [];
                }
            }
        })
        return data;
    }

    @action async queryDailySummaryList(queryObj) {
        this.loading = true;
        let res = await http.post('dailyReport/getDailyWorkTimeByDate', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.drSummaryList = data.obj.drList;
                    this.summaryTotal = data.obj.total;
                    this.expandedRowKeys = [];
                }
            }
        })
        return data;
    }

    @action async queryDailyRangeSummaryList(queryObj) {
        this.loading = true;
        let res = await http.post('dailyReport/getDailyWorkTimeByDateRange', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.drSummaryList = data.obj.drList;
                    this.summaryTotal = data.obj.total;
                    this.expandedRowKeys = [];
                }
            }
        })
        return data;
    }

    @action async querySummaryTimeRangeMpList(queryObj) {
        this.loading = true;
        let res = await http.post('mpc/getSummaryTimeRangeWorkTime', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.drSummaryList = data.obj.mpList;
                    this.summaryTotal = data.obj.total;
                    this.techDailyMpList = [];
                }
            }
        })
        return data;
    }

    @action async queryMpLstByTechNTimeFrame(queryObj) {
        this.loading = true;
        let res = await http.post('mpc/getMpLstByTechNTimeFrame', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.techDailyMpList = {...this.techDailyMpList,[queryObj.technician]:data.obj};
                }
            }
        })
        return data;
    }

    @action async queryMALstByDepartOrGroup(queryObj) {
        this.loading = true;
        let res = await http.post('mac/queryMALstByDepartOrGroup', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.maList = data.obj.maintenanceRecordList;
                    this.page.total = data.obj.total
                }
            }
        })
        return data;
    }

    @action async queryMAMPLst(queryObj) {
        this.loading = true;
        let res = await http.get('mpc/getMaMpLst', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.maMpList = data.obj;
                }
            }
        })
        return data;
    }

    @action async queryFtLstByApplyNo(queryObj) {
        this.loading = true;
        let res = await http.get('fitting/getFtLstByApplyNo', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                this.fittingList = data.obj
            }
        })
        return data;
    }

    @action async syncFMMA2Local() {
        this.loading = true;
        let res = await http.get('mac/syncFMMA2Local');
        const data = res.data;
        runInAction(() => {
            this.loading = false;
        })
        return data;
    }

    @action async onUpdateDrReason(id,reason,userName) {
        this.loading = true;
        let res = await http.put('dailyReport/updateDrReason/' + id + '/' + reason + '/' + userName);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
        })
        return data;
    }


}

const mReportStore = new MReprotStore();

export default mReportStore;
