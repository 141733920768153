import "../../style/maintenanceReport.less";
import React, { useEffect, useState } from 'react';
import { SearchOutlined, LeftSquareOutlined, RightSquareOutlined, ExportOutlined } from '@ant-design/icons';
import { Input, Button, Form, Tabs, DatePicker, Radio,message } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import errorUtil from "../../utils/errorUtil";
import Loading from "../../components/Loading";
import store from '../../store';
import moment from 'moment';
import MProcessingTable from './components/MProcessingTable';
import DayWorkTimeSumTable from './components/DayWorkTimeSumTable';
import TimeRangePickerModal from './components/TimeRangePickerModal';
import MApplicationTable from './components/MApplicationTable';
import WorkTimeLstModal from './components/WorkTimeLstModal';
import FittingLstModal from './components/FittingLstModal';

interface Iprops {
    user: any
}

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
let query: any = { currIndex: 0 }
let querySum: any = { currIndex: 0 }
let queryMA: any = { currIndex: 0 }

const MProcessingReport: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    const eStore: any = useLocalObservable(() => store.mReportStore);
    const [form] = Form.useForm();
    const [summaryform] = Form.useForm();
    const [maform] = Form.useForm();
    const [applicationNo, setApplicationNo] = useState('');
    const [WorkTimeModalVisible, setWorkTimeModalVisible] = useState(false);
    const [FittingLstModalVisible, setFittingLstModalVisible] = useState(false);
    //const [timeSumform] = Form.useForm();
    let mpList = eStore.mpList.slice();
    let drSummaryList = eStore.drSummaryList.slice();
    let maList = eStore.maList.slice();
    let { page, loading, summaryTotal, queryDate, isDaily } = eStore;
    const initRangeValue: any = [moment(new Date()).subtract(6, 'days'), moment(new Date())];
    let userName = props.user.user_NameCN;

    useEffect(() => {
        //query = { responsible: props.user.user_NameEN }
        //onSearch();
        //eStore.setQueryDate(today);
        //summaryform.setFieldsValue({'datePicker':queryDate});
        handleSummarySearch();
        return () => {
            eStore.setMpLst([]);
            eStore.setDrSummaryList([]);
            eStore.setTechDailyMpList([]);
            eStore.setExpandedRowKeys([]);
            eStore.setIsDaily(true);
            eStore.setExportModalVisible(false);
        }
    }, [])

    //无奈，需要在渲染完成强制修改DatePicker的显示值
    useEffect(() => {
        let dp = document.getElementById('date1') as HTMLInputElement;
        dp.value = queryDate.format('YYYY/MM/DD');
        // summaryform.setFieldsValue({'datePicker':queryDate});
    })

    const onTabChange = (key: any) => {
        if (key === '1') {
            handleSummarySearch();
        } else if (key === '2') {
            form.setFieldsValue({ rangePicker: initRangeValue });
            onSearch();
        } else if (key === '3') {
            maform.setFieldsValue({ rangePicker: initRangeValue });
            onMASearch();
        }
    }

    const onDateChange = (date: any, dateString: string) => {
        eStore.setQueryDate(date);
    }

    const handleSummarySearch = () => {
        let summaryType = summaryform.getFieldValue("summaryType");
        if (!summaryType || summaryType === 'd') {
            let date = summaryform.getFieldValue("datePicker");
            // let date = queryDate;
            if (date) {
                querySum.date = date.format('YYYY/MM/DD');
                eStore.setQueryDate(date);
                SearchSummary();
            } else {
                errorUtil.showErrorMsg(t('InputSearch'));
            }
        } else {
            const rangeValue = summaryform.getFieldValue("rangePicker");
            if (rangeValue) {
                let startDate = rangeValue[0].format('YYYY/MM/DD');
                let endDate = rangeValue[1].format('YYYY/MM/DD');
                querySum.startDate = startDate;
                querySum.endDate = endDate;
                addSmmaryQueryParmer();
                // eStore.querySummaryTimeRangeMpList(querySum).then((v: any) => {
                eStore.queryDailyRangeSummaryList(querySum).then((v: any) => {
                    if (v.status === 0) {
                        errorUtil.showErrorMsg(v.err);
                    }
                }).catch((ex: any) => {
                    errorUtil.showErrorWithStore(ex, history, eStore);
                });
            }
        }

    }

    const handlePreDaySearch = () => {
        let date = summaryform.getFieldValue("datePicker");
        //  let date = queryDate;
        if (date) {
            let preDate = date.subtract(1, 'days');
            //summaryform.setFieldsValue({'datePicker':preDate});
            querySum.date = preDate.format('YYYY/MM/DD');
            eStore.setQueryDate(preDate);
            SearchSummary();
        } else {
            errorUtil.showErrorMsg(t('InputSearch'));
        }

    }

    const handleNextDaySearch = () => {
        let date = summaryform.getFieldValue("datePicker");
        if (date) {
            let nextDate = date.add(1, 'days');
            //summaryform.setFieldsValue({'datePicker':nextDate});
            querySum.date = nextDate.format('YYYY/MM/DD');
            eStore.setQueryDate(nextDate);
            SearchSummary();
        } else {
            errorUtil.showErrorMsg(t('InputSearch'));
        }

    }

    const addSmmaryQueryParmer = () => {
        if (props.user.user_Role === "Supervisor") {
            querySum.group = props.user.user_Group;
        } else {
            delete querySum.group;
        }
        if (props.user.user_Role === "Senior Supervisor") {
            let groupArr = props.user.user_Group;
            if(groupArr.indexOf("&") > -1){
                groupArr = props.user.user_Group.split("&");
                groupArr.push(props.user.user_Group);
            }
            querySum.groupArr = groupArr;
        } else {
            delete querySum.groupArr;
        }
        if (props.user.user_Role === "Manager") {
            querySum.department = props.user.user_Department;
        }else if(props.user.user_Role === "Admin"){
            querySum.department = "Enginner"
        }else {
            delete querySum.department;
        }
    }

    const SearchSummary = () => {
        addSmmaryQueryParmer();
        //eStore.querySummaryMpList(querySum).then((v: any) => {
        eStore.queryDailySummaryList(querySum).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            }
        }).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });

        
    }


    const handleSearch = () => {
        // e.preventDefault();//阻止页面提交刷新
        onSearch();
    }

    const onSearch = () => {
        getSearchCondition();
        eStore.queryMpList(query).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            }
        }).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });
    }

    const getSearchCondition = () => {
        const rangeValue = form.getFieldValue("rangePicker");
        if (rangeValue) {
            let startDate = rangeValue[0].format('YYYY/MM/DD');
            let endDate = rangeValue[1].format('YYYY/MM/DD');
            query.startDate = startDate;
            query.endDate = endDate;
        } else {
            delete query.endDate
            delete query.startDate
        }
        if (props.user.user_Role === "Supervisor") {
            query.group = props.user.user_Group;
        } else {
            delete query.group;
        }
        if (props.user.user_Role === "Senior Supervisor") {
            let groupArr = props.user.user_Group;
            if(groupArr.indexOf("&") > -1){
                groupArr = props.user.user_Group.split("&");
                groupArr.push(props.user.user_Group);
            }
            query.groupArr = groupArr;
        } else {
            delete query.groupArr;
        }
        if (props.user.user_Role === "Manager") {
            query.department = props.user.user_Department;
        }else if(props.user.user_Role === "Admin"){
            query.department = "Enginner"
        } else {
            delete query.department;
        }
        let applicationNo = form.getFieldValue("applicationNo");
        if (!!applicationNo) {
            query.applyNo = applicationNo;
        } else {
            delete query.applyNo;
        }
        let machineNo = form.getFieldValue("machineNo");
        if (!!machineNo) {
            query.machineNo = machineNo;
        } else {
            delete query.machineNo;
        }
        query.pageSize = eStore.pageSize;
    }


    const handleReportTableChange = (pagination: any, fliters: any, sorter: any) => {
        getSearchCondition();
        const sortFiled = sorter.field;
        let sortOrder = sorter.order;
        if (sortOrder !== undefined && sortOrder !== null) {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc'
            } else if (sortOrder === 'descend') (
                sortOrder = 'desc'
            )
        }
        query.sortFiled = sortFiled;
        query.sortOrder = sortOrder;
        // const statusArr = fliters.ma_Status;
        // query.statusArr =  statusArr;
        const current = pagination.current;
        if (current !== undefined && current !== null) {
            if (current > 1) {
                query.currIndex = (current - 1) * pagination.pageSize;
            } else {
                query.currIndex = 0;
            }
        }
        eStore.queryMpList(query).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            }
        }).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });
    }

    const onShowSizeChange = (current: number, pageSize: number) => {
        eStore.setPageSize(pageSize);
    }

    const handleMASearch = () => {
        // e.preventDefault();//阻止页面提交刷新
        onMASearch();
    }

    const onMASearch = () => {
        getMASearchCondition();
        eStore.queryMALstByDepartOrGroup(queryMA).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            }
        }).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });
    }

    const getMASearchCondition = () => {
        const rangeValue = maform.getFieldValue("rangePicker");
        if (rangeValue) {
            let startDate = rangeValue[0].format('YYYY/MM/DD');
            let endDate = rangeValue[1].format('YYYY/MM/DD');
            queryMA.startDate = startDate;
            queryMA.endDate = endDate;
        } 
        else {
            delete queryMA.startDate;
            delete queryMA.endDate;
        }
        if (props.user.user_Role === "Supervisor") {
            queryMA.group = props.user.user_Group;
        } else {
            delete queryMA.group;
        }
        if (props.user.user_Role === "Senior Supervisor") {
            let groupArr = props.user.user_Group;
            if(groupArr.indexOf("&") > -1){
                groupArr = props.user.user_Group.split("&");
                groupArr.push(props.user.user_Group);
            }
            queryMA.groupArr = groupArr;
        } else {
            delete queryMA.groupArr;
        }
        if (props.user.user_Role === "Manager") {
            queryMA.department = props.user.user_Department;
        }else if(props.user.user_Role === "Admin"){
            queryMA.department = "Enginner"
        } else {
            delete queryMA.department;
        }
        let applicationNo = maform.getFieldValue("applicationNo");
        if (!!applicationNo) {
            queryMA.applyNo = applicationNo;
        } else {
            delete queryMA.applyNo;
        }
        let machineNo = maform.getFieldValue("machineNo");
        if (!!machineNo) {
            queryMA.machineNo = machineNo;
        } else {
            delete queryMA.machineNo;
        }
        queryMA.pageSize = eStore.pageSize;
    }


    const handleMAReportTableChange = (pagination: any, fliters: any, sorter: any) => {
        getMASearchCondition();
        const sortFiled = sorter.field;
        let sortOrder = sorter.order;
        if (sortOrder !== undefined && sortOrder !== null) {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc'
            } else if (sortOrder === 'descend') (
                sortOrder = 'desc'
            )
        }
        queryMA.sortFiled = sortFiled;
        queryMA.sortOrder = sortOrder;
        const statusArr = fliters.ma_Status;
        queryMA.statusArr =  statusArr;
        const current = pagination.current;
        if (current !== undefined && current !== null) {
            if (current > 1) {
                queryMA.currIndex = (current - 1) * pagination.pageSize;
            } else {
                queryMA.currIndex = 0;
            }
        }
        eStore.queryMALstByDepartOrGroup(queryMA).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            }
        }).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });
    }

    const onShowMASizeChange = (current: number, pageSize: number) => {
        eStore.setPageSize(pageSize);
    }

    const showExportModal = () => {
        eStore.setExportModalVisible(true);
    }

    const closeExportModal = () => {
        eStore.setExportModalVisible(false);
    }

    const onSummaryTypeChange = (e: any) => {
        if (e.target.value === 'd') {
            eStore.setIsDaily(true);
        } else {
            eStore.setIsDaily(false);
        }
    }

    const onCloseWorkTimeModal = () =>{
        setWorkTimeModalVisible(false);
    }

    const onOpenWorkTimeModal = (applyNo:string) =>{
        eStore.queryMAMPLst({applyNo:applyNo}).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });
        setApplicationNo(applyNo);
        setWorkTimeModalVisible(true);
    }

    const onCloseFittingLstModal = () =>{
        setFittingLstModalVisible(false);
    }

    const onOpenFittingLstModal = (applyNo:string) =>{
        eStore.queryFtLstByApplyNo({applyNo:applyNo}).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });
        setApplicationNo(applyNo);
        setFittingLstModalVisible(true);
    }

    const rangeConfig = {
        rules: [{ type: 'array' as const, required: true, message: 'Please select time!' }],
    };

    const onSaveTimeReason = (record: any) => {
        if (!record.dr_Reason) {
            record.dr_Reason = 'null';
        }
            const newData = [...eStore.drSummaryList];
            const index = newData.findIndex(item => record.dr_Id === item.dr_Id);
            if (index > -1) {
                const item = newData[index];
                if (item.dr_Reason !== record.dr_Reason) {
                    eStore.onUpdateDrReason(record.dr_Id, record.dr_Reason, userName).then((v: any) => {
                        if (v.status === 0) {
                            errorUtil.showErrorMsg(v.err);
                        } else if (v.status === 1) {
                            if (record.dr_Reason === 'null'){
                                record.dr_Reason = '';
                            }
                            newData.splice(index, 1, { ...item, ...record });
                            eStore.setDrSummaryList(newData);
                            message.success(t('UpdateSuccess'));
                        } else if (v.status === -1) {
                            message.error(t('UpdateFail'));
                        }
                    }).catch((e: any) => {
                        errorUtil.showErrorWithStore(e, history, eStore);
                    });
                }
            }
        //}
    }


    return <Observer>{() =>
        <>
            <Loading loading={loading} />
                <Tabs defaultActiveKey="1" onChange={onTabChange} centered size="large">
                    <TabPane tab={t('Report.DailySummary')} key="1">
                        {/* <Row justify="end">
                        <Col span={4}><Button size="large" onClick={handlePreDaySearch} icon={<LeftSquareOutlined />} disabled={loading}>{t('Report.PreDay')}</Button></Col>
                        <Col span={4}><DatePicker id="dat1" defaultValue={queryDate} onChange={onDateChange} size='large' format='YYYY/MM/DD' /></Col>
                        <Col span={4}>col-4</Col>
                        <Col span={4}>col-4</Col>
                        </Row> */}
                        <Form form={summaryform} layout="inline" className="reportbar" initialValues={{ 'datePicker': queryDate, 'rangePicker': initRangeValue,'summaryType':"d" }} onFinish={handleSummarySearch}>
                            <Form.Item hidden={!isDaily}>
                                <Button size="large" onClick={handlePreDaySearch} icon={<LeftSquareOutlined />} disabled={loading}>{t('Report.PreDay')}</Button>
                            </Form.Item>
                            <Form.Item name="datePicker" hidden={!isDaily}>
                                <DatePicker id="date1" size='large' format='YYYY/MM/DD' />
                            </Form.Item>
                            <Form.Item hidden={!isDaily}>
                                <Button size="large" onClick={handleNextDaySearch} icon={<RightSquareOutlined />} disabled={loading}>{t('Report.NextDay')}</Button>
                            </Form.Item>
                            <Form.Item hidden={isDaily} name="rangePicker"  {...rangeConfig}>
                                <RangePicker size="large" format='YYYY/MM/DD' />
                            </Form.Item>
                            <Form.Item name="summaryType">
                                <Radio.Group size="large"  onChange={onSummaryTypeChange}>
                                    <Radio.Button value="d">Daily</Radio.Button>
                                    <Radio.Button value="t">Time Range</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item>
                                <Button size="large" type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading}>{t('Search')}</Button>
                            </Form.Item>
                            <Form.Item>
                                <Button size="large" onClick={showExportModal} icon={<ExportOutlined />} disabled={loading}>{t('Export')}</Button>
                            </Form.Item>
                            <Form.Item className="summary">
                                {t('Total', { pcs: summaryTotal })}
                            </Form.Item>
                        </Form>
                        <DayWorkTimeSumTable loading={loading} dataSource={drSummaryList} handleSave={onSaveTimeReason} />
                        <TimeRangePickerModal visible={eStore.exportModalVisible} user={props.user} onCancel={closeExportModal} />
                    </TabPane>
                    {/* <TabPane tab={'时间段汇总'} key="3">
                        <Form form={timeSumform} layout="inline" className="reportbar" initialValues={{'rangePicker':initRangeValue}}  onFinish={handleSummarySearch}>
                            
                            <Form.Item>
                                <Button size="large" type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading}>{t('Search')}</Button>
                            </Form.Item>
                            <Form.Item className="summary">
                                {t('Total', { pcs: summaryTotal })}
                            </Form.Item>
                        </Form>
                        <DayWorkTimeSumTable loading={loading} dataSource={drSummaryList}/>
                    </TabPane> */}
                    <TabPane tab={t('Report.Detail')} key="2">
                        <Form form={form} layout="inline" className="reportbar" onFinish={handleSearch} >
                            <Form.Item name="rangePicker" >
                                <RangePicker size="large" format='YYYY/MM/DD' />
                            </Form.Item>
                            <Form.Item name="machineNo">
                                <Input size="large" allowClear placeholder={t('FormHeader.EquipmentNo')} />
                            </Form.Item>
                            <Form.Item name="applicationNo">
                                <Input size="large" allowClear placeholder="ApplicationNo" />
                            </Form.Item>
                            <Form.Item>
                                <Button size="large" type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading}>{t('Search')}</Button>
                            </Form.Item>
                            <Form.Item className="summary"    >
                                {t('Total', { pcs: page.total })}
                            </Form.Item>
                        </Form>
                        <MProcessingTable loading={loading} pagination={page} dataSource={mpList} handleTableChange={handleReportTableChange} onShowSizeChange={onShowSizeChange} />
                    </TabPane>
                    <TabPane tab={t('Report.MARecords')} key="3">
                        <Form form={maform} layout="inline" className="reportbar" onFinish={handleMASearch} >
                            <Form.Item name="rangePicker">
                                <RangePicker size="large" format='YYYY/MM/DD' />
                            </Form.Item>
                            <Form.Item name="machineNo">
                                <Input size="large" allowClear placeholder={t('FormHeader.EquipmentNo')} />
                            </Form.Item>
                            <Form.Item name="applicationNo">
                                <Input size="large" allowClear placeholder="ApplicationNo" />
                            </Form.Item>
                            <Form.Item>
                                <Button size="large" type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading}>{t('Search')}</Button>
                            </Form.Item>
                            <Form.Item className="summary">
                                {t('Total', { pcs: page.total })}
                            </Form.Item>
                        </Form>
                        <MApplicationTable loading={loading} pagination={page} dataSource={maList} onOpenFittingLstModal={onOpenFittingLstModal} onOpenWorkTimeModal={onOpenWorkTimeModal} handleTableChange={handleMAReportTableChange} onShowSizeChange={onShowMASizeChange} />
                        <WorkTimeLstModal applicationNo={applicationNo} onCancel={onCloseWorkTimeModal} visible={WorkTimeModalVisible} loading={loading} />   
                        <FittingLstModal applicationNo={applicationNo} onCancel={onCloseFittingLstModal} visible={FittingLstModalVisible} loading={loading} />                   
                    </TabPane>
                </Tabs>
            </>
        //   )
    }</Observer>;
}

export default observer(MProcessingReport);


