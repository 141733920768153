import React, { ReactNode, useState } from 'react';
import { Button, Modal, Form, Input, InputNumber, message, Drawer, Space, Radio } from 'antd';
import { useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import errorUtil from "../../../utils/errorUtil";
import store from '../../../store';

interface Iprops {
    visible: boolean,
    isEdit: boolean,
    user: any,
    formRef: any,
    applicationNo: string,
    ftId: number,
    onCancel: () => void,
}
const { Search } = Input;
const confirm = Modal.confirm;

const AUFittingRecordDrawer: React.FC<Iprops> = (props) => {
    const { visible, applicationNo, user, onCancel, formRef, isEdit, ftId } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [readOnly, setReadOnly] = useState(true);
    let history = useHistory();
    const eStore: any = useLocalObservable(() => store.workTimeStore);
    let title = t('Fitting.addTitle');
    if (isEdit) {
        title = t('Fitting.modifyTitle');
    }

    formRef.current = {
        initForm: (record: any) => {
            form.setFieldsValue(record);
        }
    }

    const onClose = () => {
        form.resetFields();
        onCancel();
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const onModalChange = (e: any) => {
        if (e.target.value === 'id') {
            setReadOnly(true);
            //form.resetFields();
            //form.setFieldsValue({'modal':'id'});
        } else {
            setReadOnly(false);
            //form.resetFields();
            //form.setFieldsValue({'modal':'input'});
        }
    }


    const onSearch = () => {
        eStore.queryFittingById({ productId: form.getFieldValue('ft_ProductId') }).then((v: any) => {
            if (v.status === 1) {
                let ft = v.obj;
                if (ft.product_ENname) {
                    form.setFieldsValue({ 'ft_Product_NameEN': ft.product_ENname })
                }
                if (ft.product_CNname) {
                    form.setFieldsValue({ 'ft_Product_NameCN': ft.product_CNname })
                }
                if (ft.specification) {
                    form.setFieldsValue({ 'ft_Specification': ft.specification })
                }
                if (ft.unit) {
                    form.setFieldsValue({ 'ft_Unit': ft.unit })
                }
            } else {
                message.error(v.err);
            }
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const onFinish = (values: any) => {
        values.ft_ApplicationNo = applicationNo;
        confirm({
            title: t('Production.Sure2Submit'),
            content: '',
            okText: t('Yes'),
            okType: 'danger',
            cancelText: t('No'),
            width: 600,
            className: 'bigModal',
            onOk() {
                if (isEdit) {
                    values.ft_Id = ftId;
                    values.ft_ModifyBy = user.user_NameCN;
                    eStore.updateFitting(values).then((v: any) => {
                        if (v.status === 1) {
                            onClose();
                        }
                        else {
                            errorUtil.showErrorMsg(v.err);
                        }
                    }).catch((e: any) => {
                        errorUtil.showErrorWithStore(e, history, eStore);
                    });
                } else {
                    if (values?.ft_ProductId) {
                        let query = { applyNo: applicationNo, prodId: values.ft_ProductId };
                        eStore.queryTop1FitByProdIdNApplyNo(query).then((v: any) => {
                            if (v.status === 1) {
                                if (v.obj && v.obj?.ft && v.obj?.ft?.ft_ProductId?.toString() === values.ft_ProductId) {
                                    errorUtil.showErrorMsg('已添加过ProductId：' + values.ft_ProductId);
                                } else {
                                    add2Server();
                                }
                            }
                            else {
                                errorUtil.showErrorMsg(v.err);
                            }
                        }).catch((e: any) => {
                            errorUtil.showErrorWithStore(e, history, eStore);
                        });
                    } else {
                        add2Server();
                    }
                }
            }
        });


        function add2Server() {
            values.ft_CreateBy = user.user_NameCN;
            eStore.addFitting(values).then((v: any) => {
                if (v.status === 1) {
                    onClose();
                }
                else {
                    errorUtil.showErrorMsg(v.err);
                }
            }).catch((e: any) => {
                errorUtil.showErrorWithStore(e, history, eStore);
            });
        }
    }

    return (
        <Drawer
            title={title}
            width={600}
            closable={true}
            visible={visible}
            maskClosable={false}
            onClose={onClose}
        //   destroyOnClose={true}
        >
            <Form
                {...formItemLayout} form={form} name="fitting" onFinish={onFinish}
                scrollToFirstError
            >
                {/* <Form.Item name="modal" label={t('Fitting.inputmodal')}>
                    <Radio.Group buttonStyle="solid" defaultValue='id' onChange={onModalChange}>
                        <Radio.Button value="id">ID</Radio.Button>
                        <Radio.Button value="input">{t('Fitting.input')}</Radio.Button>
                    </Radio.Group>
                </Form.Item> */}
                <Form.Item name="ft_ProductId" label={t('Fitting.ProductID')}
                    rules={[{
                        pattern: /^\d+$/,
                        message: t('Fitting.SupportNum'),
                    }]}>
                    <Search allowClear enterButton="Search" onSearch={onSearch} />
                </Form.Item>
                <Form.Item name="ft_Product_NameEN" label={'Fitting Name EN'}>
                    <Input readOnly={readOnly} />
                </Form.Item>
                <Form.Item name="ft_Product_NameCN" label={'配件名'}>
                    <Input readOnly={readOnly} />
                </Form.Item>
                <Form.Item name="ft_Specification" label={t('Fitting.Specification')}>
                    <Input readOnly={readOnly} />
                </Form.Item>
                <Form.Item name="ft_Quantity" label={t('Fitting.Quantity')}
                    rules={[{
                        required: true,
                        type: 'number',
                        message: t('Fitting.InputQuantity'),
                    }]} >
                    <InputNumber />
                </Form.Item>
                <Form.Item name="ft_Unit" label={t('Fitting.Unit')}>
                    <Input readOnly={readOnly} />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Space size='large'>
                        <Button size='large' onClick={onClose}>
                            {t('Cancel')}
                        </Button>
                        <Button size='large' type="primary" htmlType="submit">
                            {t('Submit')}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Drawer>
    )

}

export default AUFittingRecordDrawer;