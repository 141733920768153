import React, { useState,useEffect } from 'react';
import { Button, Modal, Form, Input, InputNumber, Select, message, Drawer, Space, DatePicker } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import moment from 'moment';
import errorUtil from "../../../utils/errorUtil";
import store from '../../../store';

interface Iprops {
    visible: boolean,
    isEdit: boolean,
    user: any,
    formRef: any,
    OsId: number,
    OsNo: string,
    onAfterSubmit: (values: any, showQR: boolean) => void,
    onCancel: () => void,
}
const { Option } = Select;
const { Search } = Input;
const confirm = Modal.confirm;

const AddOutSourcingDrawer: React.FC<Iprops> = (props) => {
    const { visible, onAfterSubmit, user, onCancel, formRef, isEdit, OsId, OsNo } = props;
    const [form] = Form.useForm();
    let history = useHistory();
    const { t } = useTranslation();
    const eStore: any = useLocalObservable(() => store.outSourcingStore);
    const [isOtherSupplier,setIsOtherSupplier] = useState(true);
    let { engAdminStaffList,supplierAccountList,supplierList,categoryList } = eStore;
    let title = t('OutSourcing.addOs');
    if (isEdit) {
        title = t('OutSourcing.modifyOs') + ":" + OsNo;
    }

    useEffect(() => {
        eStore.queryEngAdminStaffList();
        eStore.querySupplierAccountList();
        eStore.querySupplierList();
        eStore.queryCategoryList();
    }, [])

    formRef.current = {
        initForm: (record: any) => {
            if (record.os_QuotationDate) {
                form.setFieldsValue({ 'QuotationDate': moment(record.os_QuotationDate) });
            } else {
                form.setFieldsValue({ 'QuotationDate': null });
            }
            if(record.os_Supplier){
                form.setFieldsValue({ 'Supplier': record.os_Supplier });
            }
            form.setFieldsValue(record);
        }
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };


    const onSearch = () => {
        eStore.queryEquipByNo({ equipNo: form.getFieldValue('os_AssetNo') }).then((v: any) => {
            if (v.status === 1) {
                let eq = eStore.equipmentInfo;
                if (eq) {
                    if (eq.equipment_NameEN) {
                        form.setFieldsValue({ 'equipment_NameEN': eq.equipment_NameEN })
                    }
                    if (eq.equipment_NameCN) {
                        form.setFieldsValue({ 'equipment_NameCN': eq.equipment_NameCN })
                    }
                    if (eq.equipment_EntityShort) {
                        form.setFieldsValue({ 'os_EntityShort': eq.equipment_EntityShort })
                    }
                    if (eq.equipment_CurrentLocation) {
                        form.setFieldsValue({ 'equipment_CurrentLocation': eq.equipment_CurrentLocation })
                    }
                }else{
                    form.setFieldsValue({ 'equipment_NameEN': '',
                    'equipment_NameCN': '', 
                    'os_EntityShort': '',
                    'equipment_CurrentLocation': ''
                 })
                }
            } else {
                message.error(v.err);
            }
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const onFinish = (values: any) => {
        values.user = user.user_No;
        if(values.Supplier && values.Supplier === 'Others'){
            values.Supplier = values.otherSupplier;
        }
        if(values.QuotationDate){
            values.QuotationDate = values.QuotationDate.format('YYYY/MM/DD');
        }
        confirm({
            title: t('Production.Sure2Submit'),
            content: '',
            okText: t('Yes'),
            okType: 'danger',
            cancelText: t('No'),
            width: 600,
            className: 'bigModal',
            onOk() {
                if (isEdit) {
                    values.OsId = OsId;
                    values.OsNo = OsNo;
                    eStore.modifyOSF(values).then((v: any) => {
                        if (v.status === 1) {
                            // form.resetFields();
                            onFormCancel();
                            //message.success(t('SubmitSuccess'));
                            onAfterSubmit(values, true);
                        } else if (v.status === -1) {
                            errorUtil.showErrorMsg(v.msg);
                            onFormCancel();
                            onAfterSubmit(values, false);
                        }
                        else {
                            errorUtil.showErrorMsg(v.err);
                        }
                    }).catch((e: any) => {
                        errorUtil.showErrorWithStore(e, history, eStore);
                    });
                } else {
                    eStore.addNewOSF(values).then((v: any) => {
                        if (v.status === 1) {
                            // form.resetFields();
                            onFormCancel();
                            //message.success(t('SubmitSuccess'));
                            onAfterSubmit(values, true);
                        }
                        else {
                            errorUtil.showErrorMsg(v.err);
                        }
                    }).catch((e: any) => {
                        errorUtil.showErrorWithStore(e, history, eStore);
                    });
                }
            }
        });

    }

    const onFormCancel = () => {
        form.resetFields();
        onCancel();
    }

    const handleSelSupplierChange = (values:any) =>{
        if(values === 'Others'){
            setIsOtherSupplier(false);
        }else{
            form.setFieldsValue({ 'otherSupplier': ''})
            setIsOtherSupplier(true);
        }
    }

    const handleOsAccountChange = (values:any) =>{
        if(values === '40001'){
            form.setFieldsValue({ 'Supplier': '鹤山市沙坪中邦五金电器商行'})
        }
    }

    let accountOptArr = [];
    if(supplierAccountList){
        for(let i = 0;i< supplierAccountList.length;i++){
            accountOptArr.push(<Option value={supplierAccountList[i].user_No}>{supplierAccountList[i].user_NameCN}</Option>);
        }
    }
    let applicantOptArr = [];
    if(engAdminStaffList){
        for(let i = 0;i< engAdminStaffList.length;i++){
            applicantOptArr.push(<Option value={engAdminStaffList[i].user_No}>{engAdminStaffList[i].user_NameCN}</Option>);
        }
    }
    let supplierOptArr = [];
    if(supplierList){
        for(let i = 0;i< supplierList.length;i++){
            supplierOptArr.push(<Option value={supplierList[i].su_NameCN}>{supplierList[i].su_NameCN}</Option>);
        }
    }
    supplierOptArr.push(<Option value="Others">其他</Option>);
    let categoryOptArr = [];
    if(categoryList){
        for(let i = 0;i< categoryList.length;i++){
            categoryOptArr.push(<Option value={categoryList[i].category_No}>{categoryList[i].category_Content}</Option>);
        }
    }
    return (
        <Drawer
            title={title}
            width={'50%'}
            closable={false}
            visible={visible}
            maskClosable={false}
        >
            {/*  <Modal title={title} closable={false} maskClosable={false}
            visible={visible} wrapClassName="orderModal" width='1000'
            footer={null}
         > */}
            <Form
                {...formItemLayout} form={form} name="register" onFinish={onFinish}
                initialValues={{}}
                scrollToFirstError
            >
                <Form.Item name="os_AssetNo" label={t('FormHeader.EquipmentNo')}>
                    <Search allowClear enterButton="Search" onSearch={onSearch} />
                </Form.Item>
                <Form.Item name="equipment_NameEN" label={t('FormHeader.EquipmentName')}>
                    <Input readOnly />
                </Form.Item>
                <Form.Item name="equipment_NameCN" label={t('FormHeader.EquipmentCNName')}>
                    <Input readOnly />
                </Form.Item>
                <Form.Item name="equipment_CurrentLocation" label={t('FormHeader.EquipmentLocation')}>
                    <Input readOnly />
                </Form.Item>
                <Form.Item
                    name="os_EntityShort"
                    label={t('FormHeader.Entity')}
                    rules={[{ required: true, message: 'Please select the entity!' }]}
                >
                    <Select placeholder="select the entity">
                        <Option value="HSBWL">Heshan Bestway Leather Product Ltd.</Option>
                        <Option value="HTL">Heshan Tantec Leather Co., Ltd.</Option>
                        <Option value="ISATR">ISA Heshan Trading Co., Ltd.</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="os_CostCenter" label={t('FormHeader.CostCenter')}
                rules={[{ required: true, message: 'Please select the CostCenter!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="QuotationDate" label={t('OutSourcing.QuotationDate')}>
                    <DatePicker id="date1" format='YYYY/MM/DD' />
                </Form.Item>
                <Form.Item name="os_Quotation" label={t('OutSourcing.Quotation')}>
                    <InputNumber />
                </Form.Item>
                <Form.Item name="os_ActualCost" label={t('OutSourcing.ActualCost')}>
                    <InputNumber />
                </Form.Item>
                <Form.Item name="os_LeadTime" label={t('OutSourcing.LeadTime')}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="os_Account"
                    label={t('OutSourcing.Account')}
                    rules={[{ required: true, message: 'Please select the account!' }]}
                >
                    <Select placeholder="select the account"
                     onChange={handleOsAccountChange}>
                        {accountOptArr}
                    </Select>
                </Form.Item>
                <Form.Item label={t('OutSourcing.Supplier')}>
                    <Input.Group compact>
                        <Form.Item
                            name="Supplier"
                            noStyle
                            label={t('OutSourcing.Supplier')}
                            //rules={[{ required: true, message: 'Please select the supplier!' }]}
                        >
                            <Select style={{ width: '50%' }} placeholder="select the supplier"
                            onChange={handleSelSupplierChange}>
                                {supplierOptArr}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="otherSupplier"
                            noStyle
                        >
                            <Input disabled={isOtherSupplier} style={{ width: '50%' }} placeholder="Input supplier" />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Form.Item
                    name="os_Applicant"
                    label={t('FormHeader.ApplicationMan')}
                    rules={[{ required: true, message: 'Please select the applicant!' }]}
                >
                    <Select placeholder="select the applicant">
                        {applicantOptArr}
                    </Select>
                </Form.Item>
                {/* <Form.Item
                    name="os_FollowUp"
                    label={t('OutSourcing.FollowUp')}
                    rules={[{ required: true, message: 'Please select the followUp!' }]}
                >
                    <Select placeholder="select the followUp">
                        <Option value="HSBWL">胡显春</Option>
                        <Option value="HTL">杨学文</Option>
                    </Select>
                </Form.Item> */}
                 <Form.Item
                    name="os_Category"
                    label={t('Category')}
                    rules={[{ required: true, message: 'Please select the Category!' }]}
                >
                    <Select placeholder="select the Category">
                        {categoryOptArr}
                    </Select>
                </Form.Item>
                 <Form.Item name="os_Brief" label={t('OutSourcing.Brief')} rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="os_Content" label={t('OutSourcing.externalContent')} rules={[{ required: true }]}>
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Space size='large'>
                        <Button onClick={onFormCancel}>
                            {t('Cancel')}
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {t('Submit')}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            {/* </Modal> */}
        </Drawer>
    )

}

export default AddOutSourcingDrawer;