import React, { useContext, useState, useEffect, useRef } from 'react';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

const EditableInputRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};


// noContent要传值,不然没有值的情况下，div里没内容不可点击
interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: any;
    record: any;
    handleSave: (record: any) => void;
    noContent: string,
}

const EditableInputCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    noContent,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<Input>(null);
    const form = useContext(EditableContext)!;
    const { t } = useTranslation();

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            let values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;
    if (record) {
        let date = moment(record.dr_Date);
        let workTime = record.dr_WorkTime;
        let leaveTime = record.dr_LeaveTime?record.dr_LeaveTime:0;
        let outWorkTime = record.dr_OutWorkTime?record.dr_OutWorkTime:0;
        let wt = workTime + leaveTime + outWorkTime;
        if ((record.user?.user_Type  > 7) 
        &&((wt === 0 && (date.day() !==0 && date.day() !==6)) ||(wt > 0 && wt < 7) || wt > 8)){
            editable = true;
        }else{
            editable = false;
        }
    }
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                initialValue={record[dataIndex]}
                rules={[
                    {
                        required: false,
                        message: `${title} ` + t('isRequired'),
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div style={{ paddingRight: 24 }} onClick={toggleEdit}>
                {record[dataIndex] && record[dataIndex].trim() ? children : noContent}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

export { EditableInputRow as EditableInputRow, EditableInputCell };