import React, { useEffect } from 'react';
import { Button, Modal, Form, Input, Select, message, Drawer, Space } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import errorUtil from "../../../utils/errorUtil";
import store from '../../../store';

interface Iprops {
    visible: boolean,
    isEdit: boolean,
    user: any,
    formRef: any,
    MaId: number,
    MaNo: string,
    onAfterSubmit: (values: any, showQR: boolean) => void,
    onCancel: () => void,
}
const { Option } = Select;
const { Search } = Input;
const confirm = Modal.confirm;

const AddMaintenanceDrawer: React.FC<Iprops> = (props) => {
    const { visible, onAfterSubmit, user, onCancel, formRef, isEdit, MaId, MaNo } = props;
    const [form] = Form.useForm();
    let history = useHistory();
    const { t } = useTranslation();
    const eStore: any = useLocalObservable(() => store.manageRecordStore);
    let { category, secondCates, thirdCates } = eStore;
    let title = t('Production.addMa');
    if (isEdit) {
        title = t('Production.modifyMa') + ":" + MaNo;
    }


    formRef.current = {
        initForm: (record: any) => {
            form.setFieldsValue(record);
        }
    }

    useEffect(() => {
        getCategory();
        return () => {

        }
    }, [])

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 20,
                offset: 4,
            },
        },
    };


    const onSearch = () => {
        eStore.queryEquipByNo({ equipNo: form.getFieldValue('ma_Machine_No') }).then((v: any) => {
            if (v.status === 1) {
                let eq = eStore.equipmentInfo;
                if (eq) {
                    if (eq.equipment_NameEN) {
                        form.setFieldsValue({ 'ma_Machine_Name': eq.equipment_NameEN })
                    }
                    if (eq.equipment_NameCN) {
                        form.setFieldsValue({ 'ma_Machine_CNName': eq.equipment_NameCN })
                    }
                    if (eq.equipment_EntityShort) {
                        form.setFieldsValue({ 'ma_Entity_ShortName': eq.equipment_EntityShort })
                    }
                    if (eq.equipment_CostCenter) {
                        form.setFieldsValue({ 'ma_Cost_Center': eq.equipment_CostCenter })
                    }
                    if (eq.equipment_CurrentLocation) {
                        form.setFieldsValue({ 'ma_Current_Location': eq.equipment_CurrentLocation })
                    }
                }
            } else {
                message.error(v.err);
            }
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const onFinish = (values: any) => {
        values.applicant = user.user_NameEN;
        values.department = user.user_Department;
        confirm({
            title: t('Production.Sure2Submit'),
            content: '',
            okText: t('Yes'),
            okType: 'danger',
            cancelText: t('No'),
            width: 600,
            className: 'bigModal',
            onOk() {
                if (isEdit) {
                    values.MaId = MaId;
                    values.MaNo = MaNo;
                    eStore.modifyMA(values).then((v: any) => {
                        if (v.status === 1) {
                            // form.resetFields();
                            onFormCancel();
                            //message.success(t('SubmitSuccess'));
                            onAfterSubmit(values, true);
                        } else if (v.status === -1) {
                            errorUtil.showErrorMsg(v.msg);
                            onFormCancel();
                            onAfterSubmit(values, false);
                        }
                        else {
                            errorUtil.showErrorMsg(v.err);
                        }
                    }).catch((e: any) => {
                        errorUtil.showErrorWithStore(e, history, eStore);
                    });
                } else {
                    eStore.addNewMa(values).then((v: any) => {
                        if (v.status === 1) {
                            // form.resetFields();
                            onFormCancel();
                            //message.success(t('SubmitSuccess'));
                            onAfterSubmit(values, true);
                        }
                        else {
                            errorUtil.showErrorMsg(v.err);
                        }
                    }).catch((e: any) => {
                        errorUtil.showErrorWithStore(e, history, eStore);
                    });
                }
            }
        });

    }

    const onFormCancel = () => {
        form.resetFields();
        onCancel();
    }


    const getCategory = () => {
        let categoryOptArr: any = [];
        if (user.user_Department === 'Environment') {
            eStore.queryCategory({ categoryNo: 'M0005' }).then((v: any) => {
                if (v && v.length > 0) {
                    let cateChilds = v[0].childrenCates;
                    if (cateChilds) {
                        for (let i = 0; i < cateChilds.length; i++) {
                            categoryOptArr.push(<Option value={cateChilds[i].category_Content}>{cateChilds[i].category_Content}</Option>);
                        }
                        eStore.setSecondCates(categoryOptArr);
                    }
                }
            }).catch((e: any) => {
                errorUtil.showErrorWithStore(e, history, eStore);
            });
        } else {
            eStore.queryCategory({ categoryNo: 'M0006' }).then((v: any) => {
                if (v && v.length > 0) {
                    let cateChilds = v[0].childrenCates;
                    if (cateChilds) {
                        for (let i = 0; i < cateChilds.length; i++) {
                            categoryOptArr.push(<Option value={cateChilds[i].category_Content}>{cateChilds[i].category_Content}</Option>);
                        }
                        eStore.setSecondCates(categoryOptArr);
                    }
                }
            }).catch((e: any) => {
                errorUtil.showErrorWithStore(e, history, eStore);
            });
        }
    }

    const onSecondCateChange = (secCate: any) => {
        let categoryOptArr: any = [];
        let secondCates = category[0].childrenCates;
        if (secondCates) {
            const index = secondCates.findIndex((item: any) => secCate === item.category_Content);
            let thirdCates = secondCates[index].childrenCates;
            if (thirdCates) {
                thirdCates.map((cate: any) => {
                    categoryOptArr.push(<Option value={cate.category_Content}>{cate.category_Content}</Option>);
                })
            }
            eStore.setThirdCates(categoryOptArr);
        }
    }

    return <Observer>{() =>
        <Drawer
            title={title}
            width={860}
            closable={false}
            visible={visible}
            maskClosable={false}
        >
            {/*  <Modal title={title} closable={false} maskClosable={false}
            visible={visible} wrapClassName="orderModal" width='1000'
            footer={null}
         > */}
            <Form
                {...formItemLayout} form={form} name="register" onFinish={onFinish}
                initialValues={{}}
                scrollToFirstError
            >
                <Form.Item name="ma_Machine_No" label={t('FormHeader.EquipmentNo')}>
                    <Search allowClear enterButton="Search" onSearch={onSearch} />
                </Form.Item>
                <Form.Item name="ma_Machine_Name" label={t('FormHeader.EquipmentName')}>
                    <Input />
                </Form.Item>
                <Form.Item name="ma_Machine_CNName" label={t('FormHeader.EquipmentCNName')}>
                    <Input />
                </Form.Item>
                <Form.Item name="ma_Current_Location" label={t('FormHeader.EquipmentLocation')}>
                    <Input />
                </Form.Item>
                <Form.Item name="ma_Cost_Center" label={t('FormHeader.CostCenter')}
                    rules={[{
                        required: true,
                        message: 'Please input the Cost Center!',
                    },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="ma_Entity_ShortName"
                    label={t('FormHeader.Entity')}
                    rules={[{ required: true, message: 'Please select the entity!' }]}
                >
                    <Select placeholder="select the entity">
                        <Option value="HSBWL">Heshan Bestway Leather Product Ltd.</Option>
                        <Option value="HTL">Heshan Tantec Leather Co., Ltd.</Option>
                        <Option value="ISATR">ISA Heshan Trading Co., Ltd.</Option>
                        {/* <Option value="Bader">Bader Automotive Leather (DaLian) Co.,Ltd.</Option>
                        <Option value="STL">Saigon TanTec Leather Ltd.</Option>
                        <Option value="TTL">TransAsia TanTec Limited</Option> */}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="ma_MiddleCategory"
                    label={t('SecondlyCategory')}
                    rules={[{ required: true, message: 'Please select the category!' }]}
                >
                    <Select placeholder="select the category" onChange={onSecondCateChange}>
                        {secondCates}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="ma_ThirdCategory"
                    label={t('ThirdlyCategory')}
                    rules={[{ required: true, message: 'Please select the category!' }]}
                >
                    <Select placeholder="select the category">
                        {thirdCates}
                    </Select>
                </Form.Item>
                <Form.Item name="ma_Description" label={t('FormHeader.ProblemDescription')} rules={[{ required: true }]}>
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Space size='large'>
                        <Button onClick={onFormCancel}>
                            {t('Cancel')}
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {t('Submit')}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            {/* </Modal> */}
        </Drawer>
    }</Observer>;

}

export default observer(AddMaintenanceDrawer);