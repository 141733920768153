import '../MaintenanceForm/maintenanceForm.less'
import React, { useEffect, useState } from 'react';
import { Input, Button, Modal, Form, message, Select } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import store from '../../store';
import Loading from "../../components/Loading";
import OsProcessHead from "./components/OsProcessHead"
import OsProcessTable from "./components/OsProcessTable"
import errorUtil from "../../utils/errorUtil";

interface Iprops {
    user: any
}

const Search = Input.Search;
const confirm = Modal.confirm;
const { Option } = Select;
const OutSourcingProcess: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t, i18n } = useTranslation();
    let location: any = useLocation();
    const [btnStartDis, setBtnStartDis] = useState(true);
    const [btnFinishDis, setBtnFinishDis] = useState(true);
    let applicationNo = location.state.formNo;
    const eStore: any = useLocalObservable(() => store.osWorkTimeStore);
    const [form] = Form.useForm();
    let { loading } = eStore;
    let timeList = eStore.timeList.slice();
    let userNo = props.user.user_No;
    //let currentState = eStore.currentTimeRecord ? eStore.currentTimeRecord.state ? eStore.currentTimeRecord.state : 'I' : 'I';

    useEffect(() => {
        getFormData();
        return () => {
            eStore.setFormData({});
            eStore.setTimeList([]);
        }
    }, [])

    const getFormData = () => {
        let query = { formNo: applicationNo, technician: userNo, isContainRemark: false }
        eStore.queryOsFormNTimeListByNo(query).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            } else {
                switch (eStore.currentState) {
                    case 'Wait':
                        setBtnStartDis(false);
                        setBtnFinishDis(true);
                        break;
                    case 'Start':
                        setBtnStartDis(false);
                        setBtnFinishDis(false);
                        break;
                    case 'Pause':
                        setBtnStartDis(false);
                        setBtnFinishDis(true);
                        break;
                    case 'End':
                        setBtnStartDis(true);
                        setBtnFinishDis(true);
                        break;
                    default:
                        setBtnStartDis(false);
                        setBtnFinishDis(true);
                        break;
                }
            }
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const checkStart = () => {
        let obj = { userNo: userNo, state: 'Start' };
        eStore.queryStartRecord(obj).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            } else if (v.status === 1) {
                if (v.obj && v.obj.length > 0) {
                    let obj = v.obj;
                    let appNoLst = '';
                    for (let i = 0; i < obj.length; i++) {
                        appNoLst += obj[i].osp_FormNo + ',';
                    }
                    appNoLst = appNoLst.substr(0, appNoLst.length - 1);
                    confirm({
                        title: t('MaintenForm.StillWorkTitle', { appNoLst: appNoLst }),
                        content: t('MaintenForm.StillWorkContent'),
                        okText: t('Yes'),
                        okType: 'danger',
                        cancelText: t('No'),
                        width: 600,
                        className: 'bigModal',
                        onOk() {
                            onPauseOtherNStartClick();
                        }
                    });
                } else {
                    onStartClick();
                }
            }
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const onStartClick = () => {
        eStore.onStartTime(applicationNo, userNo).then((v: any) => {
            afterStart(v);
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const onPauseOtherNStartClick = () => {
        eStore.onPauseOtherNStartTime(applicationNo, userNo).then((v: any) => {
            afterStart(v);
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const afterStart = (v: any) => {
        if (v.status === 0) {
            errorUtil.showErrorMsg(v.err);
        } else if (v.status === 1) {
            message.success(t('StartSuccess'));
            setBtnFinishDis(false);
        } else if (v.status === -1) {
            message.error(t('StartFail'));
        }
    }

    const onPauseOrEndClick = (state: string) => {
        eStore.onPauseOrFinishTime(applicationNo, userNo, state).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            } else if (v.status === 1) {
                if (state === 'End') {
                    message.success(t('EndSuccess'));
                    setBtnStartDis(true);
                    setBtnFinishDis(true);
                } else {
                    setBtnStartDis(false);
                    setBtnFinishDis(true);
                    message.success(t('PauseSuccess'));
                }
            } else if (v.status === -1) {
                if (state === 'End') {
                    message.error(t('EndFail'));
                } else {
                    message.error(t('PauseFail'));
                }
            }
        }).catch((e: any) => {
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const showEndConfirm = (state: string) => {
        let title = t('MaintenForm.Sure2Pause');
        if (state === 'End') {
            title = t('MaintenForm.Sure2End');
        }
        confirm({
            title: title,
            content: '',
            okText: t('Yes'),
            okType: 'danger',
            cancelText: t('No'),
            width: 600,
            className: 'bigModal',
            onOk() {
                onPauseOrEndClick(state);
            }
        });
    }


    return <Observer>{() =>
        <div>
            <Loading loading={loading} />
            <h1 className="textCenter">Maintenance time form
            </h1>
            <div className="orderFrom">
                {/* <Search placeholder="OrderNo-BatchNo" enterButton allowClear size="large" onSearch={this.onSearch} ref={this.searchRef} value={this.store.searchValue} onChange={this.handleSearchValueChange} /> */}

                <OsProcessHead Header={eStore.formData} />
                <Form layout="inline" form={form} className="orderBtn">
                    <Form.Item>
                        {(() => {
                            switch (eStore.currentState) {
                                case 'I':
                                    return <Button type="primary" size="large" disabled={btnStartDis} onClick={() => { checkStart() }}>Start 开始</Button>;
                                case 'Start':
                                    return <Button danger size="large" onClick={() => { showEndConfirm('Pause') }}>Pause 暂停</Button>;
                                case 'Pause':
                                    return <Button type="primary" size="large" onClick={() => { checkStart() }}>Start 开始</Button>;
                                case 'End':
                                    return <Button type="primary" size="large" disabled={btnStartDis} onClick={() => { checkStart() }}>Start 开始</Button>;
                                default:
                                    return <Button type="primary" size="large" disabled={btnStartDis} onClick={() => { checkStart() }}>Start 开始</Button>;
                            }
                        })()}
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={() => { showEndConfirm('End') }} size="large" disabled={btnFinishDis}>End 结束</Button>
                    </Form.Item>
                </Form>

                <OsProcessTable loading={loading} user={props.user}  dataSource={timeList} />
            </div>

        </div >
    }</Observer>;
}

export default observer(OutSourcingProcess);


