import { observable, action, runInAction, makeObservable } from 'mobx';
import { webConfig } from "../config/webConfig";
import http from '../utils/http';
import root from './root';

class OsWorkRecordStore extends root {
    constructor() {
        super();
        makeObservable(this);
    }
    @observable osWorkRecordList = [];
    @observable pageSize = webConfig.reportPageSize;
    @observable page = {
        total: 0,
        defaultPageSize: webConfig.reportPageSize
    };

    @action setOsWorkRecordList(osWorkRecordList) {
        this.osWorkRecordList = osWorkRecordList;
    }

    @action setPage(page) {
        this.page = page;
    }

    @action setPageSize(pageSize) {
        this.pageSize = pageSize;
    }

    @action async queryOsWorkRecordList(queryObj) {
        this.loading = true;
        let res = await http.get('osf/selectWorkRecord', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.osWorkRecordList = data.obj.osWorkRecordList;
                    this.page.total = data.obj.total
                }
            }

        })
        return data;
    }



}

const osWorkRecordStore = new OsWorkRecordStore();

export default osWorkRecordStore;
