import React from 'react';
import { Table,Button,Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface Iprops {
    loading: boolean,
    // pagination: any,
    dataSource: object[],
    jumpToWorkForm:(application_No:any) =>void
    handleTableChange: (pagination: any, fliters: any, sorter: any) => void,
    onShowSizeChange: (current: any, pageSize: any) => void,
    isRNA:boolean
}

const EnginnerWorkRecordTable: React.FC<Iprops> = (props: Iprops) => {
    const { t,i18n } = useTranslation();
    const { dataSource, loading,isRNA } = props;

    let enginnerWorkRecordTableColumns:any = [
        {
            title: t('FormHeader.ApplicationNo'),
            fixed: isRNA?false:'left',
            render: (text:string, record:any) => {
                if(record.ma_Application_No >0){
                    return record.ma_Application_No;
                }else{
                    return record.ma_TempNo;
                }
            }
        }, {
            title: t('FormHeader.Process'),
            dataIndex: ['mp','mp_State'],
            fixed: isRNA?false:'left',
            render: (val:any) => {
                if(i18n.language === "en"){
                    return val;
                }else{
                    switch(val){
                        case "Wait":
                            return "等待开始"
                        case "Start":
                            return "开始";
                        case "Pause":
                            return "暂停";
                        case "End":
                            return "结束";
                        case "Finished":
                            return "完成";
                        default:
                            return "";
                    }
                }
            }
        },
        {
            title: t('FormHeader.Status'),
            dataIndex: 'ma_Status',
            align: 'center',
        },  {
            title: t('FormHeader.Entity'),
            dataIndex: 'ma_Entity_ShortName',
        }, {
            title: t('FormHeader.ApplicationTime'),
            render: (text:string, record:any) => {
                if (record.ma_Application_Time) {
                    let time  = moment(record.ma_Application_Time).format("MM/DD/YYYY hh:mm:ss") 
                    return time;
                } else {
                    return "";
                }
            }
        },{
            title: t('FormHeader.Department'),
            dataIndex: 'ma_Application_Department',
            align: 'center',
        }, {
            title: t('FormHeader.ApplicationMan'),
            dataIndex: 'ma_Application_Man',
        }, {
            title: t('FormHeader.CostCenter'),
            dataIndex: 'ma_Cost_Center',
        },  {
            title: t('FormHeader.EquipmentNo'),
            dataIndex: 'ma_Machine_No'
        },
         {
            title: t('FormHeader.EquipmentName'),
            dataIndex: i18n.language === "en"?'ma_Machine_Name':'ma_Machine_CNName',
            ellipsis:isRNA?{
                showTitle:false
            }:false,
            render:(text:string)=>(
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        }, {
            title: t('FormHeader.ProblemDescription'),
            dataIndex: 'ma_Description',
            ellipsis:isRNA?{
                showTitle:false
            }:false,
            render:(ma_Description:string)=>(
                <Tooltip placement="topLeft" title={ma_Description}>
                    {ma_Description}
                </Tooltip>
            )
        }, {
            title: t('FormHeader.Operation'),
            dataIndex: 'Operation',
            fixed: isRNA?false:'right',
            render: (text:string, record:any) => (
                <div>
                    <Button type="primary"  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {  props.jumpToWorkForm(record.ma_Application_No > 0?record.ma_Application_No:record.ma_TempNo)}} >{t('Work')}</Button>
                </div>
            )
        }
    ]

    if(isRNA){
        enginnerWorkRecordTableColumns.splice(-1,0,{
            title: t('FormHeader.ReasonAnalysis'),
            dataIndex: 'ma_Reason_Analysis',
            ellipsis:isRNA?{
                showTitle:false
            }:false,
            render:(ma_Reason_Analysis:string)=>(
                <Tooltip placement="topLeft" title={ma_Reason_Analysis}>
                    {ma_Reason_Analysis}
                </Tooltip>
            )
        })
    }


    //pagination.pageSize = reportPageSize;
    // pagination.pageSizeOptions = webConfig.reportPageSizeOptions;
    // pagination.showSizeChanger = true;
    // pagination.onShowSizeChange = props.onShowSizeChange;
    return (
        <Table columns={enginnerWorkRecordTableColumns}
            rowKey="ma_Id"
            dataSource={dataSource}
            bordered
            rowClassName="largeFont"
            size="middle"
            pagination={false}
            // pagination={pagination}
            loading={loading}
            onChange={props.handleTableChange}
            // scroll x:'max-content'会让column中的ellipsis在chorme94中失效
            scroll={isRNA?{ x: 'fit-content' }:{ x: 'max-content' }}
        />
    );
}

export default EnginnerWorkRecordTable;