import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';


interface Iprops {
    Header: any,
}

const MaintenanceTableHead: React.FC<Iprops> = ({ Header }) => {
    const { t } = useTranslation();
    return (
        <table className="orderHeadTable">
            <tbody>
                <tr>
                    <td>{t('FormHeader.ApplicationNo')}：</td>
                    <td className="textLeft">{Header.ma_Application_No > 0?Header.ma_Application_No:Header.ma_TempNo}</td>
                    <td>{t('FormHeader.Department')}：</td>
                    <td>{Header.ma_Application_Department}</td>
                    <td>{t('FormHeader.ApplicationMan')}：</td>
                    <td>{Header.ma_Application_Man}</td>
                    <td>{t('FormHeader.ApplicationTime')}：</td>
                    <td>{Header.ma_Application_Time ? moment(Header.ma_Application_Time).format("MM/DD/YYYY HH:mm:ss") : ""}</td>
                </tr>
                <tr>
                    <td>{t('FormHeader.Status')}：</td>
                    <td className="textLeft">{Header.ma_Status}</td>
                    <td colSpan={1}>{t('FormHeader.CostCenter')}：</td>
                    <td >{Header.ma_Cost_Center}</td>
                    <td>{t('FormHeader.Entity')}：</td>
                    <td>{Header.ma_Entity_ShortName}</td>
                    <td>{t('FormHeader.ReceivedTime')}：</td>
                    <td>{Header.ma_Received_Time ? moment(Header.ma_Received_Time).format("MM/DD/YYYY HH:mm:ss")  : ""}</td>
                </tr>
                <tr>
                    <td>{t('FormHeader.EquipmentNo')}：</td>
                    <td colSpan={2}>{Header.ma_Machine_No}</td>
                    <td >{t('FormHeader.EquipmentName')}：</td>
                    <td colSpan={2}>{Header.ma_Machine_CNName}</td>
                    <td>{t('FormHeader.Responsible')}：</td>
                    <td >{Header.ma_Responsible}</td>
                </tr>
                {Header.stdTime?<tr>
                    <td>{t('FormHeader.StandardTime')}：</td>
                    <td colSpan={7} className="textLeft" >{Header.stdTime?.stdT_StdTime+'hours/'+Header.stdTime?.stdT_TechsNum+'people'}</td>
                </tr>:<></>}
                <tr>
                    <td>{t('FormHeader.ProblemDescription')}：</td>
                    <td colSpan={7} className="textLeft" >{Header.ma_Description}</td>
                </tr>
                <tr>
                    <td>{t('MaintenForm.ReasonNMethod')}：</td>
                    <td colSpan={7} className="textLeft" >{Header.ma_Reason_Analysis}</td>
                </tr>
            </tbody>
        </table>
    );

}

export default MaintenanceTableHead;