import {Buffer} from "buffer" // npm install --save buffer

/**
* Base64加解密
*/
export default class Base64Util {
    /**
     * 加密
     */
    static encodeBase64Content(content:string) {
        return new Buffer(content).toString('base64');
    }
    /**
     * 解密
     */
    static decodeBase64Content(base64Content:string) {
        //return Buffer.from(base64Content, 'base64').toString();
        return new Buffer(base64Content, 'base64').toString();
    }
}
