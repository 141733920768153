import { observable, action, runInAction, makeObservable } from 'mobx';
import { webConfig } from "../config/webConfig";
import http from '../utils/http';
import root from './root';

class OutSourcingStore extends root {
    constructor() {
        super();
        makeObservable(this);
    }
    @observable outSourcingRecordList = [];
    @observable addModalVisible = false;
    @observable qrModalVisible = false;
    @observable outSourceInfo = {};
    @observable equipmentInfo = {};
    @observable pageSize = webConfig.reportPageSize;
    @observable page = {
        total: 0,
        defaultPageSize: webConfig.reportPageSize
    };
    @observable engAdminStaffList = [];
    @observable supplierAccountList = [];
    @observable supplierList = [];
    @observable categoryList = [];

    @action setOutSourcingRecordList(outSourcingRecordList) {
        this.outSourcingRecordList = outSourcingRecordList;
    }

    @action setEngAdminStaffList(engAdminStaffList) {
        this.engAdminStaffList = engAdminStaffList;
    }

    @action setSupplierAccountList(supplierAccountList) {
        this.supplierAccountList = supplierAccountList;
    }

    @action setSupplierList(supplierList) {
        this.supplierList = supplierList;
    }

    @action setCategoryList(categoryList) {
        this.categoryList = categoryList;
    }

    @action setAddModalVisible(addModalVisible) {
        this.addModalVisible = addModalVisible;
    }

    @action setQrModalVisible(qrModalVisible) {
        this.qrModalVisible = qrModalVisible;
    }

    @action setOutSourceInfo(outSourceInfo){
        this.outSourceInfo = outSourceInfo;
    }

    @action setPage(page) {
        this.page = page;
    }

    @action setEquipmentInfo(equipmentInfo) {
        this.equipmentInfo = equipmentInfo;
    }

    @action setPageSize(pageSize) {
        this.pageSize = pageSize;
    }

    @action async queryOutsoucringList(queryObj) {
        this.loading = true;
        let res = await http.post('osf/queryOutsoucringList', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj) {
                    this.outSourcingRecordList = data.obj.outsoucringList;
                    this.page.total = data.obj.total
                }
            }

        })
        return data;
    }

   

    @action async queryEquipByNo(queryObj) {
        this.loading = true;
        let res = await http.get('equip/getEquipByNo', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                    this.equipmentInfo = data.obj;
            }

        })
        return data;
    }

    @action async addNewOSF(queryObj) {
        this.loading = true;
        let res = await http.post('osf/addNewOSF', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                this.outSourceInfo = queryObj;
                this.outSourceInfo.OsNo = data.obj;
            }
        })
        return data;
    }

    @action async addExpire() {
        this.loading = true;
        let res = await http.get('mac/addExpire');
        runInAction(() => {
            this.loading = false;
        })
    }

    @action async modifyOSF(queryObj) {
        this.loading = true;
        let res = await http.post('osf/modifyOSF', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                this.outSourceInfo = queryObj;
            }
        })
        return data;
    }

    @action async queryEngAdminStaffList() {
        this.loading = true;
        let res = await http.get('user/getEngAdminStaff', null);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            this.engAdminStaffList = data;
        })
    }
    
    @action async querySupplierAccountList() {
        this.loading = true;
        let res = await http.get('user/getSupplierAccountLst', null);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            this.supplierAccountList = data;
        })
    }

    @action async querySupplierList() {
        this.loading = true;
        let res = await http.get('supplier/getSupplierLst', null);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            this.supplierList = data;
        })
    }

    @action async queryCategoryList() {
        this.loading = true;
        let res = await http.get('category/getMainCategory', null);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            this.categoryList = data;
        })
    }


}

const outSourcingStore = new OutSourcingStore();

export default outSourcingStore;
