import "../../style/maintenanceReport.less";
import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Button, Layout, Form } from 'antd';
import { Observer, observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EnginnerWorkRecordTable from './components/EnginnerWorkRecordTable';
import errorUtil from "../../utils/errorUtil";
import Loading from "../../components/Loading";
// import http from '../utils/http';
import store from '../../store';

interface Iprops {
    user: any
}

const { Content } = Layout;
let query: any = {}


const ReasonAnalyRecord: React.FC<Iprops> = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    // const [loading, setLoading] = useState(false);
    // const [total, setTotal] = useState(0);
    // const [maintenanceRecordList, setMaintenanceRecordList] = useState([]);
    const eStore: any = useLocalObservable(() => store.reasonAnalyStore);
    //query.pageSize = eStore.pageSize;
    query.technician = props.user.user_NameCN;
    const [form] = Form.useForm();
    let maintenanceRecordList = eStore.maintenanceRecordList.slice();
    let { page, loading } = eStore;

    useEffect(() => {
        query = { technician: props.user.user_NameCN }
        onSearch();
        return () => {
            eStore.setMaintenanceRecordList([]);
        }
    }, [])


    const handleSearch = () => {
        // e.preventDefault();//阻止页面提交刷新
        onSearch();
    }

    const onSearch = () => {
        getSearchCondition();
        eStore.queryLocalRecordList(query).then((v: any) => {
            if (v.status === 0) {
                errorUtil.showErrorMsg(v.err);
            }
        }).catch((ex: any) => {
            errorUtil.showErrorWithStore(ex, history, eStore);
        });
    }

    const getSearchCondition = () => {
        let applicationNo = form.getFieldValue("applicationNo");
        if (!!applicationNo) {
            query.applyNo = applicationNo;
        } else {
            query = { technician: props.user.user_NameCN }
        }
        // query.pageSize = eStore.pageSize;
    }

    const handleReportTableChange = (pagination: any, fliters: any, sorter: any) => {
        getSearchCondition();
        const sortFiled = sorter.field;
        let sortOrder = sorter.order;
        if (sortOrder !== undefined && sortOrder !== null) {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc'
            } else if (sortOrder === 'descend') (
                sortOrder = 'desc'
            )
        }
        query.sortFiled = sortFiled;
        query.sortOrder = sortOrder;
        //query.pageSize = pagination.pageSize;
        const current = pagination.current;
        if (current !== undefined && current !== null) {
            if (current > 1) {
                query.currIndex = (current - 1) * pagination.pageSize;
            } else {
                query.currIndex = 0;
            }
        }
        eStore.queryMaintenanceRecordList(query).catch((e: any) => {
            //errorUtil.showError(e, history);
            // setLoading(false);
            errorUtil.showErrorWithStore(e, history, eStore);
        });
    }

    const onShowSizeChange = (current: number, pageSize: number) => {
        // eStore.setPageSize(pageSize);
    }

    const jumpToWorkForm = (formData: any) => {
        //state 对象不能过大，不然无法clone
        history.push({ pathname: '/app/analy', state: { applyNo: formData } });
        //history.push('/app/workTime');
    }

    return <Observer>{() =>
        <>
            <Loading loading={loading} />
            <Form form={form} layout="inline" className="reportbar" onFinish={handleSearch}>
                {/* <Form.Item label="BatchNo"> */}
                <Form.Item name="applicationNo">
                    <Input size="large" allowClear placeholder="ApplicationNo" />
                </Form.Item>
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading}>{t('EwRecord.SearchTask')}</Button>
                </Form.Item>
                <Form.Item className="summary"    >
                    {t('Total', { pcs: page.total })}
                </Form.Item>
            </Form>
            <EnginnerWorkRecordTable loading={loading} isRNA={true} jumpToWorkForm={jumpToWorkForm} dataSource={maintenanceRecordList} handleTableChange={handleReportTableChange} onShowSizeChange={onShowSizeChange} />
        </>
        //   )
    }</Observer>;
}

export default observer(ReasonAnalyRecord);

