import React, { ReactNode } from 'react';
import { Modal,Table } from 'antd';
import { useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import store from '../../../store';

interface Iprops {
    applicationNo: string,
    visible: boolean,
    loading: boolean,
    onCancel: () => void,
}


const FittingLstModal: React.FC<Iprops> = (props) => {
    const { applicationNo,visible, loading,onCancel } = props;
    const { t, i18n } = useTranslation();
    const eStore: any = useLocalObservable(() => store.mReportStore);
    let title = t('Fitting.MANoFitting', { MaNo: applicationNo });

    const columns:Array<any> = [
        { title: t('Fitting.ProductID'), dataIndex: 'ft_ProductId', key: 'user_No' },
        { title: t('Fitting.FittingName'), 
          dataIndex: i18n.language === "en" ? 'ft_Product_NameEN' : 'ft_Product_NameCN'},
        { title: t('Fitting.Specification'), dataIndex: 'ft_Specification' },
        { title: t('Fitting.Quantity'), dataIndex: 'ft_Quantity' },
        { title: t('Fitting.Unit'), dataIndex: 'ft_Unit' },
    ];

    return (
         <Modal title={title} closable={true} maskClosable={false}
            visible={visible}  width={1000}
            footer={null} onCancel={onCancel}
         >
            <Table columns={columns}
                rowKey={record => record.ft_Id}
                dataSource={eStore.fittingList.slice()}
                loading={loading}
                scroll={{ x: 'max-content' }}
                pagination={false}
                bordered
                />
        </Modal>
        
    )

}

export default FittingLstModal;