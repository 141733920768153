import { observable, action, runInAction, makeObservable } from 'mobx';
import http from '../utils/http';
import root from './root';

class WorkTimeStore extends root {
    constructor() {
        super();
        makeObservable(this);
    }
    @observable formData = {};
    @observable currentTimeRecord = {};
    @observable currentState = 'I';
    @observable timeList = [];
    @observable fittingList = [];
    @observable stdJobList = [];

    @action setFormData(formData) {
        this.formData = formData;
    }

    @action setCurrentTimeRecord(currentTimeRecord) {
        this.currentTimeRecord = currentTimeRecord;
    }

    @action setCurrentState(currentState) {
        this.currentState = currentState;
    }

    @action setTimeList(timeList) {
        this.timeList = timeList;
    }

    @action setFittingList(fittingList) {
        this.fittingList = fittingList;
    }

    @action setStdJobList(stdJobList) {
        this.stdJobList = stdJobList;
    }



    @action async queryLocalMANTimeLstByNoTech(queryObj) {
        this.loading = true;
        let res = await http.get('mac/getMaNMpLstByNoTech', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                this.formData = res.data.obj.Header;
                this.timeList = res.data.obj.timeLst;
                if (this.timeList.length > 0) {
                    this.currentTimeRecord = {};
                    for (let i = 0; i < this.timeList.length; i++) {
                        if(this.timeList[i].mp_Technician === queryObj.technician){
                            this.currentTimeRecord = this.timeList[i];
                            break;
                        }
                    }
                    this.currentState = this.currentTimeRecord.mp_State ? this.currentTimeRecord.mp_State : 'I';
                }else{
                    this.currentTimeRecord = {};
                    this.currentState = 'New';
                }
                if(this.formData.ma_EngStatus === 'End' || this.formData.ma_EngStatus === 'Finished'){
                    this.currentState = 'End';
                }
            }
        })
        return data;
    }

    @action async queryHeaderNTimeLstByNoTech(queryObj) {
        this.loading = true;
        let res = await http.get('mapp/getHeaderNTimeLstByNoTech', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                this.formData = res.data.obj.Header;
                this.timeList = res.data.obj.timeLst;
                if (this.timeList.length > 0) {
                    this.currentTimeRecord = this.timeList[0];
                    this.currentState = this.currentTimeRecord.state ? this.currentTimeRecord.state : 'I';
                }else{
                    this.currentState = 'New';
                }
            }
        })
        return data;
    }

    @action async queryStartRecord(queryObj) {
        this.loading = true;
        let res = await http.get('mpc/getMPLstByTechNState', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                
            }
        })
        return data;
    }

    @action async onStartTime(applyNo, user,isRNA) {
        this.loading = true;
        let res;
        if (this.currentState === 'Wait') {
            res = await http.put('mpc/updateMPFromTime/' + this.currentTimeRecord.mp_Id + '/' + applyNo + '/' + user+ '/' + isRNA);
        } else {
            res = await http.put('mpc/addNewMPWithStart/' + applyNo + '/' + user+ '/' + isRNA);
        }
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj.length > 0) {
                    this.timeList = data.obj;
                    this.currentTimeRecord = {};
                    for (let i = 0; i < this.timeList.length; i++) {
                        if(this.timeList[i].mp_Technician === user){
                            this.currentTimeRecord = this.timeList[i];
                            break;
                        }
                    }
                    this.currentState = this.currentTimeRecord.mp_State ? this.currentTimeRecord.mp_State : 'I';
                }
            }
        })
        return data;
    }

    @action async onPauseOrFinishTime(applyNo,user,state) {
        this.loading = true;
        let res = await http.put('mpc/updateMPToTime/' + this.currentTimeRecord.mp_Id + '/' + state + '/' + applyNo + '/' + user);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj.length > 0) {
                    this.timeList = data.obj;
                    this.currentTimeRecord = {};
                    for (let i = 0; i < this.timeList.length; i++) {
                        if(this.timeList[i].mp_Technician === user){
                            this.currentTimeRecord = this.timeList[i];
                            break;
                        }
                    }
                    this.currentState = this.currentTimeRecord.mp_State ? this.currentTimeRecord.mp_State : 'I';
                }
            }
        })
        return data;
    }

    @action async onPauseOtherNStartTime(applyNo, user,isRNA) {
        this.loading = true;
        let type = "Add";
        if (this.currentState === 'Wait') {
            type = "Update";
        } 
        let id = this.currentTimeRecord.mp_Id?this.currentTimeRecord.mp_Id:0 
        let res = await http.put('mpc/pasueOtherNStart/' +id + '/' + applyNo + '/' + user+ '/' + type+ '/' + isRNA);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if (data.obj.length > 0) {
                    this.timeList = data.obj;
                    this.currentTimeRecord = {};
                    for (let i = 0; i < this.timeList.length; i++) {
                        if(this.timeList[i].mp_Technician === user){
                            this.currentTimeRecord = this.timeList[i];
                            break;
                        }
                    }
                    this.currentState = this.currentTimeRecord.mp_State ? this.currentTimeRecord.mp_State : 'I';
                }
            }
        })
        return data;
    }

    @action async onUpdateRemark(id,remark,userName) {
        this.loading = true;
        let res = await http.put('mpc/updateMPRemark/' + id + '/' + remark + '/' + userName);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
        })
        return data;
    }

    @action async onUpdateReason(obj){
        this.loading = true;
        let res = await http.post('mac/updateMAReason', obj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                this.formData.ma_Reason_Analysis  = obj.Reason;
            }
        })
        return data;
    }

    @action async queryFtLstByApplyNo(queryObj) {
        this.loading = true;
        let res = await http.get('fitting/getFtLstByApplyNo', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                this.fittingList = res.data.obj
            }
        })
        return data;
    }

    @action async onDeleteFitting(id) {
        this.loading = true;
        let res = await http.delete('fitting/deleteFt/' + id );
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            
        })
        return data;
    }

    @action async addFitting(ft) {
        this.loading = true;
        let res = await http.post('fitting/addNewFitting', ft);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                if(data?.obj){
                    ft.ft_Id = data.obj;
                    this.fittingList.unshift(ft);
                }
            }

        })
        return data;
    }

    @action async updateFitting(ft) {
        this.loading = true;
        let res = await http.post('fitting/updateFitting', ft);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                const index = this.fittingList.findIndex(item => ft.ft_Id === item.ft_Id);
                if(index > -1){
                    const item = this.fittingList[index];
                    this.fittingList.splice(index,1,{...item,...ft});
                }
            }

        })
        return data;
    }

    @action async queryFittingById(queryObj) {
        this.loading = true;
        let res = await http.get('fittingfm/getFittingByProdID', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
        })
        return data;
    }

    @action async queryTop1FitByProdIdNApplyNo(queryObj) {
        this.loading = true;
        let res = await http.get('fitting/getTop1FtByProdIDNApplyNo', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            this.fittingList = data.obj.ftLst;
        })
        return data;
    }

    @action async queryJobLstByMachineNo(queryObj) {
        this.loading = true;
        let res = await http.get('std/selectJobLstByMachineNo', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                this.stdJobList = res.data.obj;
            }
        })
        return data;
    }

    @action async queryJobLstByCostCenter(queryObj) {
        this.loading = true;
        let res = await http.get('std/selectJobLstByCostCenter', queryObj);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            if (data.status === 1) {
                this.stdJobList = res.data.obj;
            }
        })
        return data;
    }

    @action async onUpdateStdId(stdId,applyNo) {
        this.loading = true;
        let res = await http.put('mac/updateMAStdTimeId/' + stdId + '/' + applyNo);
        const data = res.data;
        runInAction(() => {
            this.loading = false;
            this.formData = data.obj;
        })
        return data;
    }


}

const workTimeStore = new WorkTimeStore();

export default workTimeStore;
