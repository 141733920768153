import React from 'react';
import { Table } from 'antd';
import { EditableSelectRow } from '../../../components/EditableSelectCell';
import { EditableSelectCell } from '../../../components/EditableSelectCell';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { timeExplain } from '../../../config/webConfig';


interface Iprops {
    loading: boolean,
    // pagination: any,
    dataSource: object[],
    handleSave: (row: any) => void,
    editable: boolean,
    user:any,
    // handleTableChange: (pagination: any, fliters: any, sorter: any) => void,
    // onShowSizeChange: (current: any, pageSize: any) => void
}

const EnginnerTimeRecordTable: React.FC<Iprops> = (props: Iprops) => {
    const { dataSource, loading, editable,user } = props;
    const { t, i18n } = useTranslation();
    //pagination.pageSize = reportPageSize;
    // pagination.pageSizeOptions = webConfig.reportPageSizeOptions;
    // pagination.showSizeChanger = true;
    // pagination.onShowSizeChange = props.onShowSizeChange;

    const EnginnerTimeRecordColumns = [
        {
            title: t('TimeTable.Seq'),
            render: (text: string, record: any, index: number) => `${index + 1}`
        }, {
            title: t('TimeTable.State'),
            dataIndex: 'mp_State',
            render: (val: string) => {
                if (i18n.language === "en") {
                    return val;
                } else {
                    switch (val) {
                        case "Wait":
                            return "等待开始"
                        case "Start":
                            return "开始";
                        case "Pause":
                            return "暂停";
                        case "End":
                            return "结束";
                        case "Finished":
                            return "完成";
                        default:
                            return "";
                    }
                }
            }
        }, {
            title: t('TimeTable.Name'),
            dataIndex: 'mp_Technician',
        }, {
            title: t('TimeTable.From_Time'),
            render: (text: string, record: any) => {
                if (record.mp_FromTime) {
                    let time = moment(record.mp_FromTime).format("MM/DD/YYYY HH:mm:ss")
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('TimeTable.To_Time'),
            render: (text: string, record: any) => {
                if (record.mp_ToTime) {
                    let time = moment(record.mp_ToTime).format("MM/DD/YYYY HH:mm:ss")
                    return time;
                } else {
                    return "";
                }
            }
        }, {
            title: t('TimeTable.WorkingTime'),
            align: 'center',
            render: (text: any, record: any) => {
                if (record.mp_SpendTime) {
                    let hour = Math.floor(record.mp_SpendTime / 3600);
                    let min = Math.floor((record.mp_SpendTime % 3600) / 60);
                    let second = record.mp_SpendTime % 3600 - min * 60;
                    if (hour > 0) {
                        if (min > 0) {
                            if (second > 0) {
                                return hour + "h" + min + "m" + second + "s";
                            } else {
                                return hour + "h" + min + "m";
                            }
                        } else {
                            if (second > 0) {
                                return hour + "h" + second + "s";
                            } else {
                                return hour + "h";
                            }
                        }
                    }else{
                        if (min > 0) {
                            if (second > 0) {
                                return  min + "m" + second + "s";
                            } else {
                                return  min + "m";
                            }
                        } else {
                            if (second > 0) {
                                return second + "s";
                            } else {
                                return '0';
                            }
                        }
                    }
                } else {
                    return 0;
                }
            }
        }, {
            title: t('TimeTable.Remarks'),
            dataIndex: 'mp_Remarks',
            editable: editable,
        }
    ]

    const components = {
        body: {
            row: EditableSelectRow,
            cell: EditableSelectCell,
        },
    };
    const columns = EnginnerTimeRecordColumns.map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                options: timeExplain,
                handleSave: props.handleSave,
                noContent: t('MaintenForm.NoRemark'),
            }),
        };
    });

    const getRowClassName = (record:any,index:number) =>{
        if(record.mp_Technician === user.user_NameCN){
            return 'selfTimeRecord';
        }else{
            return 'timeRecordRow';
        }
    }

    return (
        <Table components={components}
            columns={columns}
            rowKey={(record: any) => record.id}
            dataSource={dataSource}
            pagination={false}
            bordered
            loading={loading}
            rowClassName={getRowClassName}
            size='middle'
            // onChange={props.handleTableChange}
            scroll={{ x: 'max-content' }}
        />
    );
}

export default EnginnerTimeRecordTable;